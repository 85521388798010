import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import React from 'react';
import {
  Button,
  HSpacer,
  Icon,
  LoadingOverlay,
  RawDropdown,
  Tabs,
  Text,
  ToastAlert,
  VSpacer,
} from '@mqd/volt-base';
import { ErrorBannerAlert, ParentDetail } from '@mq/voltron-parent';
import {
  AuditLogsTab,
  CardInfoTab,
  CardStatus,
  ChangeStatusModal,
  DigitalWalletTokensTab,
  DisableCardModal,
  TransactionsTab,
  TransitionsTab,
  AddFundsModal,
  RemoveFundsModal,
} from '..';
import StyleContext from '../contexts/StyleContext';
import { dig } from './../shared-utils';
import CardStore from './../stores/CardStore';
import CreditCardStore from './../stores/CreditCardStore';
import s from './CardDetail.module.css';

class CardDetail extends ParentDetail {
  constructor(props) {
    super(props);

    if (props.credit) {
      this.storeConstructor = CreditCardStore;
    } else {
      this.storeConstructor = CardStore;
    }

    this.state = {
      activeTab: this.props.currentTab || 'Card details',
      showDisableCardModal: false,
      showTerminateModal: false,
      showAddFundsModal: false,
      showRemoveFundsModal: false,
      historyTabRefreshToken: 'initial',
      loadedStores: {},
      toastProps: null,
    };
  }

  get activeTabs() {
    const { userStore = {}, credit } = this.props;
    const { auditLogTableActive, digitalWalletTokenActive } = userStore;

    return [
      'Card details',
      ...(!credit ? ['Card transactions'] : []),
      ...(auditLogTableActive ? ['History'] : []),
      'Card transitions',
      ...(digitalWalletTokenActive || credit ? ['Digital wallet tokens'] : []),
    ];
  }

  get canMakeTransition() {
    const { createTransitionAllowedRoles } = this.store;
    const { userStore } = this.props;
    const { hasRoleInArrayForActiveProgram = () => {} } = userStore;
    return hasRoleInArrayForActiveProgram(createTransitionAllowedRoles);
  }

  get canUpdateCardInfo() {
    const { updateCardAllowedRoles } = this.store;
    const { userStore } = this.props;
    const { hasRoleInArrayForActiveProgram = () => {} } = userStore;
    return hasRoleInArrayForActiveProgram(updateCardAllowedRoles);
  }

  get canSetPin() {
    const { createOrUpdatePinAllowedRoles } = this.store;
    const { userStore } = this.props;
    const {
      hasRoleInArrayForActiveProgram = () => {},
      uamGranularPermissionsActive,
      canSetPin,
    } = userStore;

    return uamGranularPermissionsActive
      ? canSetPin
      : hasRoleInArrayForActiveProgram(createOrUpdatePinAllowedRoles);
  }

  get canShowPan() {
    const { userStore, credit } = this.props;
    const { auxShowPanActive, canViewPan, uamGranularPermissionsActive } = userStore;

    return uamGranularPermissionsActive ? canViewPan : auxShowPanActive && !credit;
  }

  get canViewShortPan() {
    const { userStore, credit } = this.props;

    if (credit) {
      return !!userStore.hasRoleInArrayForActiveProgram(['aux-credit-support-agent-external']);
    }

    return true;
  }

  get canViewCvv() {
    const { userStore } = this.props;
    const { uamGranularPermissionsActive, canViewPan } = userStore;
    const allowedRoles = ['aux-show-pan', 'aux-credit-support-agent-external'];

    return uamGranularPermissionsActive
      ? canViewPan
      : !!userStore.hasRoleInArrayForActiveProgram(allowedRoles);
  }

  get canAddFunds() {
    const { userStore } = this.props;
    const allowedRoles = ['production-support-internal', 'delivery-internal'];
    const { hasRoleInArrayForActiveProgram = () => {} } = userStore;
    return hasRoleInArrayForActiveProgram(allowedRoles);
  }

  get canRemoveFunds() {
    const { userStore } = this.props;
    return !!userStore?.canRemoveCardholderFunds;
  }

  renderBreadcrumb() {
    const { buildBreadcrumb } = this.props;
    if (!(buildBreadcrumb instanceof Function)) return null;
    const { last_four } = this.store;
    const currentCrumb = {
      children: last_four
        ? `Card \u2022\u2022${this.canViewShortPan ? last_four : '\u2022\u2022'}`
        : '\u2022 \u2022 \u2022',
    };
    return buildBreadcrumb(currentCrumb);
  }

  renderHeaderButtons() {
    const { goToCardProduct, goToAccountCards, credit, userStore } = this.props;
    const { card_product_token, isTerminated, accountToken } = this.store;
    const { cardSuspendActive, showTerminateCard, uamGranularPermissionsActive } = userStore;

    let btnPropsObj = {
      testId: 'view-cardproduct-button',
      onClick: () => goToCardProduct(card_product_token),
      label: 'View card product',
    };

    if (!!goToAccountCards && credit) {
      btnPropsObj = {
        testId: 'view-accountcards-button',
        onClick: () => {
          goToAccountCards(accountToken);
        },
        label: 'View account cards',
      };
    }

    const shouldRenderCardproduct = card_product_token;
    const shouldRenderTerminate = uamGranularPermissionsActive
      ? showTerminateCard && !isTerminated
      : cardSuspendActive && !isTerminated;
    const showDropdown =
      shouldRenderCardproduct && (shouldRenderTerminate || this.canAddFunds || this.canRemoveFunds);

    return (
      <div className={s.flexEnd}>
        {shouldRenderCardproduct && (
          <Button
            testId={btnPropsObj.testId}
            type="tertiary"
            onClick={btnPropsObj.onClick}
            className={s.actionButton}
          >
            {btnPropsObj.label}
          </Button>
        )}

        <HSpacer factor={1} />
        {showDropdown && (
          <RawDropdown
            control={
              <Button
                type="tertiary"
                testId="more-actions-dropdown-button"
                className={s.actionButton}
              >
                More actions
                <Icon type="caret-down" mod="default" factor={1} noHoverEffects></Icon>
              </Button>
            }
            contentPadding={0}
            anchor="right"
          >
            {shouldRenderTerminate && (
              <div
                className={s.dropdownRow}
                data-testid="terminate-card-button"
                onClick={() => this.setState({ showTerminateModal: true })}
              >
                <Text>Terminate card</Text>
              </div>
            )}
            {this.canAddFunds && (
              <div
                className={s.dropdownRow}
                data-testid="add-funds-button"
                onClick={() => this.setState({ showAddFundsModal: true })}
              >
                <Text>Add funds to user</Text>
              </div>
            )}
            {this.canRemoveFunds && (
              <div
                className={s.dropdownRow}
                data-testid="remove-funds-button"
                onClick={() => this.setState({ showRemoveFundsModal: true })}
              >
                <Text>Remove funds from user</Text>
              </div>
            )}
          </RawDropdown>
        )}
      </div>
    );
  }

  renderTerminateModal() {
    const { showTerminateModal } = this.state;
    const { terminateCard, terminateCardGranularPermissions, last_four } = this.store;
    const { userStore } = this.props;
    const { uamGranularPermissionsActive } = userStore;
    if (!showTerminateModal) return null;

    const changeStatusOnSubmit = async () => {
      try {
        if (uamGranularPermissionsActive) {
          await terminateCardGranularPermissions();
        } else {
          await terminateCard();
        }
        this.setState({ toastProps: { message: 'Card terminated', icon: 'success' } });
      } catch (e) {
        this.setState({ toastProps: { message: 'Card failed to be terminated', icon: 'error' } });
      } finally {
        this.setState({ showTerminateModal: false });
      }
    };

    return (
      <ChangeStatusModal
        hideModal={() => this.setState({ showTerminateModal: false })}
        heading={`Terminate card \u2022\u2022${this.canViewShortPan ? last_four : '\u2022\u2022'}`}
        showStatusSelection={false}
        overrideNativeOnSubmit={changeStatusOnSubmit}
        store={this.store}
        footerButtons={[<Button type="danger">Terminate</Button>]}
      >
        <Text>
          Terminated cards cannot be used or reactivated. A new card will not be reissued.
        </Text>
      </ChangeStatusModal>
    );
  }

  renderAddFundsModal() {
    const { showAddFundsModal } = this.state;
    const { cardholder } = this.store;

    if (!showAddFundsModal) return null;
    return (
      <AddFundsModal
        cardholderStore={cardholder}
        hideModal={() => this.setState({ showAddFundsModal: false })}
        showSuccessToast={() =>
          this.setState({ toastProps: { message: 'Funds added successfully.', icon: 'success' } })
        }
      />
    );
  }

  renderRemoveFundsModal() {
    const { showRemoveFundsModal } = this.state;
    const { cardholder } = this.store;

    if (!showRemoveFundsModal) return null;
    return (
      <RemoveFundsModal
        cardholderStore={cardholder}
        hideModal={() => this.setState({ showRemoveFundsModal: false })}
        showSuccessToast={() =>
          this.setState({
            toastProps: { message: 'Unload fund request submitted', icon: 'success' },
          })
        }
      />
    );
  }

  renderHeader() {
    const { state, last_four: lastFour } = this.store;
    const Breadcrumb = this.renderBreadcrumb() || <span />;
    const crumbCount = dig(Breadcrumb, 'props', 'crumbs', 'length');
    const breadCrumbIsVisible = crumbCount && crumbCount > 1;
    const shouldShowSpacer = breadCrumbIsVisible && (state || lastFour);
    const formattedFour = lastFour
      ? `\u2022\u2022${this.canViewShortPan ? lastFour : '\u2022\u2022'}`
      : '';

    return (
      <div className={s.flexDivBetween}>
        <div>
          {Breadcrumb}
          {shouldShowSpacer && <VSpacer factor={2} />}
          <CardStatus status={state} lastFour={formattedFour} />
        </div>
        {this.renderHeaderButtons()}
      </div>
    );
  }

  renderContent() {
    const { activeTab, historyTabRefreshToken, loadedStores } = this.state;
    const { gqlClient, token, last_four } = this.store;
    const {
      userStore,
      goToDigitalWalletToken,
      goToNewDispute,
      transactionsTableLocalStorageKey,
      historyTableLocalStorageKey,
      transitionsTableLocalStorageKey,
      digitalWalletTokensTableLocalStorageKey,
      goToCard,
      showPanForCreditRoles,
      canAccessDisputes,
      goToTransaction,
    } = this.props;
    const defaultComponent = (
      <CardInfoTab
        cardStore={this.store}
        goToCard={goToCard}
        canMakeTransition={this.canMakeTransition}
        canUpdateCardInfo={this.canUpdateCardInfo}
        canSetPin={this.canSetPin}
        canShowPan={this.canShowPan}
        showPanForCreditRoles={showPanForCreditRoles}
        canViewShortPan={this.canViewShortPan}
        canViewCvv={this.canViewCvv}
        userStore={userStore}
      />
    );

    if (!this.activeTabs.includes(activeTab)) {
      return defaultComponent;
    }

    const goToDWT = (dwtToken) => {
      const name = last_four
        ? `\u2022\u2022${this.canViewShortPan ? last_four : '\u2022\u2022'}`
        : 'Card';
      goToDigitalWalletToken(dwtToken, name, token);
    };

    switch (activeTab) {
      case 'Card details':
        return defaultComponent;

      case 'Digital wallet tokens':
        return (
          <DigitalWalletTokensTab
            key={token}
            loading={!token}
            goToDigitalWalletToken={goToDWT}
            storeInitArgs={{
              gqlClient: gqlClient,
              enableNoParamQuery: false,
              queryParams: {
                card_token: {
                  val: token,
                  type: 'ID!',
                },
              },
              userStore: userStore,
            }}
            autoHydrate
            localStorageKey={digitalWalletTokensTableLocalStorageKey}
          />
        );

      case 'Card transactions':
        return (
          <TransactionsTab
            key={token}
            loading={!token}
            goToDigitalWalletToken={goToDWT}
            goToNewDispute={goToNewDispute}
            onStoreConstruction={(store) => {
              const newLoadedStores = Object.assign({}, loadedStores, {
                Transactions: store,
              });
              this.setState({ loadedStores: newLoadedStores });
            }}
            storeInitArgs={{
              gqlClient: gqlClient,
              enableNoParamQuery: false,
              queryParams: {
                card_token: {
                  val: token,
                  type: 'ID!',
                },
              },
              userStore: userStore,
            }}
            localStorageKey={transactionsTableLocalStorageKey}
            canAccessDisputes={canAccessDisputes}
            goToTransaction={goToTransaction}
          />
        );

      case 'History':
        return (
          <AuditLogsTab
            key={`${historyTabRefreshToken} ${token}`}
            loading={!token}
            onStoreConstruction={(store) => {
              const newLoadedStores = Object.assign({}, loadedStores, {
                History: store,
              });
              this.setState({ loadedStores: newLoadedStores });
            }}
            storeInitArgs={{
              gqlClient,
              enableNoParamQuery: false,
              queryParams: {
                record_id: {
                  val: token,
                  type: 'String!',
                },
              },
            }}
            excludedSearchFilters={['record_id']}
            localStorageKey={historyTableLocalStorageKey}
          />
        );

      case 'Card transitions':
        if (!token) {
          return <LoadingOverlay active />;
        }
        return (
          <>
            <TransitionsTab
              key={token}
              loading={!token}
              onStoreConstruction={(store) => {
                const newLoadedStores = Object.assign({}, loadedStores, {
                  Transitions: store,
                });
                this.setState({ loadedStores: newLoadedStores });
              }}
              storeInitArgs={{
                gqlClient,
                transitionTypeOverride: 'card',
                queryParams: {
                  card_token: {
                    type: 'ID!',
                    val: token,
                  },
                },
              }}
              localStorageKey={transitionsTableLocalStorageKey}
            />
          </>
        );

      default:
        return defaultComponent;
    }
  }

  renderDisableCardModal() {
    const { showDisableCardModal } = this.state;
    if (!showDisableCardModal) return null;
    const { last_four, changeStatus } = this.store;
    return (
      <DisableCardModal
        hideModal={() => this.setState({ showDisableCardModal: false })}
        heading={`Report Lost/Stolen - ${last_four}`}
        reportAction={() => changeStatus({ status: 'TERMINATED', reason_code: '10' })}
        last_four={last_four}
      />
    );
  }

  renderErrors() {
    const { loadedStores, activeTab } = this.state;
    if (activeTab === 'Card%20details' || activeTab === 'Card details') {
      return <ErrorBannerAlert store={this.store} />;
    } else {
      return <ErrorBannerAlert store={loadedStores[activeTab]} />;
    }
  }

  renderToastAlert() {
    const { toastProps } = this.state;
    if (!toastProps || typeof toastProps !== 'object' || !toastProps.message) return null;

    return (
      <ToastAlert remove={() => this.setState({ toastProps: null })} {...toastProps}>
        {toastProps.message}
      </ToastAlert>
    );
  }

  render() {
    const { activeTab } = this.state;
    const { handleTabClick } = this.props;
    // minus the new status on top of tabSelector and card product buttons
    const height = this.dynamicHeight && this.dynamicHeight - 96;
    const style = { width: '100%' };

    return (
      <div style={style}>
        {this.renderErrors()}
        {this.renderToastAlert()}
        {this.renderHeader()}
        {this.renderTerminateModal()}
        {this.renderAddFundsModal()}
        {this.renderRemoveFundsModal()}
        {this.renderDisableCardModal()}
        <VSpacer factor={3} />

        <Tabs
          tabs={this.activeTabs}
          activeTab={activeTab}
          onTabChange={(activeTab) => {
            handleTabClick({ tab: activeTab });
            this.setState({ activeTab });
          }}
        />

        <VSpacer factor={2} />
        <StyleContext.Provider value={{ height }}>{this.renderContent()}</StyleContext.Provider>
      </div>
    );
  }
}

CardDetail.propTypes = {
  storeInitArgs: PropTypes.object,
  store: PropTypes.object,
  userStore: PropTypes.object,
  autoHydrate: PropTypes.bool,
  onStoreConstruction: PropTypes.func,
  buildBreadcrumb: PropTypes.func,
  handleTabClick: PropTypes.func,
  currentTab: PropTypes.string,
  transactionsTableLocalStorageKey: PropTypes.string,
  historyTableLocalStorageKey: PropTypes.string,
  transitionsTableLocalStorageKey: PropTypes.string,
  goToCardProduct: PropTypes.func,
  goToAccountCards: PropTypes.func,
  goToDigitalWalletToken: PropTypes.func,
  goToNewDispute: PropTypes.func,
  goToTransaction: PropTypes.func,
  credit: PropTypes.bool,
  canAccessDisputes: PropTypes.bool,
};

CardDetail.defaultProps = {
  storeInitArgs: {},
  store: null,
  userStore: {},
  autoHydrate: true,
  onStoreConstruction: null,
  buildBreadcrumb: null,
  handleTabClick: () => {},
  currentTab: 'Card details',
  transactionsTableLocalStorageKey: 'cardDetailTransactionsTableConfig',
  historyTableLocalStorageKey: 'cardDetailHistoryTableConfig',
  transitionsTableLocalStorageKey: 'cardDetailTransitionsTableConfig',
  digitalWalletTokensTableLocalStorageKey: 'cardDetailDigitalWalletTokensTableConfig',
  goToCardProduct: null,
  goToAccountCards: null,
  goToDigitalWalletToken: null,
  goToNewDispute: null,
  goToTransaction: () => {},
  credit: false,
  canAccessDisputes: false,
};

export default observer(CardDetail);
