// ******************************************* //
// ALL ROUTES NEED TO BE DECLARED IN THIS FILE //
// AUTHORIZATION IS DEFAULT DENY               //
// ******************************************* //
import { routeMap } from '@mq/volt-amc-container';
import { isInvalidSandboxRoute } from './utils.js';
import {
  requirements as acceptedCountriesRequirements,
  verifiers as acceptedCountriesVerifiers,
} from '../../views/accepted-countries/config.js';
import {
  requirements as applicationTokensRequirements,
  verifiers as applicationTokensVerifiers,
} from '../../views/application-tokens/config.js';
import { verifiers as approvalQueueVerifiers } from '../../views/approval-queue/config.js';
import {
  verifiers as adminUserDetailsVerifiers,
  requirements as adminUserDetailsRequirements,
} from '../../views/admin/uam-granular-permissions/config.js';
import {
  requirements as cardProductRequirements,
  verifiers as cardProductVerifiers,
} from '../../views/card-products/config.js';
import {
  requirements as diDataCatalogRequirements,
  verifier as diDataCatalogVerifier,
} from '../../views/data-catalog/config.js';
import { fdsRequirements, fdsVerifiers } from '../../views/fds/routing/config.js';
import {
  requirements as mccGroupRequirements,
  verifiers as mccGroupVerifiers,
} from '../../views/mcc-groups/config.js';
import {
  requirements as programControlsRequirements,
  verifiers as programControlVerifiers,
} from '../../views/program-controls/config.js';
import {
  programVerifier as achManagementProgramVerifier,
  requirements as achManagementRequirements,
  verifier as achManagementVerifier,
} from './routes/achManagement.js';
import {
  requirements as programAchFundingRequirements,
  verifier as programAchFundingVerifier,
} from './routes/programAchFunding.js';
import {
  requirements as programAchFundingTransferRequirements,
  verifier as programAchFundingTransferVerifier,
} from './routes/programAchFundingTransfer.js';
import {
  requirements as programAchFundingTransferFundsRequirements,
  verifier as programAchFundingTransferFundsVerifier,
} from './routes/programAchFundingTransferFunds.js';
import { requirements as adminRequirements, verifier as adminVerifier } from './routes/admin.js';
import {
  requirements as adminConfigManagementRequirements,
  verifier as adminConfigManagementVerifier,
} from './routes/adminConfigManagement.js';
import {
  requirements as adminDepartmentsRequirements,
  verifier as adminDepartmentsVerifier,
} from './routes/adminDepartments.js';
import {
  requirements as adminRolesRequirements,
  verifier as adminRolesVerifier,
} from './routes/adminRoles.js';
import {
  requirements as adminSandboxesManagementRequirements,
  verifier as adminSandboxesManagementVerifier,
} from './routes/adminSandboxesManagement.js';
import {
  requirements as adminSupplementsRequirements,
  verifier as adminSupplementsVerifier,
} from './routes/adminSupplements.js';
import {
  requirements as apiAccessRequirements,
  verifier as apiAccessVerifier,
} from './routes/apiAccess.js';
import {
  requirements as auditLogsRequirements,
  verifier as auditLogsVerifier,
} from './routes/auditLogs.js';
import {
  requirements as binDataManagementRequirements,
  verifier as binDataManagementVerifier,
} from './routes/binDataManagement.js';
import {
  requirements as binDataManagementCreateBinRequirements,
  verifier as binDataManagementCreateBinVerifier,
} from './routes/binDataManagementCreateBin.js';
import {
  requirements as binDataManagementCreateRangeRequirements,
  verifier as binDataManagementCreateRangeVerifier,
} from './routes/binDataManagementCreateRange.js';
import {
  requirements as binDataManagementDetailsBinRequirements,
  verifier as binDataManagementDetailsBinVerifier,
} from './routes/binDataManagementDetailsBin.js';
import {
  requirements as binDataManagementDetailsRangeRequirements,
  verifier as binDataManagementDetailsRangeVerifier,
} from './routes/binDataManagementDetailsRange.js';
import {
  requirements as binDataManagementUpdateBinRequirements,
  verifier as binDataManagementUpdateBinVerifier,
} from './routes/binDataManagementUpdateBin.js';
import {
  requirements as binDataManagementUpdateRangeRequirements,
  verifier as binDataManagementUpdateRangeVerifier,
} from './routes/binDataManagementUpdateRange.js';
import {
  requirements as binManagementRequirements,
  verifier as binManagementVerifier,
} from './routes/binManagement.js';
import {
  requirements as binManagementBinViewRequirements,
  verifier as binManagementBinViewVerifier,
} from './routes/binManagementBinView.js';
import {
  requirements as binManagementProgramViewRequirements,
  verifier as binManagementProgramViewVerifier,
} from './routes/binManagementProgramView.js';
import {
  requirements as binManagementRangeViewRequirements,
  verifier as binManagementRangeViewVerifier,
} from './routes/binManagementRangeView.js';

import { requirements as creditRequirements, verifier as creditVerifier } from './routes/credit.js';
import {
  requirements as creditTemplateEditorRequirements,
  verifier as creditTemplateEditorVerifier,
} from './routes/creditTemplateEditor';
import {
  requirements as creditWelcomeRequirements,
  verifier as creditWelcomeVerifier,
} from './routes/creditWelcome';
import {
  requirements as creditAccountsRequirements,
  verifier as creditAccountsVerifier,
} from './routes/creditAccounts.js';
import {
  requirements as creditOfferCreateRequirements,
  verifier as creditOfferCreateVerifier,
} from './routes/creditAcquisitionOfferCreate.js';
import {
  requirements as creditOffersRequirements,
  verifier as creditOffersVerifier,
} from './routes/creditAcquisitionOffers.js';
import {
  requirements as creditBundleWorkQueueRequirements,
  verifier as creditBundleWorkQueueVerifier,
} from './routes/creditBundleWorkQueue.js';
import {
  requirements as creditBundleRequirements,
  verifier as creditBundleVerifier,
} from './routes/creditBundle';
import {
  requirements as creditBundlesRequirements,
  verifier as creditBundlesVerifier,
} from './routes/creditBundles';
import {
  requirements as creditBundlesCreateRequirements,
  verifier as creditBundlesCreateVerifier,
} from './routes/creditBundlesCreate';
import {
  requirements as creditPolicyCreateRequirements,
  verifier as creditPolicyCreateVerifier,
} from './routes/creditPolicyCreate';
import {
  requirements as creditPolicyLibraryRequirements,
  verifier as creditPolicyLibraryVerifier,
} from './routes/creditPolicyLibrary.js';
import {
  requirements as creditPolicyLibraryAPRsDashboardRequirements,
  verifier as creditPolicyLibraryAPRsDashboardVerifier,
} from './routes/creditPolicyLibraryAPRsDashboard';
import {
  requirements as creditPolicyLibraryAPRRequirements,
  verifier as creditPolicyLibraryAPRVerifier,
} from './routes/creditPolicyLibraryAPR';
import {
  requirements as creditPolicyLibraryCreditProductsDashboardRequirements,
  verifier as creditPolicyLibraryCreditProductsDashboardVerifier,
} from './routes/creditPolicyLibraryCreditProductsDashboard';
import {
  requirements as creditPolicyLibraryCreditProductRequirements,
  verifier as creditPolicyLibraryCreditProductVerifier,
} from './routes/creditPolicyLibraryCreditProduct';
import {
  requirements as creditPolicyLibraryFeesDashboardRequirements,
  verifier as creditPolicyLibraryFeesDashboardVerifier,
} from './routes/creditPolicyLibraryFeesDashboard';
import {
  requirements as creditPolicyLibraryFeeRequirements,
  verifier as creditPolicyLibraryFeeVerifier,
} from './routes/creditPolicyLibraryFee';
import {
  requirements as creditPolicyLibraryOffersDashboardRequirements,
  verifier as creditPolicyLibraryOffersDashboardVerifier,
} from './routes/creditPolicyLibraryOffersDashboard';
import {
  requirements as creditPolicyLibraryRewardsDashboardRequirements,
  verifier as creditPolicyLibraryRewardsDashboardVerifier,
} from './routes/creditPolicyLibraryRewardsDashboard';
import {
  requirements as creditProductApprovalQueueRequirements,
  verifier as creditProductApprovalQueueVerifier,
} from './routes/creditProductApprovalQueue.js';
import {
  requirements as creditProductCreateRequirements,
  verifier as creditProductCreateVerifier,
} from './routes/creditProductCreate.js';
import {
  requirements as creditProductsRequirements,
  verifier as creditProductsVerifier,
} from './routes/creditProducts.js';
import {
  requirements as creditProductsRejectedRequirements,
  verifier as creditProductsRejectedVerifier,
} from './routes/creditProductsRejected.js';
import {
  requirements as creditTemplatesRequirements,
  verifier as creditTemplatesVerifier,
} from './routes/creditTemplates.js';
import {
  requirements as decisionManagerRequirements,
  verifier as decisionManagerVerifier,
} from './routes/decisionManager.js';
import {
  requirements as developmentRequirements,
  verifier as developmentVerifier,
} from './routes/development.js';
import {
  requirements as developmentTransactionTimelineRequirements,
  verifier as developmentTransactionTimelineVerifier,
} from './routes/developmentTransactionTimeline.js';
import {
  requirements as developmentWebhooksNewRequirements,
  verifier as developmentWebhooksNewVerifier,
} from './routes/developmentWebhooksNew.js';
import {
  requirements as developmentWebhooksOverviewRequirements,
  verifier as developmentWebhooksOverviewVerifier,
} from './routes/developmentWebhooksOverview.js';
import {
  requirements as developmentWebhooksShowRequirements,
  verifier as developmentWebhooksShowVerifier,
} from './routes/developmentWebhooksShow.js';
import {
  requirements as directDepositRequirements,
  verifier as directDepositVerifier,
} from './routes/directDeposit.js';
import { requirements as homeRequirements, verifier as homeVerifier } from './routes/home.js';
import {
  requirements as pinRevealRequirements,
  verifier as pinRevealVerifier,
} from './routes/pinReveal.js';
import {
  requirements as programRequirements,
  verifier as programVerifier,
} from './routes/program.js';
import {
  redirect as programAccountholderRedirect,
  requirements as programAccountholderRequirements,
  verifier as programAccountholderVerifier,
} from './routes/programAccountholder.js';
import {
  redirect as programAccountholderAccountRedirect,
  requirements as programAccountholderAccountRequirements,
  verifier as programAccountholderAccountVerifier,
} from './routes/programAccountholderAccount.js';
import {
  redirect as programAccountholderAccountActivityRedirect,
  requirements as programAccountholderAccountActivityRequirements,
  verifier as programAccountholderAccountActivityVerifier,
} from './routes/programAccountholderAccountActivity.js';
import {
  requirements as programAccountholderActivateRequirements,
  verifier as programAccountholderActivateVerifier,
} from './routes/programAccountholderActivate.js';
import {
  redirect as programAccountholderActivityRedirect,
  requirements as programAccountholderActivityRequirements,
  verifier as programAccountholderActivityVerifier,
} from './routes/programAccountholderActivity.js';
import {
  redirect as programAccountholderCardRedirect,
  requirements as programAccountholderCardRequirements,
  verifier as programAccountholderCardVerifier,
} from './routes/programAccountholderCard.js';
import {
  redirect as programAccountholderCardActivityRedirect,
  requirements as programAccountholderCardActivityRequirements,
  verifier as programAccountholderCardActivityVerifier,
} from './routes/programAccountholderCardActivity.js';
import {
  redirect as programAccountholdersRedirect,
  requirements as programAccountholdersRequirements,
  verifier as programAccountholdersVerifier,
} from './routes/programAccountholders.js';
import {
  redirect as programAccountholderSuspendRedirect,
  requirements as programAccountholderSuspendRequirements,
  verifier as programAccountholderSuspendVerifier,
} from './routes/programAccountholderSuspend.js';
import {
  redirect as programAccountholderTerminateRedirect,
  requirements as programAccountholderTerminateRequirements,
  verifier as programAccountholderTerminateVerifier,
} from './routes/programAccountholderTerminate.js';
import {
  redirect as programAccountholderTransactionRedirect,
  requirements as programAccountholderTransactionRequirements,
  verifier as programAccountholderTransactionVerifier,
} from './routes/programAccountholderTransaction.js';
import {
  requirements as programBalanceRequirements,
  verifier as programBalanceVerifier,
} from './routes/programBalance.js';
import {
  programVerifier as programBulkCardProductsCreateProgramAccessVerifier,
  requirements as programBulkCardProductsRequirements,
  verifier as programBulkCardProductsVerifier,
} from './routes/programBulkCardProducts.js';
import {
  requirements as programBulkIssuanceRequirements,
  verifier as programBulkIssuanceVerifier,
} from './routes/programBulkIssuance.js';
import {
  requirements as programBulkIssuanceViewRequirements,
  verifier as programBulkIssuanceViewVerifier,
} from './routes/programBulkIssuanceView.js';
import {
  requirements as programBusinessRequirements,
  verifier as programBusinessVerifier,
} from './routes/programBusiness.js';
import {
  requirements as programBusinessesRequirements,
  verifier as programBusinessesVerifier,
} from './routes/programBusinesses.js';
import {
  redirect as programCardRedirect,
  requirements as programCardRequirements,
  verifier as programCardVerifier,
} from './routes/programCard.js';
import {
  requirements as programCardCreationRequirements,
  verifier as programCardCreationVerifier,
} from './routes/programCardCreation.js';
import {
  redirect as programCardholderRedirect,
  requirements as programCardholderRequirements,
  verifier as programCardholderVerifier,
} from './routes/programCardholder.js';
import {
  redirect as programCardholderAccountRedirect,
  requirements as programCardholderAccountRequirements,
  verifier as programCardholderAccountVerifier,
} from './routes/programCardholderAccount.js';
import {
  redirect as programCardholdersRedirect,
  requirements as programCardholdersRequirements,
  verifier as programCardholdersVerifier,
} from './routes/programCardholders.js';
import {
  requirements as programCardManagementProvidersRequirements,
  verifier as programCardManagementProvidersVerifier,
} from './routes/programCardManagementProviders.js';
import {
  programVerifier as programCardPackageProgramAccessVerifier,
  requirements as programCardPackageRequirements,
  verifier as programCardPackageVerifier,
} from './routes/programCardPackage.js';
import {
  programVerifier as programCardPackageCreateProgramAccessVerifier,
  requirements as programCardPackageCreateRequirements,
  verifier as programCardPackageCreateVerifier,
} from './routes/programCardPackageCreate.js';
import {
  programVerifier as programCardPackagesProgramAccessVerifier,
  requirements as programCardPackagesRequirements,
  verifier as programCardPackagesVerifier,
} from './routes/programCardPackages.js';
import {
  programVerifier as programCardProductCreateProgramAccessVerifier,
  requirements as programCardProductRequirements,
  verifier as programCardProductVerifier,
} from './routes/programCardProduct.js';
import {
  programVerifier as programCardProductsCreateProgramAccessVerifier,
  requirements as programCardProductsRequirements,
  verifier as programCardProductsVerifier,
} from './routes/programCardProducts.js';
import {
  requirements as programCardManagementInventoryRequirements,
  verifier as programCardManagementInventoryVerifier,
} from './routes/programCardManagementInventory';
import {
  requirements as programCardOrderAndShipmentRequirements,
  verifier as programCardOrderAndShipmentVerifier,
} from './routes/programCardOrderAndShipment';

import {
  requirements as programCommandoModeRequirements,
  verifier as programCommandoModeVerifier,
} from './routes/programCommandoMode.js';
import {
  requirements as programCommandoModesRequirements,
  verifier as programCommandoModesVerifier,
} from './routes/programCommandoModes.js';
import {
  programVerifier as programDigitalWalletTokenProgramAccessVerifier,
  requirements as programDigitalWalletTokenRequirements,
  verifier as programDigitalWalletTokenVerifier,
} from './routes/programDigitalWalletToken.js';
import {
  requirements as programHSMKeysRequirements,
  verifier as programHSMKeysVerifier,
} from './routes/programHSMKeys.js';
import {
  programVerifier as programInventoryCardPackagesProgramAccessVerifier,
  requirements as programInventoryCardPackagesRequirements,
  verifier as programInventoryCardPackagesVerifier,
} from './routes/programInventoryCardPackages.js';
import {
  programVerifier as programInventoryOrderSummaryProgramAccessVerifier,
  requirements as programInventoryOrderSummaryRequirements,
  verifier as programInventoryOrderSummaryVerifier,
} from './routes/programInventoryOrderSummary.js';
import {
  programVerifier as programInventorySkuProgramAccessVerifier,
  requirements as programInventorySkuRequirements,
  verifier as programInventorySkuVerifier,
} from './routes/programInventorySku.js';
import {
  programVerifier as programProviderProgramAccessVerifier,
  requirements as programProviderRequirements,
  verifier as programProviderVerifier,
} from './routes/programProvider.js';
import {
  programVerifier as programProviderCreateProgramAccessVerifier,
  requirements as programProviderCreateRequirements,
  verifier as programProviderCreateVerifier,
} from './routes/programProviderCreate.js';
import {
  requirements as programReviewsRequirements,
  verifier as programReviewsVerifier,
} from './routes/programReviews.js';
import {
  requirements as programReviewDetailsRequirements,
  verifier as programReviewDetailsVerifier,
} from './routes/programReviewDetails.js';
import {
  programVerifier as programProvidersProgramAccessVerifier,
  requirements as programProvidersRequirements,
  verifier as programProvidersVerifier,
} from './routes/programProviders.js';
import {
  programVerifier as programSkuProgramAccessVerifier,
  requirements as programSkuRequirements,
  verifier as programSkuVerifier,
} from './routes/programSku.js';
import {
  programVerifier as programSkuCreateProgramAccessVerifier,
  requirements as programSkuCreateRequirements,
  verifier as programSkuCreateVerifier,
} from './routes/programSkuCreate.js';
import {
  programVerifier as programSkusProgramAccessVerifier,
  requirements as programSkusRequirements,
  verifier as programSkusVerifier,
} from './routes/programSkus.js';
import {
  requirements as reportsRequirements,
  verifier as reportsVerifier,
} from './routes/reports.js';
import {
  requirements as reportsDataDictionaryRequirements,
  verifier as reportsDataDictionaryVerifier,
} from './routes/reportsDataDictionary.js';
import {
  requirements as reportsGuideRequirements,
  verifier as reportsGuideVerifier,
} from './routes/reportsGuide.js';
import { requirements as riskRequirements, verifier as riskVerifier } from './routes/risk.js';
import {
  requirements as riskAlertsRequirements,
  verifier as riskAlertsVerifier,
} from './routes/riskAlerts.js';
import {
  requirements as riskCaseManagementRequirements,
  verifier as riskCaseManagementVerifier,
} from './routes/riskCaseManagement.js';
import {
  requirements as riskDecisionManagerRequirements,
  verifier as riskDecisionManagerVerifier,
} from './routes/riskDecisionManager.js';
import {
  requirements as riskDisputesRequirements,
  verifier as riskDisputesVerifier,
} from './routes/riskDisputes.js';
import {
  requirements as riskBankReportingRequirements,
  verifier as riskBankReportingVerifier,
} from './routes/riskBankReporting.js';
import {
  requirements as riskBulkUploadRequirements,
  verifier as riskBulkUploadVerifier,
} from './routes/riskDisputes.js';
import {
  requirements as riskTransactionReportRequirements,
  verifier as riskTransactionReportVerifier,
} from './routes/riskTransactionReport';
import {
  requirements as riskRealTimeDecisioningRequirements,
  verifier as riskRealTimeDecisioningVerifier,
} from './routes/riskRealTimeDecisioning';
import {
  requirements as riskRealTimeDecisioningDashboardRequirements,
  verifier as riskRealTimeDecisioningDashboardVerifier,
} from './routes/riskRealTimeDecisioningDashboard';
import {
  requirements as schedulerRequirements,
  verifier as schedulerVerifier,
} from './routes/scheduler.js';
import {
  requirements as storiesRequirements,
  verifier as storiesVerifier,
} from './routes/stories.js';
import {
  programVerifier as threeDSProgramAccessVerifier,
  requirements as threeDSRequirements,
  verifier as threeDSVerifier,
} from './routes/threeDS.js';
import {
  requirements as tokenizationRequirements,
  verifier as tokenizationVerifier,
} from './routes/tokenization.js';
import {
  requirements as tokenizationDetailRequirements,
  verifier as tokenizationDetailVerifier,
} from './routes/tokenizationDetails.js';
import {
  requirements as userActivityLogsRequirements,
  verifier as userActivityLogsVerifier,
} from './routes/userActivityLogs.js';
import {
  requirements as userProfileRequirements,
  verifier as userProfileVerifier,
} from './routes/userProfile.js';

// This requirementsMap is passed in to <NotAllowedView /> to display to the user
// what authorization is needed to view the route.
// NO ROUTE PROTECTION HAPPENS HERE
// The options to display are:
// {
//   requiredPermissions: [<string>],
//   requiredGranularPermissions: [<string>],
//   requiredUserPermissions: [<string>],
//   requiredRoles: [<string>],
//   requiredSupplements: [<string>],
//   flipFlopRequirements: [<string>],
//   programConfigRequirements: [<string>],
//   requiredProgramPermissions: [<string>],
//   excluded: [<string>],
//   marqetaOnly: boolean,
//   janusRequired: boolean,
// }

// if you have complex requirements or verifications define in a seperate file and import
// if you just want to let everyone in you dont need to define a value here
// (since this is just to display to the user why they couldnt access page)
export const requirementsMap = {
  achManagement: achManagementRequirements,
  admin: adminRequirements,
  adminConfigManagement: adminConfigManagementRequirements,
  adminDepartments: adminDepartmentsRequirements,
  adminSupplements: adminSupplementsRequirements,
  adminRoles: adminRolesRequirements,
  adminSandboxesManagement: adminSandboxesManagementRequirements,
  apiAccess: apiAccessRequirements,
  auditLogs: auditLogsRequirements,
  binDataManagement: binDataManagementRequirements,
  binDataManagementCreateBin: binDataManagementCreateBinRequirements,
  binDataManagementCreateRange: binDataManagementCreateRangeRequirements,
  binDataManagementUpdateRange: binDataManagementUpdateRangeRequirements,
  binDataManagementUpdateBin: binDataManagementUpdateBinRequirements,
  binDataManagementDetailsBin: binDataManagementDetailsBinRequirements,
  binDataManagementDetailsRange: binDataManagementDetailsRangeRequirements,
  binManagement: binManagementRequirements,
  binManagementBinView: binManagementBinViewRequirements,
  binManagementRangeView: binManagementRangeViewRequirements,
  binManagementProgramView: binManagementProgramViewRequirements,
  decisionManager: decisionManagerRequirements,
  development: developmentRequirements,
  developmentTransactionTimeline: developmentTransactionTimelineRequirements,
  developmentWebhooksNew: developmentWebhooksNewRequirements,
  developmentWebhooksOverview: developmentWebhooksOverviewRequirements,
  developmentWebhooksShow: developmentWebhooksShowRequirements,
  diDataCatalog: diDataCatalogRequirements,
  directDeposit: directDepositRequirements,
  pinReveal: pinRevealRequirements,
  program: programRequirements,
  programBusiness: programBusinessRequirements,
  programBusinesses: programBusinessesRequirements,
  programCard: programCardRequirements,
  programCardPackage: programCardPackageRequirements,
  programCardPackageCreate: programCardPackageCreateRequirements,
  programCardPackages: programCardPackagesRequirements,
  programCardProducts: programCardProductsRequirements,
  programCardProduct: programCardProductRequirements,
  programBulkCardProducts: programBulkCardProductsRequirements,
  programBulkIssuance: programBulkIssuanceRequirements,
  programCardManagementProviders: programCardManagementProvidersRequirements,
  programCardManagementInventory: programCardManagementInventoryRequirements,
  cardManagementInventoryDetails: programCardManagementInventoryRequirements,
  programBulkIssuanceCreate: programBulkIssuanceViewRequirements,
  programBulkIssuanceDetail: programBulkIssuanceViewRequirements,
  programCardholder: programCardholderRequirements,
  programCardholderAccount: programCardholderAccountRequirements,
  programCardholders: programCardholdersRequirements,
  programAccountholder: programAccountholderRequirements,
  programAccountholderActivity: programAccountholderActivityRequirements,
  programAccountholders: programAccountholdersRequirements,
  programAccountholderAccount: programAccountholderAccountRequirements,
  programAccountholderAccountActivity: programAccountholderAccountActivityRequirements,
  programAccountholderCard: programAccountholderCardRequirements,
  programAccountholderCardActivity: programAccountholderCardActivityRequirements,
  programAccountholderActivate: programAccountholderActivateRequirements,
  programAccountholderSuspend: programAccountholderSuspendRequirements,
  programAccountholderTerminate: programAccountholderTerminateRequirements,
  programAccountholderTransaction: programAccountholderTransactionRequirements,
  programAchFunding: programAchFundingRequirements,
  programAchFundingTransfer: programAchFundingTransferRequirements,
  programAchFundingTransferFunds: programAchFundingTransferFundsRequirements,
  programCardOrderAndShipment: programCardOrderAndShipmentRequirements,
  programCommandoMode: programCommandoModeRequirements,
  programCommandoModes: programCommandoModesRequirements,
  programDigitalWalletToken: programDigitalWalletTokenRequirements,
  programBalance: programBalanceRequirements,
  programHSMKeys: programHSMKeysRequirements,
  programAssignHSMKeys: programHSMKeysRequirements,
  programInventoryCardPackages: programInventoryCardPackagesRequirements,
  programInventoryOrderSummary: programInventoryOrderSummaryRequirements,
  programInventorySku: programInventorySkuRequirements,
  programProvider: programProviderRequirements,
  programProviderCreate: programProviderCreateRequirements,
  programProviders: programProvidersRequirements,
  programReviews: programReviewsRequirements,
  programReviewDetails: programReviewDetailsRequirements,
  programSku: programSkuRequirements,
  programSkuCreate: programSkuCreateRequirements,
  programSkus: programSkusRequirements,
  reports: reportsRequirements,
  reportsDataDictionary: reportsDataDictionaryRequirements,
  reportsGuide: reportsGuideRequirements,
  credit: creditRequirements,
  creditBundle: creditBundleRequirements,
  creditBundles: creditBundlesRequirements,
  creditBundlesCreate: creditBundlesCreateRequirements,
  creditPolicyCreate: creditPolicyCreateRequirements,
  creditPolicyLibrary: creditPolicyLibraryRequirements,
  creditPolicyLibraryCreditProductsDashboard:
    creditPolicyLibraryCreditProductsDashboardRequirements,
  creditPolicyLibraryCreditProduct: creditPolicyLibraryCreditProductRequirements,
  creditPolicyLibraryAPRsDashboard: creditPolicyLibraryAPRsDashboardRequirements,
  creditPolicyLibraryAPR: creditPolicyLibraryAPRRequirements,
  creditPolicyLibraryFeesDashboard: creditPolicyLibraryFeesDashboardRequirements,
  creditPolicyLibraryFee: creditPolicyLibraryFeeRequirements,
  creditPolicyLibraryRewardsDashboard: creditPolicyLibraryRewardsDashboardRequirements,
  creditPolicyLibraryOffersDashboard: creditPolicyLibraryOffersDashboardRequirements,
  creditProductApprovalQueue: creditProductApprovalQueueRequirements,
  creditBundleWorkQueue: creditBundleWorkQueueRequirements,
  creditProduct: creditProductsRequirements,
  creditProducts: creditProductsRequirements,
  creditProductsRejected: creditProductsRejectedRequirements,
  creditTemplates: creditTemplatesRequirements,
  creditProductCreate: creditProductCreateRequirements,
  creditOffer: creditOffersRequirements,
  creditOffers: creditOffersRequirements,
  creditOfferCreate: creditOfferCreateRequirements,
  creditTemplateEditor: creditTemplateEditorRequirements,
  creditWelcome: creditWelcomeRequirements,
  creditAccount: creditAccountsRequirements,
  creditAccounts: creditAccountsRequirements,
  risk: riskRequirements,
  riskAlerts: riskAlertsRequirements,
  riskBulkUpload: riskBulkUploadRequirements,
  riskCaseManagement: riskCaseManagementRequirements,
  riskDecisionManager: riskDecisionManagerRequirements,
  riskDisputes: riskDisputesRequirements,
  riskDispute: riskDisputesRequirements,
  riskNewDispute: riskDisputesRequirements,
  riskBankReporting: riskBankReportingRequirements,
  riskTransactionReport: riskTransactionReportRequirements,
  riskRealTimeDecisioning: riskRealTimeDecisioningRequirements,
  riskRealTimeDecisioningDashboard: riskRealTimeDecisioningDashboardRequirements,
  scheduler: schedulerRequirements,
  stories: storiesRequirements,
  threeDS: threeDSRequirements,
  tokenization: tokenizationRequirements,
  tokenizationDetail: tokenizationDetailRequirements,
  userProfile: userProfileRequirements,
  userActivityLogs: userActivityLogsRequirements,
  programCardCreation: programCardCreationRequirements,
  home: homeRequirements,

  // Merge with other requirements.
  ...fdsRequirements,
  ...programControlsRequirements,
  ...mccGroupRequirements,
  ...acceptedCountriesRequirements,
  ...cardProductRequirements,
  ...applicationTokensRequirements,
  ...adminUserDetailsRequirements,
};

// The verifyer map contains functions that will check authorization
// THIS IS WHERE THE ROUTE PROTECTION HAPPENS

// if you have complex requirements/verifications define in a separate file and import
// if you just want to let everyone in just set value as () => true
export const verifierMap = {
  achManagement: achManagementVerifier,
  admin: adminVerifier,
  adminConfigManagement: adminConfigManagementVerifier,
  adminDepartments: adminDepartmentsVerifier,
  adminSupplements: adminSupplementsVerifier,
  adminRoles: adminRolesVerifier,
  adminSandboxesManagement: adminSandboxesManagementVerifier,
  apiAccess: apiAccessVerifier,
  auditLogs: auditLogsVerifier,
  binDataManagement: binDataManagementVerifier,
  binDataManagementCreateBin: binDataManagementCreateBinVerifier,
  binDataManagementCreateRange: binDataManagementCreateRangeVerifier,
  binDataManagementUpdateRange: binDataManagementUpdateRangeVerifier,
  binDataManagementUpdateBin: binDataManagementUpdateBinVerifier,
  binDataManagementDetailsBin: binDataManagementDetailsBinVerifier,
  binDataManagementDetailsRange: binDataManagementDetailsRangeVerifier,
  binManagement: binManagementVerifier,
  binManagementBinView: binManagementBinViewVerifier,
  binManagementRangeView: binManagementRangeViewVerifier,
  binManagementProgramView: binManagementProgramViewVerifier,
  decisionManager: decisionManagerVerifier,
  development: developmentVerifier,
  developmentTransactionTimeline: developmentTransactionTimelineVerifier,
  developmentWebhooksNew: developmentWebhooksNewVerifier,
  developmentWebhooksOverview: developmentWebhooksOverviewVerifier,
  developmentWebhooksShow: developmentWebhooksShowVerifier,
  diDataCatalog: diDataCatalogVerifier,
  directDeposit: directDepositVerifier,
  pinReveal: pinRevealVerifier,
  program: programVerifier,
  programBusiness: programBusinessVerifier,
  programBusinesses: programBusinessesVerifier,
  programCard: programCardVerifier,
  programCardPackage: programCardPackageVerifier,
  programCardPackageCreate: programCardPackageCreateVerifier,
  programCardPackages: programCardPackagesVerifier,
  programCardProducts: programCardProductsVerifier,
  programCardProduct: programCardProductVerifier,
  programBulkCardProducts: programBulkCardProductsVerifier,
  programBulkIssuance: programBulkIssuanceVerifier,
  programCardManagementProviders: programCardManagementProvidersVerifier,
  programCardManagementInventory: programCardManagementInventoryVerifier,
  cardManagementInventoryDetails: programCardManagementInventoryVerifier,
  programBulkIssuanceCreate: programBulkIssuanceViewVerifier,
  programBulkIssuanceDetail: programBulkIssuanceViewVerifier,
  programCardholder: programCardholderVerifier,
  programCardholderAccount: programCardholderAccountVerifier,
  programCardholders: programCardholdersVerifier,
  programAccountholder: programAccountholderVerifier,
  programAccountholderActivity: programAccountholderActivityVerifier,
  programAccountholders: programAccountholdersVerifier,
  programAccountholderAccount: programAccountholderAccountVerifier,
  programAccountholderAccountActivity: programAccountholderAccountActivityVerifier,
  programAccountholderCard: programAccountholderCardVerifier,
  programAccountholderCardActivity: programAccountholderCardActivityVerifier,
  programAccountholderActivate: programAccountholderActivateVerifier,
  programAccountholderSuspend: programAccountholderSuspendVerifier,
  programAccountholderTerminate: programAccountholderTerminateVerifier,
  programAccountholderTransaction: programAccountholderTransactionVerifier,
  programAchFunding: programAchFundingVerifier,
  programAchFundingTransfer: programAchFundingTransferVerifier,
  programAchFundingTransferFunds: programAchFundingTransferFundsVerifier,
  programCardOrderAndShipment: programCardOrderAndShipmentVerifier,
  programCommandoMode: programCommandoModeVerifier,
  programCommandoModes: programCommandoModesVerifier,
  programDigitalWalletToken: programDigitalWalletTokenVerifier,
  programBalance: programBalanceVerifier,
  programHSMKeys: programHSMKeysVerifier,
  programAssignHSMKeys: programHSMKeysVerifier,
  programInventoryCardPackages: programInventoryCardPackagesVerifier,
  programInventoryOrderSummary: programInventoryOrderSummaryVerifier,
  programInventorySku: programInventorySkuVerifier,
  programProvider: programProviderVerifier,
  programProviderCreate: programProviderCreateVerifier,
  programProviders: programProvidersVerifier,
  programReviews: programReviewsVerifier,
  programReviewDetails: programReviewDetailsVerifier,
  programSku: programSkuVerifier,
  programSkuCreate: programSkuCreateVerifier,
  programSkus: programSkusVerifier,
  reports: reportsVerifier,
  reportsDataDictionary: reportsDataDictionaryVerifier,
  reportsGuide: reportsGuideVerifier,
  credit: creditVerifier,
  creditBundle: creditBundleVerifier,
  creditBundles: creditBundlesVerifier,
  creditBundlesCreate: creditBundlesCreateVerifier,
  creditPolicyCreate: creditPolicyCreateVerifier,
  creditPolicyLibrary: creditPolicyLibraryVerifier,
  creditPolicyLibraryCreditProductsDashboard: creditPolicyLibraryCreditProductsDashboardVerifier,
  creditPolicyLibraryCreditProduct: creditPolicyLibraryCreditProductVerifier,
  creditPolicyLibraryAPRsDashboard: creditPolicyLibraryAPRsDashboardVerifier,
  creditPolicyLibraryAPR: creditPolicyLibraryAPRVerifier,
  creditPolicyLibraryFeesDashboard: creditPolicyLibraryFeesDashboardVerifier,
  creditPolicyLibraryFee: creditPolicyLibraryFeeVerifier,
  creditPolicyLibraryRewardsDashboard: creditPolicyLibraryRewardsDashboardVerifier,
  creditPolicyLibraryOffersDashboard: creditPolicyLibraryOffersDashboardVerifier,
  creditProductApprovalQueue: creditProductApprovalQueueVerifier,
  creditBundleWorkQueue: creditBundleWorkQueueVerifier,
  creditProduct: creditProductsVerifier,
  creditProducts: creditProductsVerifier,
  creditProductsRejected: creditProductsRejectedVerifier,
  creditTemplates: creditTemplatesVerifier,
  creditProductCreate: creditProductCreateVerifier,
  creditOffer: creditOffersVerifier,
  creditOffers: creditOffersVerifier,
  creditOfferCreate: creditOfferCreateVerifier,
  creditTemplateEditor: creditTemplateEditorVerifier,
  creditWelcome: creditWelcomeVerifier,
  creditAccount: creditAccountsVerifier,
  creditAccounts: creditAccountsVerifier,
  risk: riskVerifier,
  riskAlerts: riskAlertsVerifier,
  riskBulkUpload: riskBulkUploadVerifier,
  riskCaseManagement: riskCaseManagementVerifier,
  riskDecisionManager: riskDecisionManagerVerifier,
  riskDisputes: riskDisputesVerifier,
  riskDispute: riskDisputesVerifier,
  riskNewDispute: riskDisputesVerifier,
  riskBankReporting: riskBankReportingVerifier,
  riskTransactionReport: riskTransactionReportVerifier,
  riskRealTimeDecisioning: riskRealTimeDecisioningVerifier,
  riskRealTimeDecisioningDashboard: riskRealTimeDecisioningDashboardVerifier,
  scheduler: schedulerVerifier,
  stories: storiesVerifier,
  threeDS: threeDSVerifier,
  tokenization: tokenizationVerifier,
  tokenizationDetail: tokenizationDetailVerifier,
  userProfile: userProfileVerifier,
  userActivityLogs: userActivityLogsVerifier,
  programCardCreation: programCardCreationVerifier,
  home: homeVerifier,

  // Merge with other verifiers.
  ...approvalQueueVerifiers,
  ...fdsVerifiers,
  ...programControlVerifiers,
  ...mccGroupVerifiers,
  ...acceptedCountriesVerifiers,
  ...cardProductVerifiers,
  ...applicationTokensVerifiers,
  ...adminUserDetailsVerifiers,
};

export const programAccessVerifierMap = {
  achManagement: achManagementProgramVerifier,
  programCardPackage: programCardPackageProgramAccessVerifier,
  programCardPackageCreate: programCardPackageCreateProgramAccessVerifier,
  programCardPackages: programCardPackagesProgramAccessVerifier,
  programCardProducts: programCardProductsCreateProgramAccessVerifier,
  programCardProduct: programCardProductCreateProgramAccessVerifier,
  programDigitalWalletToken: programDigitalWalletTokenProgramAccessVerifier,
  programBulkCardProducts: programBulkCardProductsCreateProgramAccessVerifier,
  programInventoryCardPackages: programInventoryCardPackagesProgramAccessVerifier,
  programInventoryOrderSummary: programInventoryOrderSummaryProgramAccessVerifier,
  programInventorySku: programInventorySkuProgramAccessVerifier,
  programProvider: programProviderProgramAccessVerifier,
  programProviderCreate: programProviderCreateProgramAccessVerifier,
  programProviders: programProvidersProgramAccessVerifier,
  programSku: programSkuProgramAccessVerifier,
  programSkuCreate: programSkuCreateProgramAccessVerifier,
  programSkus: programSkusProgramAccessVerifier,
  threeDS: threeDSProgramAccessVerifier,
};

export const redirectMap = {
  programAccountholders: programAccountholdersRedirect,
  programAccountholder: programAccountholderRedirect,
  programAccountholderActivity: programAccountholderActivityRedirect,
  programAccountholderAccount: programAccountholderAccountRedirect,
  programAccountholderAccountActivity: programAccountholderAccountActivityRedirect,
  programAccountholderCard: programAccountholderCardRedirect,
  programAccountholderCardActivity: programAccountholderCardActivityRedirect,
  programAccountholderTransaction: programAccountholderTransactionRedirect,
  programAccountholderTerminate: programAccountholderTerminateRedirect,
  programAccountholderSuspend: programAccountholderSuspendRedirect,
  programCardholders: programCardholdersRedirect,
  programCardholder: programCardholderRedirect,
  programCardholderAccount: programCardholderAccountRedirect,
  programCard: programCardRedirect,
};

const getRouteKeyByKeyPathOrName = (keyPathOrName) => {
  if (routeMap[keyPathOrName]) return keyPathOrName;
  for (const routeKey in routeMap) {
    const { path, name } = routeMap[routeKey];
    if (path === keyPathOrName || name === keyPathOrName) {
      return routeKey;
    }
  }
  return null;
};

export const requirements = (keyPathOrName) => {
  const routeKey = getRouteKeyByKeyPathOrName(keyPathOrName);
  return requirementsMap[routeKey];
};

export const verifier = (keyPathOrName, params) => {
  const routeKey = getRouteKeyByKeyPathOrName(keyPathOrName);
  const routeVerifier = verifierMap[routeKey];
  const invalidSandboxRoute = isInvalidSandboxRoute(routeKey);

  if (invalidSandboxRoute) {
    return false;
  } else {
    return routeVerifier && routeVerifier(params);
  }
};

export const programAccessVerifier = (keyPathOrName) => {
  const routeKey = getRouteKeyByKeyPathOrName(keyPathOrName);
  const routeVerifier = programAccessVerifierMap[routeKey];
  return routeVerifier ? routeVerifier() : true;
};

export const redirects = (keyPathOrName) => {
  const routeKey = getRouteKeyByKeyPathOrName(keyPathOrName);
  const routeRedirect = redirectMap[routeKey];
  const invalidSandboxRoute = isInvalidSandboxRoute(routeKey);
  if (invalidSandboxRoute) {
    return 'home';
  } else {
    return routeRedirect ? routeRedirect() : false;
  }
};
