import React from 'react';
import { StatusIndicator, Flex } from '@mqd/volt-base';
import { formatDate } from './utils.js';
import { getTxnDisplayType } from '../../../../components/transaction-details/utils.js';

export const additionalDetailsText = {
  card_holder_presence: {
    truthy: 'Card holder present',
    falsy: 'No card holder present',
  },
  card_presence: {
    truthy: 'Card present',
    falsy: 'No card present',
  },
  is_installment: {
    truthy: 'Installment',
    falsy: 'Not installment',
  },
  partial_approval_capable: {
    truthy: 'Partial approval capable',
    falsy: 'Not partial approval capable',
  },
  pin_entry_mode: {
    truthy: 'PIN entry mode present',
    falsy: 'No PIN entry mode',
  },
  pin_present: {
    truthy: 'PIN present',
    falsy: 'No PIN present',
  },
  purchase_amount_only: {
    truthy: 'Purchase amount only',
    falsy: 'Not purchase amount only',
  },
  is_recurring: {
    truthy: 'Recurring',
    falsy: 'Not recurring',
  },
};

export const stateToPillLabel = {
  PENDING: { type: 'pending', label: 'Pending' },
  CLEARED: { type: 'success', label: 'Cleared' },
  COMPLETION: { type: 'success', label: 'Completed' },
  DECLINED: { type: 'error', label: 'Declined' },
  ERROR: { type: 'error', label: 'Error' },
  COMPLETE: { type: 'success', label: 'Complete' },
  APPLIED: { type: 'success', label: 'Applied' },
  REJECTED: { type: 'error', label: 'Rejected' },
  REVERSED: { type: 'light', label: 'Reversed' },
};

export const cardStatusToPillLabel = {
  ACTIVE: { type: 'success', label: 'Active' },
  SUSPENDED: { type: 'warning', label: 'Suspended' },
  DELETED: { type: 'error', label: 'Deleted' },
  UNSUPPORTED: { type: 'warning', label: 'Unsupported ' },
  DEACTIVATED: { type: 'dark', label: 'Deactivated' },
};

export const creditDebitDisplayName = {
  C: 'Credit',
  D: 'Debit',
};

export const TRANSACTION_TYPES = {
  ADDRESS_VERIFICATION_AUTHORIZATION: 'Address verification authorization',
  DECLINED_AUTHORIZATION: 'Declined Authorization',
  ECOMMERCE_AUTHORIZATION: 'Ecommerce authorization',
  GENERIC_TRANSACTION: 'Generic transaction',
  JIT_AUTHORIZATION: 'JIT Authorization',
  MULTICURRENCY: 'Multicurrency',
  TOKEN_ECOMMERCE_AUTHORIZATION: 'Token Ecommerce Authorization',
  TOKEN_AUTHORIZATION: 'Token Authorization',
};

export const TRANSACTION_SECTIONS = {
  ACCOUNT_BALANCES: 'Resulting account balances',
  ADDITIONAL_DETAILS: 'Additional details',
  ADDRESS_VERIFICATION: 'Address verification',
  AMOUNT: 'Amount',
  DEFINED_FIELDS: ' defined fields',
  DEVICE: 'Device',
  DIGITAL_WALLET_TOKEN: 'Digital wallet token',
  JIT_FUNDING: 'JIT funding',
  MERCHANT_CARD: 'Merchant card',
  POS: 'POS details',
  REFERENCE_DETAILS: 'Reference details',
  RELATED_TRANSACTIONS: 'Related transactions',
  TOKENS: 'Tokens',
  TRANSACTION_CARD: 'Transaction card',
  WALLET_PROVIDER_PROFILE: 'Wallet provider profile',
};

export const FIELDS = {
  ACCOUNT_ID: 'Account ID',
  ACTING_USER: 'Acting user',
  ACQUIRER_EXEMPTION: 'Acquirer exemption',
  ACQUIRER_INSTITUTION_COUNTRY: 'Acquirer institution country',
  ACQUIRER_INSTITUTION_ID_CODE: 'Acquirer institution ID code',
  ACQUIRER_REFERENCE_DATA: 'Acquirer reference data',
  ACQUIRER_RETRIEVAL_REFERENCE_NUMBER: 'Acquirer retrieval reference number',
  ACQUIRER_SYSTEM_TRACE_AUDIT_NUMBER: 'Acquirer system trace audit number',
  ATM_SUBNETWORK: 'ATM sub network',
  AMOUNT: 'Amount',
  AUTHENTICATION_METHOD: 'Authentication method',
  AUTHENTICATION_STATUS: 'Authentication status',
  AVAILABLE_BALANCE: 'Available balance',
  CACHED_BALANCE: 'Cached balance',
  CARD: 'Card',
  CARD_DATA_INPUT_CAPABILITY: 'Card data input capability',
  CARD_HOLDER_AUTHENTICATION_METHOD: 'Card holder authentication method',
  CARD_LAST_FOUR: 'Card last four',
  CARD_METADATA: 'Card metadata',
  CARD_PRODUCT: 'Card product',
  CONVERSION_RATE: 'Conversion rate',
  CREDIT_BALANCE: 'Credit balance',
  CURRENCY_CODE: 'Currency code',
  DECLINE_REASON: 'Decline reason',
  DEVICE_ID: 'Device ID',
  DEVICE_SCORE: 'Device score',
  DWT_CARD_TOKEN: 'Card token',
  ELECTRONIC_COMMERCE_INDICATOR: 'Electronic commerce indicator',
  EXTERNAL_CARD: 'External card',
  EMAIL_ADDRESS: 'Email address',
  FEES: 'Fees',
  FUNDING_TYPE: 'Funding type',
  FULFILLMENT_STATUS: 'Fulfillment status',
  IMPACTED_AMOUNT: 'Impacted amount',
  IP_ADDRESS: 'IP address',
  ISSUER_ELIGIBILITY_DECISION: 'Issuer eligibility decision',
  JIT_FUNDING: 'JIT funding',
  LANGUAGE_CODE: 'Language code',
  LEDGER_BALANCE: 'Ledger balance',
  LOCATION: 'Location',
  MARQETA_RESPONSE: 'Marqeta response',
  MEMO: 'Memo',
  MERCHANT_ID: 'Merchant ID',
  METHOD: 'Method',
  MOTO_INDICATOR: 'Moto indicator',
  NAME: 'Name',
  NETWORK_REFERENCE_ID: 'Network reference ID',
  ORIGINAL_AMOUNT: 'Original amount',
  ORIGINAL_CURRENCY_CODE: 'Original currency code',
  ORIGINAL_JIT_FUNDING: 'Original JIT funding',
  PAN_ENTRY_MODE: 'PAN entry mode',
  PAN_SOURCE: 'PAN source',
  PAN_REFERENCE_ID: 'PAN reference ID',
  PAYMENT_CHANNEL: 'Payment channel',
  PENDING_CREDITS: 'Pending credits',
  PHONE_NUMBER: 'Phone number',
  PIN_ENTRY_MODE: 'PIN entry mode',
  PROGRAM_RESPONSE: ' response',
  REASON_CODE: 'Reason code',
  RESPONSE_CODE: 'Response code',
  REQUEST_AMOUNT: 'Request amount',
  SCORE: 'Score',
  STATE: 'State',
  STATE_REASON: 'State reason',
  STREET_ADDRESS: 'Street address',
  TAGS: 'Tags',
  TERMINAL_ATTENDANCE: 'Terminal attendance',
  TERMINAL_ID: 'Terminal ID',
  THREEDS_VERSION: '3DS version',
  TIMED_OUT: 'Timed out',
  TOKEN: 'Token',
  TOKEN_ELIGIBILITY_DECISION: 'Token eligibility decision',
  TOKEN_EXPIRATION: 'Token expiration',
  TOKEN_PAN: 'Token PAN',
  TOKEN_REFERENCE_ID: 'Token reference ID',
  TOKEN_REQUESTOR_ID: 'Token requestor ID',
  TOKEN_REQUESTOR_NAME: 'Token requestor name',
  TOKEN_TYPE: 'Token type',
  TRANSACTION: 'Transaction',
  TRANSACTION_APPROVAL_CODE: 'Transaction approval code',
  TRANSACTION_IDENTIFIER: 'Transaction identifier',
  TRANSFER: 'Transfer',
  TYPE: 'Type',
  USER_METADATA: 'User metadata',
  VERIFICATION_RESULT: 'Verification result',
  VERIFICATION_VALUE_CREATED_BY: 'Verification value created by',
  VERSION: 'Version',
  ZIP: 'Zip',
};

export const SUBSECTIONS = {
  BALANCES: 'Balances',
  CARD_HOLDER_AUTHENTICATION: 'Card Holder Authentication',
  DURATIONS: 'Durations',
  FEES: 'Fees',
  SETTLEMENT_DATA: 'Settlement Data',
};

export const JIT_FUNDING_FIELDS = {
  [TRANSACTION_TYPES.ADDRESS_VERIFICATION_AUTHORIZATION]: [
    FIELDS.FUNDING_TYPE,
    FIELDS.MARQETA_RESPONSE,
  ],
  [TRANSACTION_TYPES.DECLINED_AUTHORIZATION]: [
    FIELDS.DECLINE_REASON,
    FIELDS.FUNDING_TYPE,
    FIELDS.MARQETA_RESPONSE,
  ],
  [TRANSACTION_TYPES.ECOMMERCE_AUTHORIZATION]: [FIELDS.FUNDING_TYPE, FIELDS.MARQETA_RESPONSE],
  [TRANSACTION_TYPES.GENERIC_TRANSACTION]: [
    FIELDS.AMOUNT,
    FIELDS.FUNDING_TYPE,
    FIELDS.MARQETA_RESPONSE,
  ],
  [TRANSACTION_TYPES.JIT_AUTHORIZATION]: [
    FIELDS.AMOUNT,
    FIELDS.AVAILABLE_BALANCE,
    FIELDS.CACHED_BALANCE,
    FIELDS.CREDIT_BALANCE,
    FIELDS.FUNDING_TYPE,
    FIELDS.LEDGER_BALANCE,
    FIELDS.MARQETA_RESPONSE,
    FIELDS.MEMO,
    FIELDS.METHOD,
    FIELDS.PENDING_CREDITS,
    FIELDS.PROGRAM_RESPONSE,
    FIELDS.RESPONSE_CODE,
    FIELDS.TAGS,
    FIELDS.TIMED_OUT,
  ],
  [TRANSACTION_TYPES.MULTICURRENCY]: [FIELDS.FUNDING_TYPE, FIELDS.MARQETA_RESPONSE],
  [TRANSACTION_TYPES.TOKEN_AUTHORIZATION]: [FIELDS.FUNDING_TYPE, FIELDS.MARQETA_RESPONSE],
  [TRANSACTION_TYPES.TOKEN_ECOMMERCE_AUTHORIZATION]: [FIELDS.FUNDING_TYPE, FIELDS.MARQETA_RESPONSE],
};

export const JIT_FUNDING_SUBSECTIONS = {
  [TRANSACTION_TYPES.ADDRESS_VERIFICATION_AUTHORIZATION]: [SUBSECTIONS.DURATIONS],
  [TRANSACTION_TYPES.DECLINED_AUTHORIZATION]: [SUBSECTIONS.DURATIONS],
  [TRANSACTION_TYPES.ECOMMERCE_AUTHORIZATION]: [SUBSECTIONS.DURATIONS],
  [TRANSACTION_TYPES.GENERIC_TRANSACTION]: [SUBSECTIONS.DURATIONS],
  [TRANSACTION_TYPES.JIT_AUTHORIZATION]: [SUBSECTIONS.BALANCES, SUBSECTIONS.DURATIONS],
  [TRANSACTION_TYPES.MULTICURRENCY]: [SUBSECTIONS.DURATIONS],
  [TRANSACTION_TYPES.TOKEN_AUTHORIZATION]: [SUBSECTIONS.DURATIONS],
  [TRANSACTION_TYPES.TOKEN_ECOMMERCE_AUTHORIZATION]: [SUBSECTIONS.DURATIONS],
};

export const POS_FIELDS = {
  [TRANSACTION_TYPES.ADDRESS_VERIFICATION_AUTHORIZATION]: [
    FIELDS.CARD_DATA_INPUT_CAPABILITY,
    FIELDS.CARD_LAST_FOUR,
    FIELDS.FEES,
    FIELDS.MERCHANT_ID,
    FIELDS.PAN_ENTRY_MODE,
    FIELDS.PAYMENT_CHANNEL,
    FIELDS.PIN_ENTRY_MODE,
    FIELDS.TERMINAL_ATTENDANCE,
    FIELDS.TERMINAL_ID,
  ],
  [TRANSACTION_TYPES.DECLINED_AUTHORIZATION]: [
    FIELDS.ACQUIRER_EXEMPTION,
    FIELDS.CARD_DATA_INPUT_CAPABILITY,
    FIELDS.CARD_LAST_FOUR,
    FIELDS.FEES,
    FIELDS.MERCHANT_ID,
    FIELDS.MOTO_INDICATOR,
    FIELDS.PAN_ENTRY_MODE,
    FIELDS.PAYMENT_CHANNEL,
    FIELDS.PIN_ENTRY_MODE,
    FIELDS.TERMINAL_ATTENDANCE,
    FIELDS.TERMINAL_ID,
  ],
  [TRANSACTION_TYPES.ECOMMERCE_AUTHORIZATION]: [
    FIELDS.AUTHENTICATION_METHOD,
    FIELDS.AUTHENTICATION_STATUS,
    FIELDS.CARD_DATA_INPUT_CAPABILITY,
    FIELDS.CARD_LAST_FOUR,
    FIELDS.ELECTRONIC_COMMERCE_INDICATOR,
    FIELDS.FEES,
    FIELDS.MERCHANT_ID,
    FIELDS.PAN_ENTRY_MODE,
    FIELDS.PAYMENT_CHANNEL,
    FIELDS.PIN_ENTRY_MODE,
    FIELDS.TERMINAL_ATTENDANCE,
    FIELDS.TERMINAL_ID,
    FIELDS.THREEDS_VERSION,
    FIELDS.VERIFICATION_RESULT,
    FIELDS.VERIFICATION_VALUE_CREATED_BY,
  ],
  [TRANSACTION_TYPES.GENERIC_TRANSACTION]: [
    FIELDS.CARD_DATA_INPUT_CAPABILITY,
    FIELDS.CARD_LAST_FOUR,
    FIELDS.FEES,
    FIELDS.MERCHANT_ID,
    FIELDS.PAN_ENTRY_MODE,
    FIELDS.PAYMENT_CHANNEL,
    FIELDS.PIN_ENTRY_MODE,
    FIELDS.TERMINAL_ATTENDANCE,
    FIELDS.TERMINAL_ID,
  ],
  [TRANSACTION_TYPES.JIT_AUTHORIZATION]: [
    FIELDS.CARD_DATA_INPUT_CAPABILITY,
    FIELDS.CARD_LAST_FOUR,
    FIELDS.FEES,
    FIELDS.MERCHANT_ID,
    FIELDS.PAN_ENTRY_MODE,
    FIELDS.PAYMENT_CHANNEL,
    FIELDS.PIN_ENTRY_MODE,
    FIELDS.TERMINAL_ATTENDANCE,
    FIELDS.TERMINAL_ID,
    FIELDS.ELECTRONIC_COMMERCE_INDICATOR,
    FIELDS.AUTHENTICATION_METHOD,
    FIELDS.AUTHENTICATION_STATUS,
    FIELDS.THREEDS_VERSION,
    FIELDS.VERIFICATION_RESULT,
    FIELDS.VERIFICATION_VALUE_CREATED_BY,
  ],
  [TRANSACTION_TYPES.MULTICURRENCY]: [
    FIELDS.CARD_LAST_FOUR,
    FIELDS.FEES,
    FIELDS.MERCHANT_ID,
    FIELDS.PAYMENT_CHANNEL,
    FIELDS.TERMINAL_ID,
  ],
  [TRANSACTION_TYPES.TOKEN_AUTHORIZATION]: [
    FIELDS.CARD_DATA_INPUT_CAPABILITY,
    FIELDS.CARD_LAST_FOUR,
    FIELDS.FEES,
    FIELDS.MERCHANT_ID,
    FIELDS.PAN_ENTRY_MODE,
    FIELDS.PAYMENT_CHANNEL,
    FIELDS.PIN_ENTRY_MODE,
    FIELDS.TERMINAL_ATTENDANCE,
    FIELDS.TERMINAL_ID,
  ],
  [TRANSACTION_TYPES.TOKEN_ECOMMERCE_AUTHORIZATION]: [
    FIELDS.CARD_DATA_INPUT_CAPABILITY,
    FIELDS.CARD_HOLDER_AUTHENTICATION_METHOD,
    FIELDS.CARD_LAST_FOUR,
    FIELDS.FEES,
    FIELDS.MERCHANT_ID,
    FIELDS.PAN_ENTRY_MODE,
    FIELDS.PAYMENT_CHANNEL,
    FIELDS.PIN_ENTRY_MODE,
    FIELDS.TERMINAL_ATTENDANCE,
    FIELDS.TERMINAL_ID,
  ],
};

export const POS_SUBSECTIONS = {
  [TRANSACTION_TYPES.DECLINED_AUTHORIZATION]: [
    SUBSECTIONS.CARD_HOLDER_AUTHENTICATION,
    SUBSECTIONS.FEES,
  ],
  [TRANSACTION_TYPES.ECOMMERCE_AUTHORIZATION]: [
    SUBSECTIONS.CARD_HOLDER_AUTHENTICATION,
    SUBSECTIONS.FEES,
  ],
  [TRANSACTION_TYPES.GENERIC_TRANSACTION]: [SUBSECTIONS.FEES],
  [TRANSACTION_TYPES.JIT_AUTHORIZATION]: [SUBSECTIONS.CARD_HOLDER_AUTHENTICATION, SUBSECTIONS.FEES],
  [TRANSACTION_TYPES.MULTICURRENCY]: [SUBSECTIONS.FEES],
  [TRANSACTION_TYPES.TOKEN_ECOMMERCE_AUTHORIZATION]: [
    SUBSECTIONS.CARD_HOLDER_AUTHENTICATION,
    SUBSECTIONS.FEES,
  ],
};

export const AMOUNT_FIELDS = {
  [TRANSACTION_TYPES.ADDRESS_VERIFICATION_AUTHORIZATION]: [
    FIELDS.AMOUNT,
    FIELDS.CONVERSION_RATE,
    FIELDS.CURRENCY_CODE,
    FIELDS.ORIGINAL_AMOUNT,
    FIELDS.ORIGINAL_CURRENCY_CODE,
    FIELDS.REQUEST_AMOUNT,
  ],
  [TRANSACTION_TYPES.DECLINED_AUTHORIZATION]: [
    FIELDS.AMOUNT,
    FIELDS.CONVERSION_RATE,
    FIELDS.CURRENCY_CODE,
    FIELDS.ORIGINAL_AMOUNT,
    FIELDS.ORIGINAL_CURRENCY_CODE,
    FIELDS.REQUEST_AMOUNT,
  ],
  [TRANSACTION_TYPES.ECOMMERCE_AUTHORIZATION]: [
    FIELDS.AMOUNT,
    FIELDS.CONVERSION_RATE,
    FIELDS.CURRENCY_CODE,
    FIELDS.ORIGINAL_AMOUNT,
    FIELDS.ORIGINAL_CURRENCY_CODE,
    FIELDS.REQUEST_AMOUNT,
  ],
  [TRANSACTION_TYPES.JIT_AUTHORIZATION]: [
    FIELDS.AMOUNT,
    FIELDS.CONVERSION_RATE,
    FIELDS.CURRENCY_CODE,
    FIELDS.ORIGINAL_AMOUNT,
    FIELDS.ORIGINAL_CURRENCY_CODE,
    FIELDS.REQUEST_AMOUNT,
  ],
  [TRANSACTION_TYPES.MULTICURRENCY]: [
    FIELDS.AMOUNT,
    FIELDS.CONVERSION_RATE,
    FIELDS.CURRENCY_CODE,
    FIELDS.ORIGINAL_AMOUNT,
    FIELDS.ORIGINAL_CURRENCY_CODE,
    FIELDS.REQUEST_AMOUNT,
  ],
  [TRANSACTION_TYPES.TOKEN_AUTHORIZATION]: [
    FIELDS.AMOUNT,
    FIELDS.CONVERSION_RATE,
    FIELDS.CURRENCY_CODE,
    FIELDS.ORIGINAL_AMOUNT,
    FIELDS.ORIGINAL_CURRENCY_CODE,
    FIELDS.REQUEST_AMOUNT,
  ],
  [TRANSACTION_TYPES.TOKEN_ECOMMERCE_AUTHORIZATION]: [
    FIELDS.AMOUNT,
    FIELDS.CONVERSION_RATE,
    FIELDS.CURRENCY_CODE,
    FIELDS.ORIGINAL_AMOUNT,
    FIELDS.ORIGINAL_CURRENCY_CODE,
    FIELDS.REQUEST_AMOUNT,
  ],
};

export const AMOUNT_SUBSECTIONS = {
  [TRANSACTION_TYPES.MULTICURRENCY]: [SUBSECTIONS.SETTLEMENT_DATA],
};

export const BLOCKS = {
  DIGITAL_WALLET_TOKEN: 'Digital wallet token',
  DEVICE: 'Device',
  ADDRESS_VERIFICATION: 'Address verification',
};

export const TRANSACTION_CONDITIONAL_BLOCKS = {
  [TRANSACTION_TYPES.TOKEN_ECOMMERCE_AUTHORIZATION]: [BLOCKS.DIGITAL_WALLET_TOKEN, BLOCKS.DEVICE],
  [TRANSACTION_TYPES.TOKEN_AUTHORIZATION]: [BLOCKS.DIGITAL_WALLET_TOKEN, BLOCKS.DEVICE],
  [TRANSACTION_TYPES.ADDRESS_VERIFICATION_AUTHORIZATION]: [BLOCKS.ADDRESS_VERIFICATION],
};

export const DIGITAL_WALLET_TOKEN_CONDITIONAL_FIELDS = {
  [TRANSACTION_TYPES.TOKEN_ECOMMERCE_AUTHORIZATION]: [FIELDS.DWT_CARD_TOKEN],
};

export const RELATED_TRANSACTIONS_TABLE_COLUMNS = [
  {
    header: 'Relation',
    accessor: 'relation',
    key: 'relation',
  },
  {
    header: 'Created time',
    accessor: 'created_time',
    key: 'created_time',
    width: 153,
    renderer: ({ cellValue }) => formatDate(cellValue),
  },
  {
    header: 'State and type',
    accessor: 'state',
    key: 'state',
    width: 244,
    renderer: ({ cellValue, row }) => {
      return (
        <Flex flexDirection="row" alignItems="center" justifyContent="left">
          <StatusIndicator status={cellValue} />
          <div>&nbsp;{getTxnDisplayType({ type: row?.type })}</div>
        </Flex>
      );
    },
  },
  {
    header: 'Description',
    accessor: 'card_acceptor',
    key: 'card_acceptor',
    width: 244,
    renderer: ({ cellValue }) => cellValue?.name,
  },
  {
    header: 'Response memo',
    accessor: 'response',
    key: 'response',
    width: 302,
    renderer: ({ cellValue }) => `[${cellValue?.code}] ${cellValue?.memo}`,
  },
  {
    header: 'Amount',
    accessor: 'amount',
    key: 'amount',
    width: 130,
    renderer: ({ cellValue }) => `${cellValue?.toFixed(2)}`,
  },
];
