// @flow
import { action, computed, decorate, observable, runInAction } from 'mobx';
import { fragments } from '@mqd/graphql-utils';
import { ParentStore } from '@mq/voltron-parent';
import { ACTIVE, reasonCodes, SUSPENDED, TERMINATED, UNACTIVATED, VIRTUAL_PAN } from '../constants';
import AuthControlStore from './AuthControlStore';
import CardholderStore from './CardholderStore';
import ExpirationOffSetStore from './ExpirationOffSetStore';
import FulfillmentStore from './FulfillmentStore';
import VelocityControlStore from './VelocityControlStore';
import { getCardOrCardholderQuery } from '../shared-utils/index';

import * as qualtrics from 'utils/qualtrics';

class AbstractCardStore extends ParentStore {
  constructor(args: Object = {}) {
    // check that instance is not created directly from this class
    // Browser support note: new.target is ok with all browsers, except IE11
    // https://caniuse.com/#feat=mdn-javascript_operators_new_target
    if (new.target === AbstractCardStore) {
      throw new TypeError('Cannot construct Abstract instances directly');
    }

    super(args);
    this.load(args);
  }
  // values
  // payment card info
  token: string = '';
  first_name: string = '';
  middle_name: string = '';
  last_name: string = '';
  created_time: string = '';
  last_modified_time: string = '';
  cardholder_token: string = '';
  card_product_token: string = '';
  last_four: string = '';
  pan: string = '________________';
  expiration: string = '';
  expiration_time: string = '';
  cvv_number: string = '___';
  chip_cvv_number: string = '';
  barcode: string = '';
  pin_is_set: boolean = false;
  start_date: string = '';
  state: string = '';
  state_reason: string = '';
  fulfillment_status: string = '';
  reissue_pan_from_card_token: string = '';
  bulk_issuance_token: string = '';
  translate_pin_from_card_token: string = '';
  instrument_type: string = '';
  expedite: boolean = false;
  loading: boolean = false;
  metadata: string = '';
  // objects
  cardholder: CardholderStore = {};
  activationActions: Object = {};
  fulfillment: FulfillmentStore = {};
  expiration_offset: ExpirationOffSetStore = {};
  velocity_controls: Array<VelocityControlStore> = [];
  auth_controls: Array<AuthControlStore> = [];

  reasonCodes = reasonCodes;

  createTransitionAllowedRoles = [
    'access-manager',
    'cardholder-support',
    'compliance-internal',
    'compliance-processor-only',
    'delivery-internal',
    'fulfillment-internal',
    'marqeta-admin-internal',
    'production-support-internal',
    'program-admin',
    'risk-internal',
    'supplier-payments-manager',
    'aux-credit-support-agent-external',
    'aux-report-card-lost-stolen',
    'aux-card-suspend',
    'aux-card-replace',
    'aux-manual-card-create',
  ];

  /**
   * @param samePan if true, reissue card with the same pan
   * @returns a card token
   * @abstract
   * @Override
   */
  async reissueCard(samePan) {
    throw new TypeError('Method should be overriden by child class');
  }

  async cardActivationCall(queryName) {
    try {
      this.loading = true;

      const payload = {
        reason_code: '01',
        card_token: this.token,
        channel: 'ADMIN',
        state: ACTIVE,
      };

      const result = await this.gqlMutation(
        `mutation ${queryName} (
            $token: ID
            $card_token: ID!
            $state: String!
            $reason_code: String!
            $reason: String
            $channel: String!
          ) {
            ${queryName}(
              token: $token
              card_token: $card_token
              state: $state
              reason_code: $reason_code
              reason: $reason
              channel: $channel
            ){
              ...cardTransitionInfo
            }
          }

          ${fragments.cardTransitionInfo}
        `,
        payload
      );

      if (!result) throw 'Card transition failed!';
      await this.hydrate(this.token);
      const newState = result.data[queryName] && result.data[queryName].state;
      qualtrics.track(qualtrics.EVENTS.CARD_STATUS_CHANGED);
      return `Successfully updated card state to ${newState}`;
    } catch (e) {
      throw e;
    } finally {
      this.loading = false;
    }
  }

  async unlockCard() {
    await this.cardActivationCall('unlockCard');
  }

  async activateCard() {
    await this.cardActivationCall('activateCard');
  }

  // accepts a route function as callback to redirect user
  // routeCallBack should accept a token as parameter
  // if routeCallBack is undefined, this function will return the new card token
  async replaceCardWithSamePan(params, routeCallBack) {
    window.analytics && window.analytics.track('Card Replaced');

    await this.changeStatus({
      status: SUSPENDED,
      reason_code: params.reason_code || '10',
      reason: params.reason,
    });

    const token = await this.reissueCard(true);
    if (!token) return console.error('CardStore: Failed to replace card with same pan');

    if (routeCallBack) return await routeCallBack(token);
    return token;
  }

  async replaceCardWithSamePanUam(params, routeCallBack) {
    window.analytics && window.analytics.track('Card Replaced');

    try {
      this.loading = true;
      const payload = {
        card_token: this.token,
        state: SUSPENDED,
        reason_code: params.reason_code || '10',
        reason: params.reason,
        channel: 'ADMIN',
        cardholder_token: this.cardholder_token,
        card_product_token: this.card_product_token,
        reissue_pan_from_card_token: this.token,
      };

      const result = await this.gqlMutation(
        `mutation replaceCard (
              $card_token: ID!
              $state: String!
              $reason_code: String!
              $reason: String
              $channel: String
              $cardholder_token: ID!
              $card_product_token: ID!
              $reissue_pan_from_card_token: String
              ) {
                replaceCard(
                  card_token: $card_token
                  state: $state
                  reason_code: $reason_code
                  reason: $reason
                  channel: $channel
                  cardholder_token: $cardholder_token
                  card_product_token: $card_product_token
                  reissue_pan_from_card_token: $reissue_pan_from_card_token
                ){
                  token
                }
              }
            `,
        payload
      );

      if (result?.data) {
        qualtrics.track(qualtrics.EVENTS.CARD_STATUS_CHANGED);
      }

      await this.hydrate(this.token);

      const token = this.dig(result, 'data', 'replaceCard', 'token');
      if (!token) return console.error('Failed to replace card with same pan');

      if (routeCallBack) return await routeCallBack(token);
      return token;
    } catch (e) {
      throw e;
    } finally {
      this.loading = false;
    }
  }

  // accepts a route function as callback to redirect user
  // routeCallBack should accept a token as parameter
  // if routeCallBack is undefined, this function will return the new card token
  async reportCardLostStolen(params, routeCallBack) {
    window.analytics && window.analytics.track('Card Reported');
    const token = await this.reissueCard(false);

    if (!token) return console.error('CardStore: Failed to replace card');

    if (routeCallBack) return await routeCallBack(token);
    return token;
  }

  async reportCardLostStolenUam(params, routeCallBack) {
    window.analytics && window.analytics.track('Card Reported');

    try {
      this.loading = true;
      const payload = {
        card_token: this.token,
        state: TERMINATED,
        reason_code: params.reason_code || '10',
        reason: params.reason,
        channel: 'ADMIN',
        cardholder_token: this.cardholder_token,
        card_product_token: this.card_product_token,
      };

      const result = await this.gqlMutation(
        `mutation reportCard (
              $card_token: ID!
              $state: String!
              $reason_code: String!
              $reason: String
              $channel: String
              $cardholder_token: ID!
              $card_product_token: ID!
              ) {
                reportCard(
                  card_token: $card_token
                  state: $state
                  reason_code: $reason_code
                  reason: $reason
                  channel: $channel
                  cardholder_token: $cardholder_token
                  card_product_token: $card_product_token
                ){
                  token
                }
              }
            `,
        payload
      );

      if (result?.data) {
        qualtrics.track(qualtrics.EVENTS.CARD_STATUS_CHANGED);
      }

      await this.hydrate(this.token);

      const token = this.dig(result, 'data', 'reportCard', 'token');
      if (!token) throw 'Failed to report card';

      if (routeCallBack) return await routeCallBack(token);
      return token;
    } catch (e) {
      console.error(e);
      throw e;
    } finally {
      this.loading = false;
    }
  }

  async suspendCard(params) {
    await this.changeStatus({
      status: SUSPENDED,
      reason_code: params.reason_code,
      reason: params.reason,
    });
  }

  async suspendCardUam(params) {
    try {
      this.loading = true;
      const payload = {
        card_token: this.token,
        channel: 'ADMIN',
        state: SUSPENDED,
        reason_code: params.reason_code,
        reason: params.reason,
      };

      const result = await this.gqlMutation(
        `mutation lockCard (
            $token: ID
            $card_token: ID!
            $state: String!
            $reason_code: String!
            $reason: String
            $channel: String!
          ) {
            lockCard(
              token: $token
              card_token: $card_token
              state: $state
              reason_code: $reason_code
              reason: $reason
              channel: $channel
            ){
              ...cardTransitionInfo
            }
          }

          ${fragments.cardTransitionInfo}
        `,
        payload
      );

      if (!result) throw 'Card transition failed!';
      await this.hydrate(this.token);
      const newState = result.data.lockCard && result.data.lockCard.state;
      qualtrics.track(qualtrics.EVENTS.CARD_STATUS_CHANGED);
      return `Successfully updated card state to ${newState}`;
    } catch (e) {
      throw e;
    } finally {
      this.loading = false;
    }
  }

  async terminateCard() {
    window.analytics && window.analytics.track('Card Terminated');
    await this.changeStatus({
      status: TERMINATED,
      reason_code: '01',
    });
  }

  async terminateCardGranularPermissions() {
    window.analytics && window.analytics.track('Card Terminated');

    try {
      this.loading = true;
      const payload = {
        card_token: this.token,
        channel: 'ADMIN',
        reason_code: '01',
        state: TERMINATED,
      };

      const result = await this.gqlMutation(
        `mutation terminateCard (
          $token: ID
          $card_token: ID!
          $state: String!
          $reason_code: String!
          $channel: String!
        ) {
          terminateCard(
            token: $token
            card_token: $card_token
            state: $state
            reason_code: $reason_code
            channel: $channel
          ){
            ...cardTransitionInfo
          }
        }
        ${fragments.cardTransitionInfo}
      `,
        payload
      );
      if (!result) throw 'Failed to terminate card';
      await this.hydrate(this.token);
      qualtrics.track(qualtrics.EVENTS.CARD_STATUS_CHANGED);
      return 'Successfully terminated card';
    } catch (e) {
      throw e;
    } finally {
      this.loading = false;
    }
  }

  async changeStatus(params) {
    try {
      this.loading = true;

      const { status, ...remainingParams } = params;
      const payload = {
        ...remainingParams,
        card_token: this.token,
        channel: 'ADMIN',
        state: params.status,
      };

      const result = await this.gqlMutation(
        `mutation createCardTransition (
            $token: ID
            $card_token: ID!
            $state: String!
            $reason_code: String!
            $reason: String
            $channel: String!
          ) {
            createCardTransition(
              token: $token
              card_token: $card_token
              state: $state
              reason_code: $reason_code
              reason: $reason
              channel: $channel
            ){
              ...cardTransitionInfo
            }
          }

          ${fragments.cardTransitionInfo}
        `,
        payload
      );

      if (!result) throw 'Card transition failed!';
      await this.hydrate(this.token);
      const newState = result.data.createCardTransition && result.data.createCardTransition.state;
      qualtrics.track(qualtrics.EVENTS.CARD_STATUS_CHANGED);
      return `Successfully updated card state to ${newState}`;
    } catch (e) {
      throw e;
    } finally {
      this.loading = false;
    }
  }

  // actions
  async hydrate(token) {
    try {
      this.loading = true;
      const result = await this.gqlQuery(
        `query ${this.cardQuery}($token: ID!) {
            ${this.cardQuery}(token: $token) {
              ${this.fullDataQuery}
            }
          }
          ${this.fullDataFragments}
        `,
        {
          token,
          ...this.hydrateParams,
        }
      );
      runInAction(() => {
        if (result) {
          const cardInfo = this.extract(result, this.cardQuery);
          this.extractAndLoadContructItem(cardInfo);
          this.valuesUpdated = {};
          return true;
        } else {
          return false;
        }
      });
    } catch (e) {
      console.error('CardStore: Unable to hydrate card');
    } finally {
      this.loading = false;
    }
  }

  extractAndLoadContructItem(cardInfo) {
    const cardholder = this.dig(cardInfo, 'cardholder');
    const cardProduct = this.dig(cardInfo, 'card_product');

    this.loadFullInfoResult({
      ...cardInfo,
      first_name: this.dig(cardholder, 'first_name'),
      middle_name: this.dig(cardholder, 'middle_name'),
      last_name: this.dig(cardholder, 'last_name'),
      start_date: this.dig(cardProduct, 'start_date'),
    });
  }

  updateCardAllowedRoles = [
    'compliance-internal',
    'compliance-processor-only',
    'risk-internal',
    'delivery-internal',
    'fulfillment-internal',
    'production-support-internal',
    'cardholder-support',
    'supplier-payments-manager',
    'program-admin',
    'access-manager',
    'marqeta-admin-internal',
  ];

  async updateCard() {
    const paramsInfo = {
      token: { type: 'ID!' },
      cardholder_token: { type: 'String' },
      expedite: { type: 'Boolean' },
      fulfillment: { type: 'CardFulfillmentInput' },
      metadata: { type: 'String' },
    };
    const result = await this.gqlMutation(
      `
      mutation updateCard(${this.configureOuterQueryParams(paramsInfo)}) {
        updateCard(${this.configureInnerQueryParams(paramsInfo)}) {
          ${this.fullDataQuery}
        }
      }
      ${this.fullDataFragments}
      `,
      this.updateParams
    );
    return runInAction(() => {
      if (result) {
        const cardInfo = this.extract(result, 'updateCard');
        this.extractAndLoadContructItem(cardInfo);
        this.valuesUpdated = {};
        return true;
      } else {
        return false;
      }
    });
  }

  async addMetadata({ key, value }) {
    const paramsInfo = {
      token: { type: 'ID!' },
      metadata: { type: 'String' },
    };
    const result = await this.gqlMutation(
      `
      mutation updateCard(${this.configureOuterQueryParams(paramsInfo)}) {
        updateCard(${this.configureInnerQueryParams(paramsInfo)}) {
          metadata
        }
      }
      `,
      {
        token: this.token,
        metadata: JSON.stringify({ [key]: value }),
      }
    );
    return runInAction(() => {
      if (result) {
        const cardInfo = this.extract(result, 'updateCard');
        const { metadata } = cardInfo;
        if (metadata) {
          this.metadata = metadata;
        }
        this.valuesUpdated = {};
        return true;
      } else {
        return false;
      }
    });
  }

  createOrUpdatePinAllowedRoles = [
    'cardholder-support',
    'marqeta-admin-internal',
    'production-support-internal',
  ];

  async createOrUpdatePin({ pin }) {
    try {
      const result = await this.gqlMutation(
        `
        mutation createOrUpdatePin($card_token: ID!, $pin: String!) {
          createOrUpdatePin(card_token: $card_token, pin: $pin)
        }
        `,
        {
          card_token: this.token,
          pin: pin,
        }
      );
      if (!result || this.error) throw this.error;
      await this.hydrate(this.token);
    } catch (error) {
      throw error;
    }
  }

  async showPan() {
    try {
      this.loading = true;
      const result = await this.gqlQuery(
        `query showPan(
            $token: ID!
            $show_cvv_number: Boolean
          ) {
            showpan(
              token: $token
              show_cvv_number: $show_cvv_number
            ){
              pan
              cvv_number
            }
          }        
        `,
        {
          token: this.token,
          show_cvv_number: true,
        }
      );

      const pan = this.dig(result, 'data', 'showpan', 'pan');
      const cvv_number = this.dig(result, 'data', 'showpan', 'cvv_number');
      this.load({
        pan,
        cvv_number,
      });
    } catch (e) {
      console.error('CardStore: Unable to fetch card pan');
    } finally {
      this.loading = false;
    }
  }

  loadFullInfoResult(cardInfo) {
    this.load(cardInfo);
    const {
      final_fulfillment = {},
      cardholder = {},
      velocity_controls = [],
      auth_controls = [],
    } = cardInfo;

    const cardholderObject = {
      token: this.dig(cardholder, 'token'),
      first_name: this.dig(cardholder, 'first_name'),
      last_name: this.dig(cardholder, 'last_name'),
      funding_sources: this.dig(cardholder, 'funding_sources', 'data'),
      gpa_balance: this.dig(cardholder, 'gpa_balance'),
    };

    this.loadAndConstructList('auth_controls', auth_controls, AuthControlStore);
    this.loadAndConstructList('velocity_controls', velocity_controls, VelocityControlStore);
    this.loadAndConstructItem('cardholder', cardholderObject, CardholderStore);
    this.loadAndConstructItem('fulfillment', final_fulfillment, FulfillmentStore);
  }

  updateMetadata(key, value) {
    const metadataCopy = Object.assign({}, this.metadataObject);
    metadataCopy[key] = value ? value : null;
    this.updateForSave('metadata', JSON.stringify(metadataCopy));
  }

  filterList(list) {
    if (list && list.filter) {
      return list.filter((item) => item);
    }
  }

  mapMccNames(controls) {
    let mccs = [];
    controls.forEach(({ merchant_scope }) => {
      const { mcc_name, mcc_group_data } = merchant_scope;
      const { mcc_names } = mcc_group_data || {};
      if (mcc_name) mccs.push(mcc_name);
      if (mcc_names) mccs = [...mccs, ...mcc_names];
    });

    return this.uniqueArray(mccs);
  }

  // computed
  get metadataObject(): Object {
    if (this.metadata) {
      try {
        return JSON.parse(this.metadata);
      } catch (error) {
        return {};
      }
    } else {
      return {};
    }
  }

  // computed
  watchedObjects = ['fulfillment'];
  get updateParams(): Object {
    const params = {
      token: this.token,
      ...this.watchedUpdateParams,
    };
    this.valuesUpdatedArray.forEach((value) => (params[value] = this[value]));
    return params;
  }

  // helpers
  get fullDataQuery(): string {
    const fulfillmentQuery = `
      shipping {
        method
        return_address {
          ...addressBaseInfo
        }
        recipient_address {
          ...addressBaseInfo
        }
        care_of_line
      }
      card_fulfillment_reason
      card_personalization {
        text {
          name_line_1 {
            value
          }
          name_line_2 {
            value
          }
        }
        images {
          card {
            name
            thermal_color
          }
          carrier {
            name
            message_1
          }
          signature {
            name
          }
          carrier_return_window {
            name
          }
        }
        carrier {
          template_id
          logo_file
          logo_thumbnail_file
          message_file
          message_line
        }
        perso_type
      }
    `;

    return `
      ...cardBaseInfo
      card_product {
        start_date
      }      
      cardholder {
        token
        first_name
        middle_name
        last_name
        funding_sources {
          data {
            ...fundingSourceBaseInfo
          }
        }
        gpa_balance {
          gpa {
            ...balanceBaseInfo
          }
        }
      }
      auth_controls {
        ...authControlBaseInfo
      }
      velocity_controls {
        ...velocityControlBaseInfo
      }
      activation_actions {
        terminate_reissued_source_card
        swap_digital_wallet_tokens_from_card_token
      }
      final_fulfillment {
        ${fulfillmentQuery}
      }

    `;
  }

  get fullDataFragments() {
    return `
      ${fragments.authControlBaseInfo}
      ${fragments.addressBaseInfo}
      ${fragments.balanceBaseInfo}
      ${fragments.cardBaseInfo}
      ${fragments.fundingSourceBaseInfo}
      ${fragments.velocityControlBaseInfo}
    `;
  }

  get formattedExpiration() {
    const expiration = this.expiration;
    return expiration && `${expiration.substring(0, 2)}/${expiration.substring(2, 4)}`;
  }

  get isLocked() {
    return this.state === SUSPENDED;
  }

  get isUnactivated() {
    return this.state === UNACTIVATED;
  }

  get isTerminated() {
    return this.state === TERMINATED;
  }

  get availableStatuses() {
    const possibleTransitions = {
      ACTIVE: ['Suspended', 'Terminated'],
      SUSPENDED: ['Active', 'Terminated'],
      UNACTIVATED: ['Active', 'Terminated'],
    };

    return possibleTransitions[this.state] || [];
  }

  get cardholderFullName() {
    return `${this.first_name} ${this.last_name}`;
  }

  get instrumentType() {
    return this.instrument_type === VIRTUAL_PAN ? 'Virtual' : 'Physical';
  }

  get isPhysical() {
    return this.instrument_type !== VIRTUAL_PAN;
  }

  get hasSingleUseVelocityControl() {
    return this.velocity_controls.some((velocity) => velocity.isSingleUse);
  }

  get authFormattedMccs() {
    const authControlMccNames = this.mapMccNames(this.auth_controls);
    return this.uniqueArray(authControlMccNames);
  }

  get velocityControlUsageLimits() {
    const limits = this.velocity_controls.map((velocity) => velocity.formattedUsageLimit);
    return this.filterList(limits);
  }

  get velocityControlUsageLimitsWithTypes() {
    const limits = this.velocity_controls.map(
      ({ formattedUsageLimit, controlTypeStringsArray } = {}) => {
        const includes = `Includes: ${controlTypeStringsArray.join(', ')}`;
        return {
          formattedUsageLimit: formattedUsageLimit,
          includes,
        };
      }
    );

    return this.filterList(limits);
  }

  get formattedAmountLimits() {
    const minLimits = this.velocity_controls.reduce((accControls, controlItem) => {
      const velWindow = controlItem.velocity_window;
      if (
        (accControls[velWindow] &&
          accControls[velWindow].amount_limit < controlItem.amount_limit) ||
        !velWindow
      ) {
        return accControls;
      }

      return {
        ...accControls,
        [velWindow]: {
          amount_limit: controlItem.amount_limit,
          formattedVelocityControl: controlItem.formattedVelocityControl,
        },
      };
    }, {});
    const amountLimits = ['DAY', 'WEEK', 'MONTH', 'TRANSACTION'].map(
      (velWindow) => minLimits[velWindow] && minLimits[velWindow].formattedVelocityControl
    );
    return this.filterList(this.uniqueArray(amountLimits));
  }

  get cardQuery() {
    return getCardOrCardholderQuery(
      'card',
      'cardByCardProduct',
      localStorage.getItem('userOrgName'),
      JSON.parse(localStorage.getItem('redseaRoles'))
    );
  }
}

decorate(AbstractCardStore, {
  // values
  token: observable,
  first_name: observable,
  middle_name: observable,
  last_name: observable,
  created_time: observable,
  last_modified_time: observable,
  cardholder_token: observable,
  card_product_token: observable,
  last_four: observable,
  pan: observable,
  expiration: observable,
  expiration_time: observable,
  cvv_number: observable,
  chip_cvv_number: observable,
  barcode: observable,
  pin_is_set: observable,
  start_date: observable,
  state: observable,
  state_reason: observable,
  fulfillment_status: observable,
  expiration_offset: observable,
  reissue_pan_from_card_token: observable,
  bulk_issuance_token: observable,
  translate_pin_from_card_token: observable,
  instrument_type: observable,
  expedite: observable,
  metadata: observable,
  // objects
  cardholder: observable,
  fulfillment: observable,
  activationActions: observable,
  loading: observable,
  velocity_controls: observable,
  auth_controls: observable,

  // actions
  hydrate: action.bound,
  changeStatus: action.bound,
  updateCard: action.bound,
  loadFullInfoResult: action.bound,
  addMetadata: action.bound,
  updateMetadata: action.bound,
  reportCardLostStolen: action.bound,
  reportCardLostStolenUam: action.bound,
  suspendCard: action.bound,
  suspendCardUam: action.bound,
  activateCard: action.bound,
  unlockCard: action.bound,
  cardActivationCall: action.bound,
  reissueCard: action.bound,
  extractAndLoadContructItem: action.bound,
  replaceCardWithSamePan: action.bound,
  replaceCardWithSamePanUam: action.bound,
  terminateCard: action.bound,
  terminateCardGranularPermissions: action.bound,
  mapMccNames: action.bound,
  filterList: action.bound,
  showPan: action.bound,
  createOrUpdatePin: action.bound,

  // computed
  velocityControlUsageLimitsWithTypes: computed,
  authFormattedMccs: computed,
  metadataObject: computed,
  updateParams: computed,
  formattedExpiration: computed,
  isLocked: computed,
  isUnactivated: computed,
  isTerminated: computed,
  cardholderFullName: computed,
  instrumentType: computed,
  isPhysical: computed,
  hasSingleUseVelocityControl: computed,
  velocityControlUsageLimits: computed,
  formattedAmountLimits: computed,
  cardQuery: computed,
});

export default AbstractCardStore;
