// @flow
import { decorate, observable, action, runInAction, computed } from 'mobx';
import { omit } from 'ramda';
import { fragments } from '@mqd/graphql-utils';
import TransactionStore from '../stores/TransactionStore';
import { ParentTableStore } from '@mq/voltron-parent';
import {
  disputeEnabledRoles,
  returnEnabledRoles,
  cancelBankTransferRoles,
  defaultFilters,
  transactionFiltersLocalStorageKey,
} from './constants';
import { UAM_GRANULAR_PERMISSIONS } from '@mqd/mqd-constants';
const { DISPUTES_VIEW_AND_EDIT } = UAM_GRANULAR_PERMISSIONS;

export default class TransactionTableStore extends ParentTableStore {
  constructor(args: Object = {}) {
    super(args);
    this.load(args);
    this.loadFiltersFromSessionStorage();
    this.setActiveFilterCount();
  }
  // values
  items: Array<TransactionStore> = [];
  count: number = 50;
  start_date: string = '';
  end_date: string = '';
  state: Array<string> = ['PENDING', 'CLEARED', 'COMPLETION', 'DECLINED', 'ERROR'];
  type: Array<string> = [];
  cardholderOrBusinessToken: string = '';
  activeFilterCount: number = 0;

  setActiveFilterCount() {
    const count = Object.keys(defaultFilters).reduce((acc, key) => {
      const defaultFilter = defaultFilters[key];
      const filter = this[key];

      if (!Array.isArray(defaultFilter)) {
        return defaultFilter === filter ? acc : acc + 1;
      }

      if (defaultFilter.length !== filter.length) {
        return acc + 1;
      }

      for (const item of defaultFilter) {
        if (!filter.includes(item)) {
          return acc + 1;
        }
      }

      return acc;
    }, 0);

    this.activeFilterCount = count;
  }

  loadFiltersFromSessionStorage() {
    const values = window.sessionStorage.getItem(transactionFiltersLocalStorageKey);
    const persistedValues = JSON.parse(values);

    if (persistedValues && persistedValues.token === this.cardholderOrBusinessToken) {
      Object.keys(omit(['token'], persistedValues)).forEach((key) => {
        this[key] = persistedValues[key];
      });
    }
  }

  saveFiltersToSessionStorage() {
    const values = {
      token: this.cardholderOrBusinessToken,
      start_date: this.start_date,
      end_date: this.end_date,
      state: this.state,
      type: this.type,
    };

    window.sessionStorage.setItem(transactionFiltersLocalStorageKey, JSON.stringify(values));
  }

  hydrateFilterQueryParams() {
    const filterParams = {
      start_date: {
        type: 'String',
        val: this.start_date,
      },
      end_date: {
        type: 'String',
        val: this.end_date,
      },
    };
    const isSquareMainShard = this.userStore.selectedActiveProgram.short_name === 'sqr';

    if (!isSquareMainShard) {
      filterParams.type = {
        type: 'String',
        val: this.type && this.type.join(','),
      };
      filterParams.state = {
        type: 'String',
        val: this.state && this.state.join(','),
      };
    }

    this.queryParams = {
      ...this.queryParams,
      ...filterParams,
    };
  }

  handleSearch() {
    const { hydrate, setAttr } = this;
    setAttr && setAttr('start_index', 0);
    setAttr('count', 50);
    hydrate && hydrate();
    this.setActiveFilterCount();
    this.saveFiltersToSessionStorage();
  }

  // actions
  async hydrate() {
    this.hydrateFilterQueryParams();
    const isSquareMainShard = this.userStore.selectedActiveProgram.short_name === 'sqr';

    if (!this.enableNoParamQuery && !this.haveRequiredParams) return;
    this.loading = true;
    const result = await this.gqlQuery(
      `query transactions${this.outerQueryParams} {
          transactions${this.innerQueryParams} {
            data {
              ...transactionBaseInfo
              direct_deposit {
                token
                state
              }
              bank_transfer_token
              bankTransfer {
                status
                created_time
                achFundingSource {
                  account_suffix
                }
                transitions {
                  token
                  status
                  created_time
                }
              }
            }
            ${this.paginationAttributes}
          }
        }
        ${fragments.transactionBaseInfo}
      `,
      isSquareMainShard
        ? this.parseDelimitedParams([], ',') // PS-22741 - main square shard needs to have state and type excluded from filter parameters
        : this.parseDelimitedParams(['type', 'state'], ','),
      undefined,
      undefined,
      'all'
    );
    runInAction(() => {
      const transactions = this.extract(result, 'transactions');
      this.processPaginationResponse(transactions, TransactionStore);
      this.loading = false;
    });
  }

  // computed
  get canDisputeTransaction() {
    if (!this.userStore) return false;
    const roles = this.userStore.uamGranularPermissionsActive
      ? [DISPUTES_VIEW_AND_EDIT]
      : disputeEnabledRoles;
    return Boolean(this.userStore.hasRoleInArrayForActiveProgram(roles));
  }

  get canReturnTransaction() {
    if (!this.userStore) return false;
    return Boolean(this.userStore.hasRoleInArrayForActiveProgram(returnEnabledRoles));
  }

  get canCancelBankTransfer() {
    if (!this.userStore) return false;
    return Boolean(this.userStore.hasRoleInArrayForActiveProgram(cancelBankTransferRoles));
  }
}

decorate(TransactionTableStore, {
  // values
  items: observable,
  count: observable,
  sort_by: observable,
  cardholder_token: observable,
  start_date: observable,
  end_date: observable,
  state: observable,
  type: observable,

  // actions
  hydrate: action.bound,
  handleSearch: action.bound,
  hydrateFilterQueryParams: action.bound,

  // computed
  canDisputeTransaction: computed,
  canReturnTransaction: computed,
  canCancelBankTransfer: computed,
});
