export const transactionTypes = [
  'account.credit',
  'account.debit',
  'accountfunding.pull.chargeback.rev',
  'accountfunding.pull.chargeback',
  'accountfunding.pull',
  'ach.cancel',
  'ach.early.funds.reversed',
  'ach.early.funds',
  'ach.jit.cancel',
  'ach.jit.pull.pending',
  'ach.jit.pull.returned',
  'ach.jit.pull',
  'ach.jit.push.pending',
  'ach.jit.push.returned',
  'ach.jit.push',
  'ach.provisional.credit.reversed',
  'ach.provisional.credit',
  'ach.pull.pending',
  'ach.pull.returned',
  'ach.pull',
  'ach.push.pending',
  'ach.push.returned',
  'ach.push.reversal.completed',
  'ach.push.reversal.declined',
  'ach.push',
  'authorization.advice',
  'authorization.atm.withdrawal',
  'authorization.cashback',
  'authorization.clearing.atm.withdrawal',
  'authorization.clearing.cashback',
  'authorization.clearing.chageback.provisional.debit',
  'authorization.clearing.chargeback.completed',
  'authorization.clearing.chargeback.provisional.credit',
  'authorization.clearing.chargeback.provisional.debit',
  'authorization.clearing.chargeback.reversal',
  'authorization.clearing.chargeback.writeoff',
  'authorization.clearing.chargeback',
  'authorization.clearing.quasi.cash',
  'authorization.clearing.refund',
  'authorization.clearing.representment',
  'authorization.clearing',
  'authorization.incremental',
  'authorization.quasi.cash',
  'authorization.reversal.issuerexpiration',
  'authorization.reversal',
  'authorization.standin',
  'authorization',
  'balanceinquiry',
  'check.return.debit',
  'directdeposit.credit.pending.reversal',
  'directdeposit.credit.pending',
  'directdeposit.credit.reject',
  'directdeposit.credit.reversal',
  'directdeposit.credit',
  'directdeposit.debit.pending.reversal',
  'directdeposit.debit.pending',
  'directdeposit.debit.reject',
  'directdeposit.debit.reversal',
  'directdeposit.debit',
  'dispute.credit',
  'dispute.debit',
  'fee.charge.pending.refund',
  'fee.charge.pending',
  'fee.charge.reversal',
  'fee.charge',
  'funds.expire',
  'gpa.credit.authorization.billpayment.reversal',
  'gpa.credit.authorization.billpayment',
  'gpa.credit.authorization.reversal',
  'gpa.credit.authorization',
  'gpa.credit.billpayment',
  'gpa.credit.chargeback.reversal',
  'gpa.credit.chargeback',
  'gpa.credit.issueroperator',
  'gpa.credit.networkload.reversal',
  'gpa.credit.networkload',
  'gpa.credit.pending.reversal',
  'gpa.credit.pending',
  'gpa.credit.reversal',
  'gpa.credit',
  'gpa.debit.issueroperator',
  'gpa.debit.networkload',
  'gpa.debit.pending.reversal',
  'gpa.debit.pending',
  'gpa.debit.reversal',
  'gpa.debit',
  'gpa.grant',
  'msa.credit.chargeback.reversal',
  'msa.credit.chargeback',
  'msa.credit.pending.reversal',
  'msa.credit.pending',
  'msa.credit.reversal',
  'msa.credit',
  'msa.debit.pending.reversal',
  'msa.debit.pending',
  'msa.debit',
  'original.credit.auth_plus_capture.reversal',
  'original.credit.auth_plus_capture',
  'original.credit.authorization.clearing',
  'original.credit.authorization.reversal',
  'original.credit.authorization',
  'pindebit.atm.withdrawal',
  'pindebit.authorization.clearing',
  'pindebit.authorization.reversal.issuerexpiration',
  'pindebit.authorization.reversal',
  'pindebit.authorization',
  'pindebit.balanceinquiry',
  'pindebit.cashback',
  'pindebit.chargeback.completed',
  'pindebit.chargeback.provisional.credit',
  'pindebit.chargeback.provisional.debit',
  'pindebit.chargeback.reversal',
  'pindebit.chargeback.writeoff',
  'pindebit.chargeback',
  'pindebit.credit.adjustment',
  'pindebit.quasicash',
  'pindebit.refund.reversal',
  'pindebit.refund',
  'pindebit.representment',
  'pindebit.reversal',
  'pindebit.transfer',
  'pindebit',
  'programreserve.credit',
  'programreserve.debit',
  'pullfromcard.pull.chargeback.rev',
  'pullfromcard.pull.chargeback',
  'pullfromcard.pull.reversal',
  'pullfromcard.pull',
  'pushtocard.debit',
  'pushtocard.reversal',
  'refund.authorization.advice',
  'refund.authorization.clearing',
  'refund.authorization.reversal',
  'refund.authorization',
  'refund',
  'reward.earn',
  'token.activation-request',
  'token.advice',
  'transaction.unknown',
  'transfer.fee',
  'transfer.peer',
  'transfer.program',
  'unknown',
];

export const filterOptions = [
  {
    label: 'State',
    placeholder: 'Default: PENDING, COMPLETION',
    filterParam: 'state',
    type: 'multiselect',
    defaultClearOptions: ['PENDING', 'CLEARED', 'COMPLETION', 'DECLINED', 'ERROR'],
    options: ['PENDING', 'CLEARED', 'COMPLETION', 'DECLINED', 'ERROR'],
  },
  {
    label: 'Type',
    placeholder: 'Default: All types',
    filterParam: 'type',
    type: 'multiselect',
    options: transactionTypes,
  },
];

export const defaultFilters = {
  type: [],
  state: ['PENDING', 'CLEARED', 'COMPLETION', 'DECLINED', 'ERROR'],
};

export const transactionFiltersLocalStorageKey = 'transactionFilters';

export const disputeEnabledRoles = [
  'cardholder-support',
  'supplier-payments-manager',
  'program-admin',
  'marqeta-admin-internal',
  'compliance-internal',
  'compliance-program-managed',
  'compliance-processor-only',
  'risk-internal',
  'aux-risk-external',
];

export const disputeReasonOptions = [
  'Fraud',
  'Authorization',
  'Processing Error',
  'Consumer Dispute',
  'Other',
];

export const cardCompromisedOptions = [
  "Card still in Account holder's possession",
  'Card has been lost or stolen',
];

export const returnEnabledRoles = [
  'cardholder-support',
  'compliance-internal',
  'compliance-processor-only',
  'compliance-program-managed',
  'marqeta-admin-internal',
  'production-support-internal',
  'program-admin',
  'risk-internal',
];

export const cancelBankTransferRoles = [
  'cardholder-support',
  'compliance-internal',
  'compliance-processor-only',
  'compliance-program-managed',
  'marqeta-admin-internal',
  'production-support-internal',
  'program-admin',
  'risk-internal',
  'manage-acho-transfers-view-and-edit', // This granular permission is needed to enable the three dot actions items that allows a user to cancel a transaction from the transaction table
];
