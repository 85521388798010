import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { InputSelectGroup } from '@mqd/volt-base';
import { MaskedInput } from '@mqd/volt-currency';
import { ProgramReserveContext } from '../../../../../../context/ProgramReserveContext.js';
import CurrencySelect from 'components/currency-select/CurrencySelect';

import s from './CurrencyInputGroup.module.css';

function CurrencyInputGroup({ transaction, type }) {
  const { updateTransaction } = useContext(ProgramReserveContext);
  const displayValue = type === 'amount' ? transaction.amount : transaction.collateralAmount;
  const inputContainerTestId =
    type === 'amount' ? 'amount-input-container' : 'collateral-input-container';
  const maskedInputTestId = type === 'amount' ? 'amount-input' : 'collateral-input';

  return (
    <InputSelectGroup selectWidth={100} className={s.amountContainer} testId={inputContainerTestId}>
      <MaskedInput
        placeholder="Enter amount"
        required
        value={displayValue}
        onValueChange={({ floatValue }) => {
          const updateValue =
            type === 'amount' ? { amount: floatValue } : { collateralAmount: floatValue };
          updateTransaction(transaction.id, updateValue);
        }}
        error={transaction.hasAmountErrors}
        isAllowed={() => true}
        testId={maskedInputTestId}
      />
      <CurrencySelect
        placeholder="Select currency"
        disabled={transaction.currencySelectionDisabled}
        value={transaction.currency}
        error={transaction.hasAmountErrors}
        onChange={({ code }) => updateTransaction(transaction.id, { currency: code })}
        withFlags
        primaryCodes={['USD', 'CAD']}
      />
    </InputSelectGroup>
  );
}

CurrencyInputGroup.propTypes = {
  transaction: PropTypes.object.isRequired,
};

export default observer(CurrencyInputGroup);
