import React, { useState } from 'react';
import { Tabs } from '@mqd/volt-base';
import OverviewTab from './components/OverviewTab/OverviewTab.js';
import ProgramSpecificTab from './components/ProgramSpecificTab/ProgramSpecificTab.js';

export function TransactionHistory() {
  const tabs = {
    overview: 'Overview',
    programSpecific: 'Program specific',
  };

  const [activeTab, setActiveTab] = useState(tabs.overview);

  const render = () => {
    switch (activeTab) {
      default:
      case tabs.overview:
        return <OverviewTab />;

      case tabs.programSpecific:
        return <ProgramSpecificTab />;
    }
  };

  return (
    <Tabs
      activeTab={activeTab}
      tabs={Object.values(tabs)}
      onTabChange={setActiveTab}
      testId="program-reserve-tabs"
    >
      {render()}
    </Tabs>
  );
}

export default TransactionHistory;
