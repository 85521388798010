import React from 'react';
import { Flex, FormLabel, HSpacer } from '@mqd/volt-base';

import rowStyles from '../../TransactionRow.module.css';
import s from './TransactionsFormHeaders.module.css';

// I had to create this component to render labels separately
// We can not use `label` property on inputs
// because it results in a bigger interactive area
// since both input and label are in the same container
const TransactionsFormHeaders = ({ commercialCreditActive }) => (
  <Flex flexDirection="row" className={s.headersContainer}>
    <div className={rowStyles.programContainer}>
      <FormLabel size="small">Program</FormLabel>
    </div>

    <HSpacer factor={2} />

    <div className={rowStyles.amountContainer}>
      <FormLabel size="small">Deposit/repayment amount</FormLabel>
    </div>

    {commercialCreditActive && (
      <>
        <HSpacer factor={2} />
        <div className={rowStyles.collateralContainer}>
          <FormLabel size="small" optional>
            Collateral amount
          </FormLabel>
        </div>
      </>
    )}

    <HSpacer factor={8.5} />

    <div className={rowStyles.memoContainer}>
      <FormLabel size="small" optional>
        Memo
      </FormLabel>
    </div>

    <HSpacer factor={2} />

    <div className={rowStyles.alertContainer}>
      <FormLabel size="small">Alert</FormLabel>
    </div>
  </Flex>
);

export default TransactionsFormHeaders;
