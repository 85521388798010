// @flow
import { decorate, observable, action, computed, runInAction } from 'mobx';
import { ParentStore } from '@mq/voltron-parent';
import { fragments } from '@mqd/graphql-utils';
import { TRANSACTION_TYPES } from '@mqd/mqd-constants';
import CardAcceptorStore from './CardAcceptorStore';
import ResponseStore from './ResponseStore';
import GpaBalanceStore from './GpaBalanceStore';
import GpaOrderStore from './GpaOrderStore';
import ChargebackStore from './GpaOrderStore';
import CardStore from './CardStore';
import CardholderStore from './CardholderStore';
import DirectDepositStore from './DirectDepositStore';
import DigitalWalletTokenStore from './DigitalWalletTokenStore';
import { isNotPresent } from './../shared-utils';
import * as qualtrics from 'utils/qualtrics';
import { ignoreImpactedAmount } from '../../../../views/program/TransactionDetails/v2/utils';

export const disputableTransactionTypes = TRANSACTION_TYPES.DISPUTABLE_TRANSACTION_TYPES;

export const returnableTransactionTypes = [
  'directdeposit.credit',
  'directdeposit.credit.pending',
  'directdeposit.debit',
  'directdeposit.debit.pending',
];
export const returnableDirectDepositStates = ['APPLIED', 'PENDING'];
export const cancelableBankTransferStates = ['PENDING'];

class TransactionStore extends ParentStore {
  constructor(args: Object = {}) {
    super(args);
    this.loadAndConstruct(args);
  }
  // values
  token: string = '';
  type: string = '';
  state: string = '';
  acting_cardholder_token: string = '';
  card_token: string = '';
  cardholder_token: string = '';
  duration: string = '';
  created_time: string = '';
  cardholder_transaction_time: string = '';
  settlement_date: string = '';
  request_amount: string = '';
  amount: string = '';
  issuer_interchange_amount: string = '';
  currency_code: string = '';
  approval_code: string = '';
  preceding_related_transaction_token: string = '';
  incremental_authorization_transaction_tokens: string = '';
  program_transfer: string = '';
  fee_transfer: string = '';
  peer_transfer: string = '';
  offer_orders: string = '';
  rewards: string = '';
  polarity: string = '';
  real_time_fee_group: string = '';
  network: string = '';
  subnetwork: string = '';
  standin_approved_by: string = '';
  acquirer_fee_amount: string = '';
  pos: Object = {};
  avs: string = '';
  transaction_metadata: string = '';
  electronic_commerce_indicator: string = '';
  verification_result: string = '';
  verification_value_created_by: string = '';
  card_acceptor: CardAcceptorStore = {};
  response: ResponseStore = {};
  gpa_order: GpaOrderStore = {};
  gpa: GpaBalanceStore = {};
  chargeback: ChargebackStore = {};
  card: CardStore = null;
  saving: boolean = false;
  cardholder: CardholderStore = null;
  loadingCard: boolean = false;
  loadingCardholder: boolean = false;
  direct_deposit: DirectDepositStore = {};
  digital_wallet_token: DigitalWalletTokenStore = {};
  skipAutoLoad: boolean = true;

  // actions
  async hydrate(token) {
    const result = await this.gqlQuery(
      `query transaction($token: ID!) {
        transaction(token: $token) {
          ...transactionBaseInfo
          direct_deposit {
            token
            state
          }        
        }
      }
      ${fragments.transactionBaseInfo}`,
      {
        token,
        ...this.hydreateParams,
      }
    );

    runInAction(() => {
      if (result) {
        const transactionData = this.extract(result, 'transaction');
        this.loadAndConstruct(transactionData);
        return true;
      } else {
        return false;
      }
    });
  }

  loadAndConstruct(args) {
    this.load(args);
    const loadIfKeysPresent = [
      {
        key: 'card_acceptor',
        store: CardAcceptorStore,
      },
      {
        key: 'response',
        store: ResponseStore,
      },
      {
        key: 'gpa_order',
        store: GpaOrderStore,
      },
      {
        key: 'gpa',
        store: GpaBalanceStore,
      },
      {
        key: 'chargeback',
        store: ChargebackStore,
      },
      {
        key: 'digital_wallet_token',
        store: DigitalWalletTokenStore,
      },
    ];

    loadIfKeysPresent.forEach(({ key, store }) => {
      const value = args[key];
      if (value) this.loadAndConstructItem(key, value, store);
    });
  }

  async fetchCardInformation() {
    this.loadingCard = true;
    runInAction(async () => {
      try {
        this.card = new CardStore({ gqlClient: this.gqlClient });
        await this.card.hydrate(this.card_token);
      } catch (e) {
        console.error(e);
      } finally {
        this.loadingCard = false;
      }
    });
  }

  async fetchCardholderInformation() {
    this.loadingCardholder = true;
    runInAction(async () => {
      try {
        this.cardholder = new CardholderStore({ gqlClient: this.gqlClient });
        await this.cardholder.hydrate(this.cardholder_token);
      } catch (e) {
        console.error(e);
      } finally {
        this.loadingCardholder = false;
      }
    });
  }

  async disputeTransaction(params) {
    this.saving = true;

    const result = await this.gqlMutation(
      `mutation createZendeskTicket(
        $submitter: ZendeskSubmitterInput!
        $subject: String!
        $comment: ZendeskCommentInput!
        $priority: String
        $attachments: [FileInput]
      ) {
        createZendeskTicket(
          submitter: $submitter
          subject: $subject
          comment: $comment
          priority: $priority
          attachments: $attachments
        ) {
          ticket {
            id
            subject
            url
          }
        }
      }`,
      params
    );

    return runInAction(async () => {
      try {
        if (!result) {
          this.saving = false;
          throw this.error || 'Dispute was not submitted.';
        }
        return `Your dispute has been submitted. The Marqeta Risk team will get back to you via email with the resolution.`;
      } catch (e) {
        throw e;
      } finally {
        this.saving = false;
      }
    });
  }

  async returnTransaction(params) {
    this.loading = true;

    const payload = {
      channel: 'API',
      direct_deposit_token: this.direct_deposit?.token,
      state: 'REVERSED',
      ...params,
    };

    const result = await this.gqlMutation(
      `mutation createDirectDepositTransition(
        $channel: String!
        $direct_deposit_token: ID!
        $reason_code: String!
        $reason: String!
        $state: String!
      ) {
        createDirectDepositTransition(
          channel: $channel
          direct_deposit_token: $direct_deposit_token
          reason_code: $reason_code
          reason: $reason
          state: $state
        ) {
          direct_deposit_token
        }
      }
    `,
      payload
    );

    return runInAction(async () => {
      try {
        if (!result) {
          throw this.error || 'This form could not be submitted because of an API error.';
        }
        await this.hydrate(this.token);
        return 'Direct deposit returned';
      } catch (e) {
        throw this.error || e;
      } finally {
        this.loading = false;
      }
    });
  }

  async cancelBankTransfer(params) {
    this.loading = true;

    const payload = {
      channel: 'ADMIN',
      bank_transfer_token: this.bank_transfer_token,
      status: 'CANCELLED',
      ...params,
    };

    const result = await this.gqlMutation(
      `mutation createAchBankTransferTransition(
        $bank_transfer_token: ID!
        $channel: String!
        $status: String!
        $reason: String
      ) {
        createAchBankTransferTransition(
          bank_transfer_token: $bank_transfer_token
          channel: $channel
          status: $status
          reason: $reason
        ) {
          reason
          status
        }
      }
    `,
      payload
    );

    return runInAction(async () => {
      try {
        if (!result) {
          throw this.error || 'This form could not be submitted because of an API error.';
        }
        await this.hydrate(this.token);
        qualtrics.track(qualtrics.EVENTS.ACHO_CANCELLED);
        return 'Bank transfer cancelled';
      } catch (e) {
        throw this.error || e;
      } finally {
        this.loading = false;
      }
    });
  }

  // computed
  get cardAcceptor() {
    return this.card_acceptor && this.card_acceptor.name;
  }

  get channel() {
    return this.dig(this.card_acceptor, 'poi', 'channel');
  }

  get cardholder_presence() {
    return this.dig(this.card_acceptor, 'poi', 'cardholder_presence');
  }

  get card_presence() {
    return this.dig(this.card_acceptor, 'poi', 'card_presence');
  }

  get processing_type() {
    return this.dig(this.card_acceptor, 'poi', 'processing_type');
  }

  get isDisputable() {
    return Boolean(this.state === 'COMPLETION' && disputableTransactionTypes.includes(this.type));
  }

  get isReturnable() {
    return Boolean(
      this.direct_deposit &&
        returnableDirectDepositStates.includes(this.direct_deposit?.state) &&
        (this.state === 'COMPLETION' || this.state === 'PENDING') &&
        returnableTransactionTypes.includes(this.type)
    );
  }

  get isBankTransferCancellable() {
    const status = this.dig(this.bankTransfer, 'status');
    return Boolean(
      this.type.includes('ach.') && status && cancelableBankTransferStates.includes(status)
    );
  }

  get responseCode() {
    return this.dig(this.response, 'code');
  }

  get responseMemo() {
    return this.dig(this.response, 'memo');
  }

  get responseAdditionalInformation() {
    return this.dig(this.response, 'additional_information');
  }

  get token_requestor_name() {
    return this.dig(this.digital_wallet_token, 'token_service_provider', 'token_requestor_name');
  }

  get digitalWalletToken() {
    return this.dig(this.digital_wallet_token, 'token');
  }

  get available_balance() {
    return this.dig(this.gpa, 'available_balance');
  }

  get impactedAmount() {
    const impactedAmount = this.dig(this.gpa, 'impacted_amount');
    return ignoreImpactedAmount(impactedAmount, this.state, this.type)
      ? this.amount
      : impactedAmount;
  }

  get formattedAmount() {
    try {
      if (isNotPresent(this.amount)) return null;
      return Number.parseFloat(this.amount).toFixed(2);
    } catch (err) {
      return this.amount;
    }
  }

  get formattedAmountWithCurrency() {
    try {
      if (isNotPresent(this.amount)) return null;
      return [this.formattedAmount, this.currency_code].join(' ').trim();
    } catch (err) {
      return this.amount;
    }
  }
}

decorate(TransactionStore, {
  // values
  token: observable,
  type: observable,
  state: observable,
  acting_cardholder_token: observable,
  card_token: observable,
  cardholder_token: observable,
  duration: observable,
  created_time: observable,
  cardholder_transaction_time: observable,
  settlement_date: observable,
  request_amount: observable,
  amount: observable,
  issuer_interchange_amount: observable,
  currency_code: observable,
  approval_code: observable,
  preceding_related_transaction_token: observable,
  incremental_authorization_transaction_tokens: observable,
  program_transfer: observable,
  fee_transfer: observable,
  peer_transfer: observable,
  offer_orders: observable,
  rewards: observable,
  polarity: observable,
  real_time_fee_group: observable,
  network: observable,
  subnetwork: observable,
  standin_approved_by: observable,
  acquirer_fee_amount: observable,
  pos: observable,
  avs: observable,
  transaction_metadata: observable,
  electronic_commerce_indicator: observable,
  verification_result: observable,
  verification_value_created_by: observable,
  card_acceptor: observable,
  response: observable,
  gpa_order: observable,
  chargeback: observable,
  card: observable,
  cardholder: observable,
  saving: observable,
  loadingCard: observable,
  loadingCardholder: observable,
  bankTransfer: observable,
  bank_transfer_token: observable,

  // actions
  loadAndConstruct: action.bound,
  hydrate: action.bound,
  fetchCardInformation: action.bound,
  fetchCardholderInformation: action.bound,

  // computed
  cardAcceptor: computed,
  channel: computed,
  cardholder_presence: computed,
  card_presence: computed,
  processing_type: computed,
  isDisputable: computed,
  isReturnable: computed,
  responseCode: computed,
  responseMemo: computed,
  responseAdditionalInformation: computed,
  token_requestor_name: computed,
  digitalWalletToken: computed,
  formattedAmount: computed,
  formattedAmountWithCurrency: computed,
  impactedAmount: computed,
});

export default TransactionStore;
