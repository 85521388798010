import routerStore from 'stores/router/RouterStore';
import CREDIT_ROUTES from './routes';

export const goToCredit = () => routerStore.go(CREDIT_ROUTES.CREDIT);

export const goToProduct = (token) => routerStore.go(CREDIT_ROUTES.PRODUCT, { params: { token } });
export const goToProducts = () => routerStore.go(CREDIT_ROUTES.PRODUCTS);
export const goToProductCreate = () => routerStore.go(CREDIT_ROUTES.PRODUCT_CREATE);

export const goToAccount = (token) => routerStore.go(CREDIT_ROUTES.ACCOUNT, { params: { token } });
export const goToAccountTab = (token, tab) =>
  routerStore.go(CREDIT_ROUTES.ACCOUNT, { params: { token, tab } });
export const goToSubstatus = (subStatusToken, accountHolderName, accountToken) =>
  routerStore.go(CREDIT_ROUTES.SUB_STATUS, {
    params: { subStatusToken, accountHolderName, accountToken },
  });
export const goToAccounts = () => routerStore.go(CREDIT_ROUTES.ACCOUNTS);

export const goToAcquisitionOffer = (token) =>
  routerStore.go(CREDIT_ROUTES.ACQUISITION_OFFER, { params: { token } });
export const goToAcquisitionOffers = () => routerStore.go(CREDIT_ROUTES.ACQUISITION_OFFERS);
export const goToAcquisitionOfferCreate = () =>
  routerStore.go(CREDIT_ROUTES.ACQUISITION_OFFER_CREATE);

export const goToBundle = (token) => routerStore.go(CREDIT_ROUTES.BUNDLE, { token });
export const goToBundles = () => routerStore.go(CREDIT_ROUTES.BUNDLES);
export const goToBundlesCreate = () => routerStore.go(CREDIT_ROUTES.BUNDLES_CREATE);

export const goToWorkQueue = () => routerStore.go(CREDIT_ROUTES.WORK_QUEUE);
export const goToApprovalQueue = () => routerStore.go(CREDIT_ROUTES.APPROVAL_QUEUE);

export const goToPolicyLibrary = () => routerStore.go(CREDIT_ROUTES.POLICY_LIBRARY);
export const goToPolicies = () => routerStore.go(CREDIT_ROUTES.POLICIES);
export const goToSpecificPolicyDashboard = (policy) =>
  routerStore.go(`/credit/policy-library/${policy}`);
export const goToPolicyCreate = (policy) => routerStore.go(CREDIT_ROUTES.POLICY_CREATE, { policy });

export const goToPolicyProduct = (token) =>
  routerStore.go(CREDIT_ROUTES.POLICY_PRODUCT, { params: { token } });
export const goToPolicyProducts = () => routerStore.go(CREDIT_ROUTES.POLICY_PRODUCTS);

export const goToPolicyAPR = (token) =>
  routerStore.go(CREDIT_ROUTES.POLICY_APR, { params: { token } });
export const goToPolicyAPRS = () => routerStore.go(CREDIT_ROUTES.POLICY_APRS);

export const goToPolicyFee = (token) =>
  routerStore.go(CREDIT_ROUTES.POLICY_FEE, { params: { token } });
export const goToPolicyFees = () => routerStore.go(CREDIT_ROUTES.POLICY_FEES);

export const goToPolicyReward = (token) =>
  routerStore.go(CREDIT_ROUTES.POLICY_REWARD, { params: { token } });
export const goToPolicyRewards = () => routerStore.go(CREDIT_ROUTES.POLICY_REWARDS);

export const goToTemplates = () => routerStore.go(CREDIT_ROUTES.TEMPLATES);
