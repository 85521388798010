import React, { Component } from 'react';
import { ApolloProvider } from '@apollo/react-hooks';
import { voltConfig } from '@mq/volt-amc-container';
import axios from 'axios';
import routerStore from './stores/router/RouterStore.js';
import './App.css';
import './sizing.css';
import '@mqd/volt-core/css';
import EnvironmentSelectorSetup from './components/environment-selector/EnvironmentSelectorSetup.js';
import Page from './components/page/Page.js';
import { gqlClient } from '@mq/volt-amc-container';
import WebVitals from './utils/WebVitals.js';
import initializeDataDog from './utils/dataDog.js';
import { isObservableArray, toJS } from 'mobx';

// Configure volt-amc-container.
voltConfig.go = routerStore.go;

const client = gqlClient();
axios.interceptors.request.use((config) => {
  const newParams = {};
  if (config?.params) {
    for (const key in config.params) {
      const value = config.params[key];
      const isArray = isObservableArray(value) || Array.isArray(value);
      if (isArray) {
        if (isObservableArray(value) && toJS(value).length > 0) {
          newParams[key] = toJS(value);
        } else if (Array.isArray(value) && value.length > 0) {
          newParams[key] = value;
        }
      } else if (value !== '') {
        newParams[key] = value;
      }
    }
  }
  config.params = newParams;
  return config;
});

class App extends Component {
  componentDidMount() {
    initializeDataDog();
  }
  render() {
    return (
      <ApolloProvider client={client}>
        <WebVitals>
          <EnvironmentSelectorSetup>
            <Page />
          </EnvironmentSelectorSetup>
        </WebVitals>
      </ApolloProvider>
    );
  }
}

export default App;
