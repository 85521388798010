// @flow
import { decorate, observable, action, runInAction } from 'mobx';
import { fragments } from '@mqd/graphql-utils';
import BusinessTransitionStore from '../stores/BusinessTransitionStore';
import CardholderTransitionStore from '../stores/CardholderTransitionStore';
import CardTransitionStore from '../stores/CardTransitionStore';
import DigitalWalletTokenTransitionStore from '../stores/DigitalWalletTokenTransitionStore';
import { ParentTableStore } from '@mq/voltron-parent';

export default class TransitionsTableStore extends ParentTableStore {
  constructor(args: Object = {}) {
    super(args);
    this.load(args);

    const { items } = args;
    if (items) this.loadPlainItems(items);
  }
  // values
  items: Array<any> = [];
  count: number = 25;

  get transitionType(): String {
    if (this.transitionTypeOverride) return this.transitionTypeOverride;
    const { business_token, card_token, cardholder_token, digital_wallet_token } =
      this.hydrateParams;

    if (business_token) return 'business';
    if (card_token) return 'card';
    if (cardholder_token) return 'cardholder';
    if (digital_wallet_token) return 'digitalWalletToken';
  }

  get queryStatement() {
    switch (this.transitionType) {
      case 'business':
        return `
          query business($business_token: ID! ${this.paginationParamsOuter}) {
            business(token: $business_token) {
              transitions(${this.paginationParamsInner}) {
                data {
                  ...businessTransitionInfo
                }
                ${this.paginationAttributes}
              }
            }
          }
          ${fragments.businessTransitionInfo}
        `;
        break;
      case 'digitalWalletToken':
        return `
          query digitalWalletToken($digital_wallet_token: ID! ${this.paginationParamsOuter}) {
            digitalWalletToken(token: $digital_wallet_token) {
              transitions(${this.paginationParamsInner}) {
                data {
                  ...digitalWalletTokenTransitionBaseInfo
                }
                ${this.paginationAttributes}
              }
            }
          }
          ${fragments.digitalWalletTokenTransitionBaseInfo}
        `;
        break;
      case 'card':
        return `
          query card($card_token: ID! ${this.paginationParamsOuter}) {
            card(token: $card_token) {
              transitions(${this.paginationParamsInner}) {
                data {
                  ...cardTransitionInfo
                }
                ${this.paginationAttributes}
              }
            }
          }
          ${fragments.cardTransitionInfo}
        `;
        break;
      case 'cardholder':
        return `
          query cardholder($cardholder_token: ID! ${this.paginationParamsOuter}) {
            cardholder(token: $cardholder_token) {
              transitions(${this.paginationParamsInner}) {
                data {
                  ...cardholderTransitionInfo
                }
                ${this.paginationAttributes}
              }
            }
          }
          ${fragments.cardholderTransitionInfo}
        `;
        break;
      default:
        return;
        break;
    }
  }

  // actions
  async hydrate() {
    this.loading = true;
    if (!this.queryStatement || !this.transitionType) {
      this.loading = false;
      return console.error('Missing transition parameters!');
    }

    const result = await this.gqlQuery(this.queryStatement, this.hydrateParams);
    let transitions = [];
    runInAction(() => {
      switch (this.transitionType) {
        case 'digitalWalletToken':
          transitions = this.dig(result, 'data', 'digitalWalletToken', 'transitions');
          this.processPaginationResponse(transitions, DigitalWalletTokenTransitionStore);
          break;
        case 'business':
          transitions = this.dig(result, 'data', 'business', 'transitions');
          this.processPaginationResponse(transitions, BusinessTransitionStore);
          break;
        case 'cardholder':
          transitions = this.dig(result, 'data', 'cardholder', 'transitions');
          this.processPaginationResponse(transitions, CardholderTransitionStore);
          break;
        case 'card':
          transitions = this.dig(result, 'data', 'card', 'transitions');
          this.processPaginationResponse(transitions, CardTransitionStore);
          break;
        default:
          this.loading = false;
          break;
      }
      this.loading = false;
    });
  }

  loadPlainItems(items) {
    switch (this.transitionType) {
      case 'digitalWalletToken':
        this.loadAndConstructList('items', items, DigitalWalletTokenTransitionStore);
        break;
      case 'business':
        this.loadAndConstructList('items', items, BusinessTransitionStore);
        break;
      case 'cardholder':
        this.loadAndConstructList('items', items, CardholderTransitionStore);
        break;
      case 'card':
        this.loadAndConstructList('items', items, CardTransitionStore);
        break;
      default:
        break;
    }
  }
}

decorate(TransitionsTableStore, {
  // values
  items: observable,
  count: observable,
  sort_by: observable,
  business_token: observable,

  // actions
  hydrate: action.bound,
  loadPlainItems: action.bound,

  // computed
});
