// @flow
import { decorate, observable, action, runInAction, computed } from 'mobx';
import { fragments } from '@mqd/graphql-utils';
import { ParentStore } from '@mq/voltron-parent';
import WalletProviderProfileStore from './WalletProviderProfileStore';
import TokenServiceProviderStore from './TokenServiceProviderStore';
import DeviceStore from './DeviceStore';
import { ACTIVE, SUSPENDED, UNLOCK, LOCK, TERMINATE, TERMINATED } from '../constants';
import { sentenceCase } from '../shared-utils';
import * as qualtrics from 'utils/qualtrics';

class DigitalWalletTokenStore extends ParentStore {
  constructor(args: Object = {}) {
    super(args);
    this.loadAndConstruct(args);
  }
  // values
  token: string = '';
  created_time: string = '';
  last_modified_time: string = '';
  state: string = '';
  state_reason: string = '';
  fulfillment_status: string = '';
  issuer_eligibility_decision: string = '';
  showTerminateModal: Boolean = false;

  // metadata
  loading: boolean = false;

  // Substores
  wallet_provider_profile: WalletProviderProfileStore = {};
  device: DeviceStore = {};
  token_service_provider: TokenServiceProviderStore = {};

  createTransitionAllowedRoles = [
    'access-manager',
    'cardholder-support',
    'compliance-internal',
    'compliance-processor-only',
    'delivery-internal',
    'fulfillment-internal',
    'marqeta-admin-internal',
    'production-support-internal',
    'program-admin',
    'risk-internal',
    'supplier-payments-manager',
    'aux-credit-support-agent-external',
  ];

  async hydrate(showFullPan = false) {
    this.loading = true;

    const queryName = showFullPan ? 'digitalWalletTokenShowTokenPan' : 'digitalWalletToken';

    try {
      this.loading = true;
      const result = await this.gqlQuery(
        `query digitalWalletToken($token: ID!) {
            ${queryName}(token: $token) {
              ...digitalWalletTokenBaseInfo
            }
          }

          ${fragments.digitalWalletTokenBaseInfo}
        `,
        {
          token: this.token,
          ...this.hydrateParams,
        }
      );

      const data = this.dig(result, 'data', queryName) || {};
      this.loadAndConstruct(data);
    } catch (e) {
      throw e;
    } finally {
      this.loading = false;
    }
  }

  loadAndConstruct(args) {
    this.load(args);
    const loadIfKeysPresent = [
      {
        key: 'device',
        store: DeviceStore,
      },
      {
        key: 'wallet_provider_profile',
        store: WalletProviderProfileStore,
      },
      {
        key: 'token_service_provider',
        store: TokenServiceProviderStore,
      },
    ];

    loadIfKeysPresent.forEach(({ key, store }) => {
      const value = args[key];
      if (value) this.loadAndConstructItem(key, value, store);
    });
  }

  async terminateToken({ reason_code, reason }) {
    const result = await this.changeStatus({
      state: TERMINATED,
      reason_code,
      ...(reason && { reason }),
    });
    this.showTerminateModal = false;
    qualtrics.track(qualtrics.EVENTS.DIGITAL_WALLET_TOKEN_SUSPENDED);
    return result;
  }

  async lockToken() {
    const result = await this.changeStatus({
      state: SUSPENDED,
      reason_code: '01', // TODO: design doesn't have a way to edit this, probably need a modal?
    });
    qualtrics.track(qualtrics.EVENTS.DIGITAL_WALLET_TOKEN_SUSPENDED);
    return result;
  }

  async activateToken() {
    return await this.changeStatus({
      state: ACTIVE,
      reason_code: '01', // TODO: design doesn't have a way to edit this, probably need a modal?
    });
  }

  async changeStatus(params) {
    try {
      this.loading = true;
      const result = await this.gqlMutation(
        `
          mutation createDigitalWalletTokenTransition(
            $token: ID 
            $reason_code: String!
            $token_reference_id: String
            $channel: String
            $digital_wallet_token: ID!
            $state: String!
            $reason: String
            $override_tsp_error: Boolean            
          ){
            createDigitalWalletTokenTransition(
              token: $token
              reason_code: $reason_code
              token_reference_id: $token_reference_id
              channel: $channel
              digital_wallet_token: $digital_wallet_token
              state: $state
              reason: $reason
              override_tsp_error: $override_tsp_error        
            ){
              ...digitalWalletTokenTransitionBaseInfo
            }
          }

          ${fragments.digitalWalletTokenTransitionBaseInfo}
        `,
        {
          digital_wallet_token: this.token,
          ...params,
        }
      );

      const state = this.dig(result, 'data', 'createDigitalWalletTokenTransition', 'state');
      this.load({ state });
      this.loading = false;
      return state;
    } catch (e) {
      this.loading = false;
      throw e;
    }
  }

  setTerminateModal(bool) {
    this.showTerminateModal = Boolean(bool);
  }

  // helpers
  transformDWTStatus(status) {
    return status ? status.replace(/DECISION_/, '').toLowerCase() : '';
  }

  // computed
  get formattedTokenPan() {
    const pan = this.dig(this.token_service_provider, 'token_pan');
    if (pan) {
      return '\u2022\u2022' + pan.substr(pan.length - 4);
    }
    return null;
  }

  get formattedState() {
    return sentenceCase(this.state);
  }

  get formattedWalletProviderRiskScore() {
    const score = this.dig(this.wallet_provider_profile, 'risk_assessment', 'score');
    return this.transformDWTStatus(score);
  }

  get formattedFulfillmentStatus() {
    return this.transformDWTStatus(this.fulfillment_status);
  }

  get allowedActions() {
    switch (this.state) {
      case ACTIVE:
        return [
          {
            text: LOCK,
            onClick: this.lockToken,
          },
          {
            text: TERMINATE,
            onClick: this.terminateToken,
          },
        ];
      case SUSPENDED:
        return [
          {
            text: UNLOCK,
            onClick: this.activateToken,
          },
          {
            text: TERMINATE,
            onClick: this.terminateToken,
          },
        ];
      default:
        return [];
    }
  }
}

decorate(DigitalWalletTokenStore, {
  // values
  token: observable,
  created_time: observable,
  last_modified_time: observable,
  state: observable,
  state_reason: observable,
  fulfillment_status: observable,
  issuer_eligibility_decision: observable,
  loading: observable,
  wallet_provider_profile: observable,
  device: observable,
  token_service_provider: observable,
  showTerminateModal: observable,

  // actions
  terminateToken: action.bound,
  lockToken: action.bound,
  activateToken: action.bound,
  changeStatus: action.bound,
  hydrate: action.bound,
  setTerminateModal: action.bound,

  // helpers
  transformDWTStatus: action.bound,

  //computed
  formattedState: computed,
  formattedTokenPan: computed,
  formattedFulfillmentStatus: computed,
  formattedWalletProviderRiskScore: computed,
  allowedActions: computed,
});

export default DigitalWalletTokenStore;
