// @flow
import { decorate, observable, action, computed, runInAction } from 'mobx';
import { ParentStore } from '@mq/voltron-parent';
import { fragments, templates } from '@mqd/graphql-utils';
import axios from 'axios';
import VelocityControlStore from './VelocityControlStore';
import AuthControlStore from './AuthControlStore';
import CardProductConfigStore from './CardProductConfigStore';

class CardProductStore extends ParentStore {
  constructor(args: Object = {}) {
    super(args);
    this.loadAndConstruct(args);
  }
  // store conifg
  skipAutoLoad: boolean = true;

  // values
  name: string = '';
  active: boolean = true;
  token: string = '';
  start_date: string = '';
  end_date: string = '';
  created_time: string = '';
  last_modified_time: string = '';
  velocity_controls: Array<VelocityControlStore> = [];
  auth_controls: Array<AuthControlStore> = [];
  config: CardProductConfigStore = {};
  mccList: Object = {};
  mccLoaded: boolean = false;

  loadAndConstruct(cardProductData = {}) {
    const {
      velocity_controls: { data: velocityData } = {},
      auth_controls: { data: authData } = {},
      config,
      ...cardProductAttr
    } = cardProductData;

    this.load(cardProductAttr);
    this.loadAndConstructList('velocity_controls', velocityData, VelocityControlStore);
    this.loadAndConstructList('auth_controls', authData, AuthControlStore);
    this.loadAndConstructItem('config', config, CardProductConfigStore);
  }

  async hydrate() {
    try {
      this.loading = true;
      const query = `
        query cardProduct($token: ID!) {
          cardProduct(token: $token) {
            ${templates.cardProductExpandedBaseInfo}
            auth_controls {
              data {
                ...authControlBaseInfo
              }
            }
            velocity_controls {
              data {
                ...velocityControlBaseInfo
              }
            }            
          }
        }
        ${fragments.binBaseInfo}
        ${fragments.cardProductFulfillmentBaseInfo}
        ${fragments.cardProductJitFundingBaseInfo}
        ${fragments.authControlBaseInfo}
        ${fragments.velocityControlBaseInfo}
        ${fragments.cardProductCardLifeCycleBaseInfo}
      `;

      const result = await this.gqlQuery(query, {
        token: this.token,
        ...this.hydrateParams,
      });

      runInAction(() => {
        if (result) {
          this.loadAndConstruct(this.dig(result, 'data', 'cardProduct'));
        }
      });
    } catch (e) {
      console.error(e);
    } finally {
      this.loading = false;
    }
  }

  // computed
  get hasMinOffSet() {
    return Boolean(
      this.dig(this.config, 'card_life_cycle', 'expiration_offset', 'min_offset', 'value')
    );
  }

  get hasControls() {
    return Boolean(
      (this.velocity_controls && this.velocity_controls.length) ||
        (this.auth_controls && this.auth_controls.length)
    );
  }
  get isPhysical() {
    const paymentInstrument = this.dig(this.config, 'fulfillment', 'payment_instrument');
    return paymentInstrument.startsWith('PHYSICAL');
  }

  get cardProductType() {
    return `${this.isPhysical ? 'PHYSICAL' : 'VIRTUAL'} CARD PRODUCT`;
  }

  get authControlStartTimes() {
    const startTimes = this.auth_controls.map((auth) => auth.formattedStartTime);
    return [...new Set(this.filterList(startTimes))];
  }

  get authFormattedMccs() {
    const authControlMccNames = this.mapMccNames(this.auth_controls);
    return this.uniqueArray(authControlMccNames);
  }

  get velocityControlAmountLimits() {
    const limits = this.velocity_controls.map((velocity) => {
      const { formattedVelocityControl, usage_limit } = velocity || {};
      return formattedVelocityControl;
    });

    return this.filterList(limits);
  }

  get velocityControlUsageLimits() {
    const limits = this.velocity_controls.map((velocity) => velocity.formattedUsageLimit);

    return this.filterList(limits);
  }

  get velocityControlUsageLimitsWithTypes() {
    const limits = this.velocity_controls.map(
      ({ formattedUsageLimit, controlTypeStringsArray } = {}) => {
        const includes = `Includes: ${controlTypeStringsArray.join(', ')}`;
        return {
          formattedUsageLimit: formattedUsageLimit,
          includes,
        };
      }
    );

    return this.filterList(limits);
  }

  get hasSingleUseVelocityControl() {
    return this.velocity_controls.some((velocity) => velocity.isSingleUse);
  }

  // helpers
  mapMccNames(controls) {
    let mccs = [];
    controls.forEach(({ merchant_scope }) => {
      const { mcc_name, mcc_group_data } = merchant_scope;
      const { mcc_names } = mcc_group_data || {};
      if (mcc_name) mccs.push(mcc_name);
      if (mcc_names) mccs = [...mccs, ...mcc_names];
    });

    return this.uniqueArray(mccs);
  }

  filterList(list) {
    if (list && list.filter) {
      return list.filter((item) => item);
    }
  }

  sentenceCase(str) {
    if (str.charAt && str.substr) {
      str.charAt(0).toUpperCase() + str.substr(1).toLowerCase();
    } else {
      return str;
    }
  }

  get infoCardProps() {
    const configFallback = {
      fulfillment: {
        bin: {},
      },
      jit_funding: {
        fundingType: {},
      },
    };
    return {
      token: this.token,
      start_date: this.start_date,
      config: this.config || configFallback,
      active: this.active,
    };
  }

  get formattedName() {
    return this.name || this.fallbackName || 'Card product';
  }

  get fallbackName() {
    if (this.name) return;

    const { fulfillment: { bin: { network, type, classification } = {} } = {} } = this.config || {};
    const nameElements = [network, classification, type, 'card product'].filter((el) => el);
    const name = nameElements.join(' ').trim();

    return this.sentenceCase(name);
  }

  get isPreFunded() {
    return this.dig(this, 'config', 'jit_funding', 'paymentcard_funding_source', 'enabled');
  }

  get shippingAddress() {
    const recipient_address = this.dig(
      this,
      'config',
      'fulfillment',
      'shipping',
      'recipient_address'
    );

    const {
      first_name,
      middle_name,
      last_name,
      address1,
      address2,
      city,
      state,
      postal_code,
      zip,
      country,
    } = recipient_address || {};

    if (!address1) return null;

    return {
      first_name,
      middle_name,
      last_name,
      address1,
      address2,
      city,
      state,
      postal_code,
      zip,
      country,
    };
  }

  get isBulkShip() {
    return this.dig(this, 'config', 'fulfillment', 'bulk_ship');
  }
}

decorate(CardProductStore, {
  // values
  skipAutoLoad: observable,
  name: observable,
  active: observable,
  start_date: observable,
  end_date: observable,
  token: observable,
  created_time: observable,
  last_modified_time: observable,
  velocity_controls: observable,
  auth_controls: observable,
  config: observable,
  mccList: observable,
  mccLoaded: observable,

  // actions
  loadAndConstruct: action.bound,
  hydrate: action.bound,
  mapMccNames: action.bound,

  // computed
  hasControls: computed,
  isPhysical: computed,
  cardProductType: computed,
  authControlStartTimes: computed,
  velocityControlAmountLimits: computed,
  authFormattedMccs: computed,
  hasSingleUseVelocityControl: computed,
  infoCardProps: computed,
  formattedName: computed,
  fallbackName: computed,
  isPreFunded: computed,
  hasMinOffSet: computed,
  shippingAddress: computed,
  velocityControlUsageLimitsWithTypes: computed,
  isBulkShip: computed,

  // helpers
  filterList: action.bound,
});

export default CardProductStore;
