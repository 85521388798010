// @flow
import { decorate, observable, action, runInAction, computed } from 'mobx';
import { fragments } from '@mqd/graphql-utils';
import { ParentStore } from '@mq/voltron-parent';
import IncorporationStore from './IncorporationStore';
import GpaBalanceStore from './GpaBalanceStore';
import PrimaryContactStore from './PrimaryContactStore';
import AddressStore from './AddressStore';
import IdentificationStore from './IdentificationStore';
import ProprietorOrOfficerStore from './ProprietorOrOfficerStore';
import { reasonCodes } from '../constants';
import BusinessIdentityCheckStore from './BusinessIdentityCheckStore';
import KycsBusinessStore from './KycsBusinessStore';
import { findNewestKyc } from '../shared-utils/index';

class BusinessStore extends ParentStore {
  constructor(args: Object = {}) {
    super(args);
    this.load(args);
  }
  // values
  active: boolean = false;
  attester_name: string = '';
  attestation_consent: boolean = false;
  attester_title: string = '';
  attestation_date: string = '';
  status: string = '';
  token: string = '';
  business_name_legal: string = '';
  business_name_dba: string = '';
  business_type: string = '';
  notes_string: string = '';
  ip_address: string = '';
  password: string = '';
  phone: string = '';
  metadata: string = '';
  account_holder_group_token: string = '';
  in_current_location_since: string = '';
  website: string = '';
  date_established: string = '';
  general_business_description: string = '';
  history: string = '';
  international_office_locations: string = '';
  duns_number: string = '';
  taxpayer_id: string = '';
  created_time: string = '';
  last_modified_time: string = '';
  kyb_active_for_business: boolean = false;
  // objects
  businessIdentityCheck: BusinessIdentityCheckStore = {};
  kycBusiness: KycsBusinessStore = {};
  identifications: IdentificationStore = [];
  gpa_balance: GpaBalanceStore = {};
  incorporation: IncorporationStore = {};
  primary_contact: PrimaryContactStore = {};
  office_location: AddressStore = {};
  proprietor_or_officer: ProprietorOrOfficerStore = {};
  beneficial_owner1: ProprietorOrOfficerStore = {};
  beneficial_owner2: ProprietorOrOfficerStore = {};
  beneficial_owner3: ProprietorOrOfficerStore = {};
  beneficial_owner4: ProprietorOrOfficerStore = {};
  loading: boolean = false;

  reasonCodes = reasonCodes;

  get identificationIsEdit() {
    return !(this.identifications && !this.identifications[0]);
  }

  updateBusinessAllowedRoles = [
    'compliance-internal',
    'compliance-program-managed',
    'compliance-processor-only',
    'risk-internal',
    'delivery-internal',
    'production-support-internal',
    'cardholder-support',
    'supplier-payments-manager',
    'program-admin',
    'access-manager',
    'marqeta-admin-internal',
  ];

  createTransitionAllowedRoles = [
    'compliance-internal',
    'compliance-processor-only',
    'delivery-internal',
    'marqeta-admin-internal',
    'program-admin',
    'risk-internal',
  ];

  manualOverrideRoles = [
    'compliance-internal',
    'risk-internal',
    'aux-compliance-internal',
    'aux-risk-internal',
    'marqeta-admin-internal',
  ];

  hideIdentification(type) {
    const targetIdentification = this.proprietor_or_officer.identifications.find(
      (identification) => identification.type === type
    );
    targetIdentification.value = '********';
  }

  async revealIdentification(type) {
    const payload = {
      token: this.token,
      full_ssn: true,
      type,
    };
    const targetIdentification = this.proprietor_or_officer.identifications.find(
      (identification) => identification.type === type
    );
    try {
      targetIdentification.loading = true;
      const result = await this.getIdentification(payload);
      const lowerCaseType = type.toLowerCase();
      const fullIdentificationValue = result.data.fullBusinessIdentificationNumber[lowerCaseType];
      targetIdentification.value = fullIdentificationValue;
    } catch (e) {
      console.error(e);
    } finally {
      targetIdentification.loading = false;
    }
  }

  async revealIdentificationGranular(type) {
    const payload = {
      token: this.token,
      full_ssn: true,
      type,
    };
    const targetIdentification = this.proprietor_or_officer.identifications.find(
      (identification) => identification.type === type
    );
    try {
      const granularResult = await this.getIdentificationGranular(payload);
      const lowerCaseType = type.toLowerCase();
      const fullIdentificationValue =
        granularResult.data.fullBusinessIdentificationNumberGranular[lowerCaseType];
      targetIdentification.value = fullIdentificationValue;
    } catch (e) {
      console.error(e);
    } finally {
      targetIdentification.loading = false;
    }
  }

  hideV1Identification() {
    this.proprietor_or_officer.ssn = '********';
  }

  async revealV1Identification() {
    const payload = {
      token: this.token,
      full_ssn: true,
      type: 'ssn',
    };

    try {
      this.proprietor_or_officer.loading = true;
      const result = await this.getIdentification(payload);
      this.proprietor_or_officer.ssn = result.data.fullBusinessIdentificationNumber.ssn;
    } catch (e) {
      console.error(e);
    } finally {
      this.proprietor_or_officer.loading = false;
    }
  }

  async revealV1IdentificationGranular() {
    const payload = {
      token: this.token,
      full_ssn: true,
      type: 'ssn',
    };

    try {
      this.proprietor_or_officer.loading = true;
      const granularResult = await this.getIdentificationGranular(payload);
      this.proprietor_or_officer.ssn =
        granularResult.data.fullBusinessIdentificationNumberGranular.ssn;
    } catch (e) {
      console.error(e);
    } finally {
      this.proprietor_or_officer.loading = false;
    }
  }

  async getIdentification(payload) {
    const result = await this.gqlQuery(
      `
        query fullBusinessIdentificationNumber (
          $token: ID!
          $type: String!
          $full_ssn: Boolean!
        ) {
          fullBusinessIdentificationNumber (
            token: $token
            type: $type
            full_ssn: $full_ssn
          ) {
            ssn
            tin
            nin
            sin
          }
        }
      `,
      payload
    );
    return result;
  }

  async getIdentificationGranular(payload) {
    const result = await this.gqlQuery(
      `
        query fullBusinessIdentificationNumberGranular (
          $token: ID!
          $type: String!
          $full_ssn: Boolean!
        ) {
          fullBusinessIdentificationNumberGranular (
            token: $token
            type: $type
            full_ssn: $full_ssn
          ) {
            ssn
            tin
            nin
            sin
          }
        }
      `,
      payload
    );
    return result;
  }

  get availableStatuses() {
    const possibleTransitions = {
      LIMITED: ['Active', 'Suspended', 'Closed'],
      ACTIVE: ['Suspended', 'Closed'],
      UNVERIFIED: ['Active', 'Suspended', 'Closed'],
      SUSPENDED: ['Active', 'Limited', 'Unverified', 'Closed'],
      CLOSED: ['Active', 'Limited', 'Unverified', 'Suspended'],
    };

    return possibleTransitions[this.status] || [];
  }

  async changeStatus(params) {
    const payload = {
      business_token: this.token,
      channel: 'ADMIN',
      ...params,
    };

    const result = await this.gqlMutation(
      `mutation createBusinessTransition (
          $token: ID
          $business_token: ID!
          $status: String!
          $reason_code: String!
          $reason: String
          $channel: String!
        ) {
          createBusinessTransition(
            token: $token
            business_token: $business_token
            status: $status
            reason_code: $reason_code
            reason: $reason
            channel: $channel
          ){
            ...businessTransitionInfo
          }
        }

        ${fragments.businessTransitionInfo}
      `,
      payload
    );

    if (!result) throw 'Business transition Failed!';
    return runInAction(async () => {
      await this.hydrate(this.token);
      const newStatus =
        result.data.createBusinessTransition && result.data.createBusinessTransition.status;
      return `Successfully updated business status to ${newStatus}`;
    });
  }

  // actions
  async addNote(businessToken, description, isPrivate, activeUserInfo) {
    const { email, userRole } = activeUserInfo;
    if (!email || !userRole)
      throw new Error(
        'Error: user must be logged in to create a note (undefined user email and role).'
      );
    const paramsInfo = {
      token: {
        type: 'String!',
        val: businessToken,
      },
      description: {
        type: 'String',
        val: description,
      },
      private: {
        type: 'Boolean',
        val: isPrivate,
      },
      created_by: {
        type: 'String',
        val: email,
      },
      created_by_user_role: {
        type: 'String',
        val: userRole.toUpperCase(),
      },
    };

    const result = await this.gqlMutation(
      `
        mutation createBusinessNote(${this.configureOuterQueryParams(paramsInfo)}) {
          createBusinessNote(${this.configureInnerQueryParams(paramsInfo)}) {
            ...noteBaseInfo
          }
        }
        ${fragments.noteBaseInfo}
      `,
      this.configureQueryParams(paramsInfo)
    );
    return result;
  }

  async hydrate(token) {
    this.getKybAccess();
    try {
      this.loading = true;
      const tokenParam = token || this.token;
      const result = await this.gqlQuery(
        `query business($token: ID!) {
            business(token: $token) {
              ${this.fullDataQuery}
            }
          }
          ${this.fullDataFragments}
        `,
        {
          token: tokenParam,
          ...this.hydrateParams,
        }
      );
      runInAction(() => {
        if (result) {
          const business = this.extract(result, 'business');
          this.setBusiness(business);
          const kycBusiness = this.dig(business, 'kycBusiness');
          this.loadAndConstructItem('kycBusiness', kycBusiness, KycsBusinessStore);
        }
      });
    } catch (e) {
      console.error('Error fetching business: ', e);
    } finally {
      this.loading = false;
    }
  }

  async updateBusiness() {
    const paramsInfo = {
      token: { type: 'ID!' },
      business_name_legal: { type: 'String' },
      business_name_dba: { type: 'String' },
      in_current_location_since: { type: 'String' },
      phone: { type: 'String' },
      website: { type: 'String' },
      date_established: { type: 'String' },
      general_business_description: { type: 'String' },
      history: { type: 'String' },
      account_holder_group_token: { type: 'String' },
      ip_address: { type: 'String' },
      notes: { type: 'String' },
      business_type: { type: 'String' },
      international_office_locations: { type: 'String' },
      duns_number: { type: 'String' },
      password: { type: 'String' },
      office_location: { type: 'AddressInput' },
      metadata: { type: 'String' },
      incorporation: { type: 'IncorporationInput' },
      proprietor_or_officer: { type: 'ProprietorOrOfficerInput' },
      beneficial_owner1: { type: 'ProprietorOrOfficerInput' },
      beneficial_owner2: { type: 'ProprietorOrOfficerInput' },
      beneficial_owner3: { type: 'ProprietorOrOfficerInput' },
      beneficial_owner4: { type: 'ProprietorOrOfficerInput' },
      primary_contact: { type: 'PrimaryContactInput' },
      taxpayer_id: { type: 'String' },
    };

    const result = await this.gqlMutation(
      `
      mutation updateBusiness(${this.configureOuterQueryParams(paramsInfo)}) {
        updateBusiness(${this.configureInnerQueryParams(paramsInfo)}) {
          ${this.fullDataQuery}
        }
      }
      ${this.fullDataFragments}
      `,
      this.updateParams
    );
    return runInAction(() => {
      if (result) {
        const business = this.extract(result, 'updateBusiness');
        this.setBusiness(business);
        this.valuesUpdated = {};
        return true;
      } else {
        return false;
      }
    });
  }

  async addIdentification({ type, value, expirationDate }) {
    const paramsInfo = {
      token: { type: 'ID!' },
      identifications: { type: '[IdentificationInput]' },
    };
    const result = await this.gqlMutation(
      `
      mutation updateBusiness(${this.configureOuterQueryParams(paramsInfo)}) {
        updateBusiness(${this.configureInnerQueryParams(paramsInfo)}) {
          identifications {
            ...identificationBaseInfo
          }
        }
      }
      ${fragments.identificationBaseInfo}
      `,
      {
        token: this.token,
        identifications: [{ type, value, expiration_date: expirationDate }],
      }
    );
    return runInAction(() => {
      if (result) {
        const businessData = this.extract(result, 'updateBusiness');
        const { identifications } = businessData;
        this.loadAndConstructList('identifications', identifications, IdentificationStore);
        this.valuesUpdated = {};
        return true;
      } else {
        return false;
      }
    });
  }

  async addPoeIdentification({ type, value, expirationDate }) {
    const paramsInfo = {
      token: { type: 'ID!' },
      identifications: { type: '[IdentificationInput]' },
    };
    const result = await this.gqlMutation(
      `
      mutation updateBusiness(${this.configureOuterQueryParams(paramsInfo)}) {
        updateBusiness(
          token: $token
          proprietor_or_officer: {
            identifications: $identifications
          }
        ) {
          proprietor_or_officer {
            ...proprietorOrOfficerBaseInfo
            identifications {
              ...identificationBaseInfo
            }
          }
        }
      }
      ${fragments.identificationBaseInfo}
      ${fragments.proprietorOrOfficerBaseInfo}
      `,
      {
        token: this.token,
        identifications: [{ type, value, expiration_date: expirationDate }],
      }
    );

    return runInAction(() => {
      if (result) {
        const businessData = this.extract(result, 'updateBusiness');
        const {
          proprietor_or_officer,
          beneficial_owner1,
          beneficial_owner2,
          beneficial_owner3,
          beneficial_owner4,
        } = businessData;
        this.loadAndConstructItem(
          'proprietor_or_officer',
          proprietor_or_officer,
          ProprietorOrOfficerStore
        );
        this.loadAndConstructItem('beneficial_owner1', beneficial_owner1, ProprietorOrOfficerStore);
        this.loadAndConstructItem('beneficial_owner2', beneficial_owner2, ProprietorOrOfficerStore);
        this.loadAndConstructItem('beneficial_owner3', beneficial_owner3, ProprietorOrOfficerStore);
        this.loadAndConstructItem('beneficial_owner4', beneficial_owner4, ProprietorOrOfficerStore);

        return true;
      } else {
        return false;
      }
    });
  }

  async addMetadata({ key, value }) {
    const paramsInfo = {
      token: { type: 'ID!' },
      metadata: { type: 'String' },
    };
    const result = await this.gqlMutation(
      `
      mutation updateBusiness(${this.configureOuterQueryParams(paramsInfo)}) {
        updateBusiness(${this.configureInnerQueryParams(paramsInfo)}) {
          metadata
        }
      }
      `,
      {
        token: this.token,
        metadata: JSON.stringify({ [key]: value }),
      }
    );
    return runInAction(() => {
      if (result) {
        const businessData = this.extract(result, 'updateBusiness');
        const { metadata } = businessData;
        if (metadata) {
          this.metadata = metadata;
        }
        this.valuesUpdated = {};
        return true;
      } else {
        return false;
      }
    });
  }

  async performManualOverride({ manualOverride, notes }) {
    const paramsInfo = {
      manual_override: { type: 'Boolean!' },
      notes: { type: 'String' },
      business_token: { type: 'String!' },
    };
    const result = await this.gqlMutation(
      `
      mutation performKYB(${this.configureOuterQueryParams(paramsInfo)}) {
        performKYB(${this.configureInnerQueryParams(paramsInfo)}) {
          token
        }
      }
      `,
      {
        manual_override: manualOverride,
        notes: notes,
        business_token: this.token,
      }
    );

    if (!result) {
      throw new Error('Manual override failed!');
    }

    return runInAction(async () => {
      await this.hydrate(this.token);
    });
  }

  loadFullInfoResult(businessData = {}) {
    this.load(businessData);
    const {
      gpa_balance,
      incorporation,
      primary_contact,
      office_location,
      identifications,
      proprietor_or_officer,
      beneficial_owner1,
      beneficial_owner2,
      beneficial_owner3,
      beneficial_owner4,
    } = businessData;
    this.loadAndConstructList('identifications', identifications, IdentificationStore);
    this.loadAndConstructItem('gpa_balance', gpa_balance, GpaBalanceStore);
    this.loadAndConstructItem('incorporation', incorporation, IncorporationStore);
    this.loadAndConstructItem('primary_contact', primary_contact, PrimaryContactStore);
    this.loadAndConstructItem('office_location', office_location, AddressStore);
    this.loadAndConstructItem(
      'proprietor_or_officer',
      proprietor_or_officer,
      ProprietorOrOfficerStore
    );
    this.loadAndConstructItem('beneficial_owner1', beneficial_owner1, ProprietorOrOfficerStore);
    this.loadAndConstructItem('beneficial_owner2', beneficial_owner2, ProprietorOrOfficerStore);
    this.loadAndConstructItem('beneficial_owner3', beneficial_owner3, ProprietorOrOfficerStore);
    this.loadAndConstructItem('beneficial_owner4', beneficial_owner4, ProprietorOrOfficerStore);
  }

  setBusiness(business) {
    this.loadFullInfoResult(business);
    const businessIdentityCheck = this.dig(business, 'businessIdentityCheck');
    this.loadAndConstructItem(
      'businessIdentityCheck',
      businessIdentityCheck,
      BusinessIdentityCheckStore
    );
  }

  updateMetadata(key, value) {
    const metadataCopy = Object.assign({}, this.metadataObject);
    metadataCopy[key] = value ? value : null;
    this.updateForSave('metadata', JSON.stringify(metadataCopy));
  }

  async getKybAccess() {
    try {
      const result = await this.gqlQuery(
        `
          query kybVersion {
            kybVersion
          }
        `
      );
      runInAction(() => {
        const kybVersion = this.dig(result, 'data', 'kybVersion');
        this.kyb_active_for_business = kybVersion === 'V4';
      });
    } catch (error) {
      console.error(error);
    }
  }

  // computed
  watchedObjects = ['office_location', 'incorporation', 'proprietor_or_officer', 'primary_contact'];
  get updateParams(): Object {
    let params = {
      token: this.token,
      ...this.watchedUpdateParams,
    };
    this.valuesUpdatedArray.forEach((value) => {
      // to handle the notes => notes_string rename
      if (value === 'notes_string') {
        params.notes = this.notes_string;
      } else {
        params[value] = this[value];
      }
    });
    return params;
  }

  get activeString(): String {
    return this.active ? 'Active' : 'Inactive';
  }

  get kybDate(): String {
    return this.dig(this.businessIdentityCheck, 'attestation_date');
  }

  get businessIdentityStatus(): String {
    return this.dig(this.businessIdentityCheck, 'status');
  }

  get kycManualOverride(): String {
    const kycBusinessData = this.dig(this.kycBusiness, 'data');
    if (kycBusinessData && kycBusinessData[0]) {
      return this.dig(kycBusinessData[0], 'manual_override');
    }
    return false;
  }

  get vendorPending(): String {
    const kycBusinessData = this.dig(this.kycBusiness, 'data');
    if (kycBusinessData && kycBusinessData[0]) {
      return this.dig(kycBusinessData[0], 'result', 'status') === 'pending';
    }
    return false;
  }

  get metadataObject(): Object {
    if (this.metadata) {
      try {
        return JSON.parse(this.metadata);
      } catch (error) {
        return {};
      }
    } else {
      return {};
    }
  }

  get isSoleProp(): Boolean {
    if (!this.incorporation) return false;
    const { incorporation_type } = this.incorporation;
    return incorporation_type === 'SOLE_PROPRIETORSHIP';
  }

  // helpers
  get fullDataQuery() {
    return `
      ...businessBaseInfo
      attester_name
      attestation_consent
      attester_title
      attestation_date
      gpa_balance {
        gpa {
          ...balanceBaseInfo
        }
      }
      incorporation {
        ...incorporationBaseInfo
        address_registered_under {
          ...addressBaseInfo
        }
      }
      primary_contact {
        ...primaryContactBaseInfo
      }
      office_location {
        ...addressBaseInfo
      }
      identifications {
        ...identificationBaseInfo
      }
      proprietor_or_officer {
        ...proprietorOrOfficerBaseInfo
        home {
          ...addressBaseInfo
        }
        identifications {
          ...identificationBaseInfo
        }
      }
      beneficial_owner1 {
        ...proprietorOrOfficerBaseInfo
        home {
          ...addressBaseInfo
        }
        identifications {
          ...identificationBaseInfo
        }
      }
      beneficial_owner2 {
        ...proprietorOrOfficerBaseInfo
        home {
          ...addressBaseInfo
        }
        identifications {
          ...identificationBaseInfo
        }
      }
      beneficial_owner3 {
        ...proprietorOrOfficerBaseInfo
        home {
          ...addressBaseInfo
        }
        identifications {
          ...identificationBaseInfo
        }
      }
      beneficial_owner4 {
        ...proprietorOrOfficerBaseInfo
        home {
          ...addressBaseInfo
        }
        identifications {
          ...identificationBaseInfo
        }
      }
      businessIdentityCheck {
        status
        attestation_date
      }
      kycBusiness(count: 500) {
        data {
          token
          created_time
          last_modified_time
          manual_override
          notes
          result {
            status
            codes {
              code
            }
          }
        }
      }
    `;
  }

  get fullDataFragments() {
    return `
      ${fragments.businessBaseInfo}
      ${fragments.balanceBaseInfo}
      ${fragments.addressBaseInfo}
      ${fragments.incorporationBaseInfo}
      ${fragments.primaryContactBaseInfo}
      ${fragments.identificationBaseInfo}
      ${fragments.proprietorOrOfficerBaseInfo}
    `;
  }

  get officeLocationAddress() {
    if (this.office_location) {
      const { address1, address2 } = this.office_location;

      if (!address1 && !address2) return null;
      return `${address1 || ''} ${address2 || ''}`;
    }
  }

  get officeLocationPostalCode() {
    return this.office_location && this.office_location.postal_code;
  }

  get officeLocationState() {
    return this.office_location && this.office_location.state;
  }

  get officeLocationCity() {
    return this.office_location && this.office_location.city;
  }

  get kycDetails() {
    return findNewestKyc(this.kycBusiness);
  }

  get kycData() {
    return this.dig(this.kycBusiness, 'data');
  }
}

decorate(BusinessStore, {
  // values
  active: observable,
  attester_name: observable,
  attestation_consent: observable,
  attester_title: observable,
  attestation_date: observable,
  status: observable,
  token: observable,
  business_name_legal: observable,
  business_name_dba: observable,
  business_type: observable,
  notes_string: observable,
  ip_address: observable,
  password: observable,
  phone: observable,
  metadata: observable,
  account_holder_group_token: observable,
  in_current_location_since: observable,
  website: observable,
  date_established: observable,
  general_business_description: observable,
  history: observable,
  international_office_locations: observable,
  duns_number: observable,
  taxpayer_id: observable,
  created_time: observable,
  last_modified_time: observable,
  loading: observable,
  // objects
  businessIdentityCheck: observable,
  kycBusiness: observable,
  gpa_balance: observable,
  incorporation: observable,
  primary_contact: observable,
  office_location: observable,
  identifications: observable,
  proprietor_or_officer: observable,

  // actions
  addNote: action.bound,
  hydrate: action.bound,
  updateBusiness: action.bound,
  loadFullInfoResult: action.bound,
  addIdentification: action.bound,
  addPoeIdentification: action.bound,
  addMetadata: action.bound,
  updateMetadata: action.bound,
  revealIdentification: action.bound,
  revealIdentificationGranular: action.bound,
  hideIdentification: action.bound,
  hideV1Identification: action.bound,
  revealV1Identification: action.bound,
  revealV1IdentificationGranular: action.bound,
  getIdentification: action.bound,
  performManualOverride: action.bound,

  // computed
  activeString: computed,
  metadataObject: computed,
  updateParams: computed,
  isSoleProp: computed,
  officeLocationAddress: computed,
  officeLocationPostalCode: computed,
  officeLocationState: computed,
  officeLocationCity: computed,
  identificationIsEdit: computed,
  kybDate: computed,
  businessIdentityStatus: computed,
  kycManualOverride: computed,
  vendorPending: computed,
  kycDetails: computed,
  kycData: computed,
});

export default BusinessStore;
