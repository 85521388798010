import React from 'react';
import { Link } from '@mqd/volt-base';

import shared from './SharedStyles.module.css';

export const space = '\u00A0';
export const marqetaDocsUrl = 'https://www.marqeta.com/docs/core-api/introduction';

export const VIEW_TYPE = {
  CREATOR: 'CREATOR_VIEW',
  APPROVER: 'APPROVER_VIEW',
  SUPPORT_AGENT: 'SUPPORT_AGENT_VIEW',
  BRAND_CONTRIBUTOR: 'BRAND_CONTRIBUTOR_VIEW',
};

export const CREDIT_TYPES = {
  CREDIT: 'Credit product',
  ACQUISITION: 'Offer',
  POLICY_LIBRARY: 'POLICY_LIBRARY',
  BUNDLE: 'Bundle',
};

export const PL_WELCOME_CART = {
  CREATOR_VIEW: {
    TITLE: 'Welcome to Policy library',
    TEXT: `The Policy library houses all existing credit product, APR, fee, reward, and offer
          policies. When combined, these credit policies make up a bundle.`,
  },
  APPROVER_VIEW: {
    TITLE: 'Welcome to Policy library',
    TEXT: `The Policy library houses all existing credit product, APR, fee, reward, and offer
          policies. When combined, these credit policies make up a bundle.`,
  },
};

export const CP_WELCOME_CART = {
  CREATOR_VIEW: {
    TITLE: 'Welcome to credit products, creator',
    TEXT: `A product is a collection of characteristics, such as credit limit range and fees,
          that determine the properties of prospective credit accounts. Use the creator 
          dashboard to create new credit products and view existing products by status.`,
    LINK: 'https://marqeta.com',
    LINK_TEXT: 'View guide',
  },
  APPROVER_VIEW: {
    TITLE: 'Welcome to credit products, approver',
    TEXT: `A product is a collection of characteristics, such as credit limit range and fees,
           that determine the properties of prospective credit accounts. Use the approver
           dashboard to review credit products.`,
    LINK: 'https://marqeta.com',
    LINK_TEXT: 'View guide',
  },
  SUPPORT_AGENT_VIEW: {
    TITLE: 'Welcome to credit products, support agent',
    TEXT: `A credit product is a collection of characteristics, such as credit limit range and
           fees, that determine the properties of prospective credit accounts. Use the
           support agent dashboard to view existing credit products by status.`,
    LINK: 'https://marqeta.com',
    LINK_TEXT: 'View guide',
  },
};

export const AO_WELCOME_CART = {
  CREATOR_VIEW: {
    TITLE: 'Welcome to offers, creator',
    TEXT: `Offers are created from credit products and sent to prospective account holders to acquire new credit accounts.
           Use the creator dashboard to view existing offers.`,
    LINK: 'https://marqeta.com',
    LINK_TEXT: 'View documentation',
  },
  APPROVER_VIEW: {
    TITLE: 'Welcome to offers, approver',
    TEXT: `Offers are created from credit products and sent to prospective account holders to acquire new credit accounts.
           Use the approver dashboard to view existing offers.`,
    LINK: 'https://marqeta.com',
    LINK_TEXT: 'View documentation',
  },
  SUPPORT_AGENT_VIEW: {
    TITLE: 'Welcome to offers, support agent',
    TEXT: `Offers are created from credit products and sent to prospective account holders to acquire new credit accounts.
           Use the support agent dashboard to view existing offers.`,
    LINK: 'https://marqeta.com',
    LINK_TEXT: 'View documentation',
  },
};

export const DASHBOARD_TOOLTIPS = {
  PRODUCT: {
    IN_PROGRESS: 'Items awaiting action appear in progress and are not active.',
    REJECTED: 'Rejected items cannot be edited or made active.',
  },
  OFFER: {
    QUEUE: 'Items awaiting action appear in queue and are not active.',
    REJECTED: 'Rejected items cannot be edited or made active.',
  },
};

export const CP_CREATE_APPROVE_FORM = {
  CREATE: {
    title: 'Create credit product',
    subtitle: (
      <>
        Create a credit product and specify its characteristics. Note that several constructs are
        defaults in our system. Only fill out the actionable form fields. Contact
        {space}
        <Link
          href="mailto:team-credit-product@marqeta.com"
          className={`${shared.cp_title_link} ${shared.inlineFlex}`}
        >
          Marqeta
        </Link>
        for any questions.
      </>
    ),
  },
  SENT_FOR_REVISION: {
    title: {
      APPROVER_VIEW: 'Credit product sent to creator for revision',
      CREATOR_VIEW: 'Revise credit product',
    },
    subtitle: {
      APPROVER_VIEW: (
        <>
          View the recently sent for revision credit product, now with the creator. You are able to
          archive the credit product. Contact
          {space}
          <Link
            href="mailto:team-credit-product@marqeta.com"
            className={`${shared.cp_title_link} ${shared.inlineFlex}`}
          >
            Marqeta
          </Link>
          for any questions.
          {space}
          <Link
            href={marqetaDocsUrl}
            className={`${shared.cp_title_link} ${shared.inlineFlex}`}
            external
          >
            View guide
          </Link>
        </>
      ),
      CREATOR_VIEW: (
        <>
          Revise the credit product per approver’s comments. Contact
          {space}
          <Link
            href="mailto:team-credit-product@marqeta.com"
            className={`${shared.cp_title_link} ${shared.inlineFlex}`}
          >
            Marqeta
          </Link>
          for any questions.
          {space}
          <Link
            href={marqetaDocsUrl}
            className={`${shared.cp_title_link} ${shared.inlineFlex}`}
            external
          >
            View guide
          </Link>
        </>
      ),
    },
  },
  PENDING_APPROVAL: {
    title: {
      CREATOR_VIEW: 'Credit product submitted to approver',
      APPROVER_VIEW: 'Review credit product',
    },
    subtitle: {
      CREATOR_VIEW: (
        <>
          View the recently submitted credit product, now with the approver. You are able to archive
          the credit product. Contact
          {space}
          <Link href="mailto:team-credit-product@marqeta.com" className={shared.inlineFlex}>
            Marqeta
          </Link>
          for any questions.
          <Link
            href={marqetaDocsUrl}
            className={`${shared.cp_title_link} ${shared.inlineFlex}`}
            external
          >
            View guide
          </Link>
        </>
      ),
      APPROVER_VIEW: (
        <>
          Review the submitted edits to the credit product. Contact
          {space}
          <Link href="mailto:team-credit-product@marqeta.com" className={shared.inlineFlex}>
            Marqeta
          </Link>
          for any questions.
          {space}
          <Link
            href={marqetaDocsUrl}
            className={`${shared.cp_title_link} ${shared.inlineFlex}`}
            external
          >
            View guide
          </Link>
        </>
      ),
    },
  },
  DRAFT: {
    title: 'Edit credit product draft',
    subtitle: (
      <>
        Edit credit product draft. Note that several constructs are defaults in our system. Only
        fill out the actionable form fields. Contact
        {space}
        <Link href="mailto:team-credit-product@marqeta.com" className={shared.inlineFlex}>
          Marqeta
        </Link>
        for any questions.
      </>
    ),
  },
  ACTIVE: {
    title: 'Update credit product',
    subtitle: (
      <>
        Update the active credit product and submit changes for review. Contact
        {space}
        <Link href="mailto:team-credit-product@marqeta.com" className={shared.inlineFlex}>
          Marqeta
        </Link>
        for any questions.
        <Link
          href={marqetaDocsUrl}
          className={`${shared.cp_title_link} ${shared.inlineFlex}`}
          external
        >
          View guide
        </Link>
      </>
    ),
  },
  ARCHIVED: {
    title: 'Credit product archived',
    subtitle: (
      <div style={{ maxWidth: 540 }}>
        This credit product has been archived. If unarchived, it will be restored as a draft and
        require a new selected card product.
        {space}
        <Link href={marqetaDocsUrl} className={`${shared.cp_title_link}`} external>
          View guide
        </Link>
      </div>
    ),
  },
  PENDING_APPROVAL_SUB_TITLE: 'Review and provide feedback in the comments as needed.',
  LINK_TEXT: 'View guide',
  LINK_URL: marqetaDocsUrl,
};

export const AO_CREATE_APPROVE_FORM = {
  CREATE: {
    title: 'Create offer',
    subtitle_v1: `Create an offer associated with an existing credit product.
                  Select a parent credit product to define the offer’s characteristics.
                  The parent credit product determines which characteristics you can define.`,
    subtitle_v2: `Create an offer associated with the selected parent credit product.
                  The parent credit product determines which characteristics you can define.`,
  },
  PENDING_APPROVAL: {
    title: 'Review offer pending approval',
    subtitle: `Review the pending offer and either approve,
               reject, or send it back to the creator for revision.`,
  },
  DRAFT: {
    title: 'Edit offer draft ',
    subtitle: `Edit an offer and specify its characteristics. 
               Select a parent credit product to determine which characteristics you can define.`,
  },
  SENT_FOR_REVISION: {
    title: 'Edit offer revision ',
    subtitle: `Edit an offer and specify its characteristics. 
               Select a parent credit product to determine which characteristics you can define.`,
  },
  LINK_TEXT: 'View developer guide',
  LINK_URL: 'https://marqeta.com',
};

export const PARENT_CP_CONSTRUCTS = {
  creditLine: '100,000 - 200,000 USD',
  interestMethod: 'Average daily balance (with new transactions)',
  productType: 'Revolving',
  productSubType: 'Credit card',
  classification: 'Consumer',
};

export const APRS = {
  GO_TO: 'Go-to, Purchase',
  PROMOTIONAL: 'Promotional',
};

export const REWARDS = {
  CASH_BACK_STATEMENT_CREDIT: 'Cash back, statement credit',
  CASH_BACK: 'Cash back',
};

export const FEES_KEY_TO_NAME = {
  PERIODIC_MEMBERSHIP_FEE: 'Periodic membership fee',
  FOREIGN_TRANSACTION_FEE: 'Foreign transaction fee',
  OVER_LIMIT_FEE: 'Overlimit fee',
  LATE_PAYMENT_FEE: 'Late payment fee',
  RETURNED_PAYMENT_FEE: 'Returned payment fee',
  CARD_REPLACEMENT_FEE: 'Card replacement fee',
  ANNUAL_FEE: 'Annual fee',
  MONTHLY_FEE: 'Monthly fee',
};

export const PERIODIC_FEE_TYPES = {
  MONTHLY: {
    key: 'monthly',
    label: 'Monthly',
    graphqlKey: 'MONTHLY',
  },
  ANNUAL: {
    key: 'annual',
    label: 'Annual',
    graphqlKey: 'ANNUAL',
  },
};

export const FEE_METHODS = {
  PERCENTAGE: 'PERCENTAGE',
  FLAT: 'FLAT',
};

export const AO_V1_DEFAULT_CONFIGS = {
  billingCycleDay: 'First day of the month (e.g. 2020-02-01)',
  paymentDueDay: 'Last day of the month (e.g. 2020-02-29)',
  gracePeriod: 'Month duration (e.g. 29 days)',
};

export const ITEM_SECTIONS = {
  PRODUCT: {
    IN_PROGRESS: 'In progress',
    ACTIVE: 'Active',
    REJECTED: 'Rejected',
  },
  OFFER: {
    QUEUE: 'Queue',
    ACTIVE: 'Active',
    REJECTED: 'Rejected',
  },
};

export const PRODUCT_STATUS = {
  ACTIVE: 'ACTIVE',
  ARCHIVED: 'ARCHIVED',
  DRAFT: 'DRAFT',
  PENDING_APPROVAL: 'PENDING_APPROVAL',
  REJECTED: 'REJECTED',
  SENT_FOR_REVISION: 'SENT_FOR_REVISION',
  APPROVED: 'APPROVED',
};

export const BUNDLE_STATUS = {
  ACTIVE: 'ACTIVE',
  ARCHIVED: 'ARCHIVED',
  DRAFT: 'DRAFT',
  PENDING_APPROVAL: 'PENDING_APPROVAL',
  REJECTED: 'REJECTED',
  SENT_FOR_REVISION: 'SENT_FOR_REVISION',
  APPROVED: 'APPROVED',
};

export const PRODUCT_ACTIONS = {
  DRAFT: 'DRAFT',
  SENT_FOR_REVISION: 'SENT_FOR_REVISION',
  SEND_FOR_APPROVAL: 'SEND_FOR_APPROVAL',
  ACTIVATE: 'ACTIVATE',
  REJECT: 'REJECT',
  ARCHIVE: 'ARCHIVE',
  UNARCHIVE: 'UNARCHIVE',
};

export const AO_STATUSES = {
  SENT_FOR_REVISION: 'SENT_FOR_REVISION',
  PENDING_APPROVAL: 'PENDING_APPROVAL',
  DRAFT: 'DRAFT',
  ACTIVE: 'ACTIVE',
  REJECTED: 'REJECTED',
};

export const CARD_STATUSES = {
  ACTIVE: 'ACTIVE',
  TERMINATED: 'TERMINATED',
  SUSPENDED: 'SUSPENDED',
};

export const PRODUCT_PAGE_TABS = {
  DETAILS: 'Details',
  HISTORY: 'History',
};

export const ACCOUNT_PAGE_TABS = {
  ACCOUNT_DETAILS: 'Account details',
  SUB_STATUSES: 'Substatuses',
  CARDS: 'Cards',
  STATEMENTS: 'Statements',
  TRANSACTIONS: 'Transactions',
  PAYMENT: 'Account Balance Activity',
  NOTES: 'Notes',
};

export const PAYMENT_STATUSES = {
  PENDING: 'PENDING',
  PROCESSING: 'PROCESSING',
  SUBMITTED: 'SUBMITTED',
  COMPLETED: 'COMPLETED',
  REJECTED: 'REJECTED',
  RETURNED: 'RETURNED',
  CANCELLED: 'CANCELLED',
};

export const PAYMENT_SOURCE_STATUS = {
  ACTIVE: 'ACTIVE',
  PENDING: 'PENDING',
  INACTIVE: 'INACTIVE',
};

export const PAYMENT_SOURCE_TYPE = {
  SAVINGS: 'SAVINGS',
  CHECKING: 'CHECKING',
  OTHER: 'OTHER',
};

export const MODAL_ERRORS = {
  REJECT_CONFIRMATION: {
    showError: false,
    showConfirmation: true,
    heading: 'Are you sure?',
    description:
      'Rejected credit products cannot be edited or made active. This action cannot be undone.',
    btnText: 'Reject',
  },
  ARCHIVE_CONFIRMATION: {
    showError: true,
    heading: 'Archive credit product',
    description:
      'Credit product will be removed from main view. To un-archive, restore as a draft.',
    btnText: 'Archive',
  },
  FORM_VALIDATION: {
    showError: true,
    heading: 'An user error occurred',
    description: 'Invalid characters entered. Please review the form and try again.',
    btnText: 'Retry',
  },
  API_ERROR: {
    showError: true,
    heading: 'A system error occurred',
    description: "Try again later. Something has gone wrong, but it's likely to be fixed",
    btnText: 'Retry',
  },
};

export const EMPTY_DASHBOARD = {
  CP_HEADING: 'No existing active credit products',
  CP_APPROVER_SUBHEADING: 'Credit products submitted by the creator appear here.',
  CP_SUPPORT_SUBHEADING: 'Active credit products appear here.',
  CP_BTN_TEXT: 'Create credit product',

  AO_HEADING: 'No existing offers',
  AO_SUBHEADING: 'Before you can create an offer, an active credit product must exist.',
  AO_NO_ACTIVE_PRODUCT_HEADING: 'No offers yet',
  AO_NO_ACTIVE_PRODUCT_SUBHEADING:
    'There are no active credit products yet. Go to credit products dashboard to create the first credit product.',
  AO_APPROVE_HEADING: 'No offers yet',
  AO_APPROVE_SUBHEADING: 'Offers appear here when submitted for approval by the creator.',
  AO_SUPPORT_HEADING: 'No existing offers yet',
  AO_SUPPORT_SUBHEADING: 'Active offers appear here.',
  AO_BTN_TEXT: 'Create Offer',
  AO_BTN_1_TEXT: 'View credit products',

  AO_ACME_USER_HEADING: 'No offers on Marqeta platform yet',
  AO_ACME_USER_SUBHEADING:
    'An offer, which derives its properties from an existing credit product, is sent to prospective account holders to acquire new credit accounts. To start using offers, contact your Marqeta representative.',
  AO_ACME_USER_BTN_1_TEXT: 'View docs',
  AO_ACME_USER_BTN_2_TEXT: 'Contact us',
};

export const FEES_KEY_TO_FIELD_NAME = {
  PERIODIC_MEMBERSHIP_FEE: 'membershipFee',
  FOREIGN_TRANSACTION_FEE: 'foreignTransactionFee',
  OVER_LIMIT_FEE: 'overlimitFee',
  LATE_PAYMENT_FEE: 'latePaymentFee',
  RETURNED_PAYMENT_FEE: 'returnedPaymentFee',
  CARD_REPLACEMENT_FEE: 'cardReplacementFee',
};

export const FIELD_NAMES_TO_FEE_KEY = {
  membershipFee: 'PERIODIC_MEMBERSHIP_FEE',
  foreignTransactionFee: 'FOREIGN_TRANSACTION_FEE',
  overlimitFee: 'OVER_LIMIT_FEE',
  latePaymentFee: 'LATE_PAYMENT_FEE',
  returnedPaymentFee: 'RETURNED_PAYMENT_FEE',
  cardReplacementFee: 'CARD_REPLACEMENT_FEE',
};

export const FEE_FIELD_TO_METHOD = {
  PERIODIC_MEMBERSHIP_FEE: FEE_METHODS.FLAT,
  FOREIGN_TRANSACTION_FEE: FEE_METHODS.PERCENTAGE,
  OVER_LIMIT_FEE: FEE_METHODS.FLAT,
  LATE_PAYMENT_FEE: FEE_METHODS.FLAT,
  RETURNED_PAYMENT_FEE: FEE_METHODS.FLAT,
  CARD_REPLACEMENT_FEE: FEE_METHODS.FLAT,
};

export const ACCOUNT_STATUSES = {
  ACTIVE: 'ACTIVE',
  TERMINATED: 'TERMINATED',
  SUSPENDED: 'SUSPENDED',
  UNACTIVETED: 'UNACTIVETED',
};

export const ACCOUNT_TRANSACTION_TYPES = {
  PURCHASE: 'PURCHASE',
  INTERNAL: 'INTERNAL',
  FEE: 'FEE',
  REWARD: 'REWARD',
  INTEREST: 'INTEREST',
  PAYMENT: 'PAYMENT',
  ADJUSTMENT: 'ADJUSTMENT',
  BALANCE_TRANSFER: 'BALANCE_TRANSFER',
  CASH_ADVANCE: 'CASH_ADVANCE',
};

export const BUNDLE_FORM_TEXTS = {
  nameLabel: 'Credit bundle name',
  nameSublabel: 'Enter a public-facing name for the credit bundle.',
  descriptionLabel: 'Credit bundle description',
  descriptionSublabel: 'Enter an internal, nonpublic description for the credit bundle.',
};

export const CP_FORM_TEXTS = {
  creditLimitMaxValidatorText: 'Maximum should be greater than minimum',
  creditLimitMinValidatorText: 'Minimum should be less than maximum',
  leavingHeaderText: 'Leaving credit products?',
  leavingBodyText: 'You will lose any unsaved changes to your credit product.',
  optionalText: 'optional',

  editCardProductsLabel: 'View card product',
  nonEditCardProductsLabel: 'Card product',
  cardProductsGroupLabel: 'Card product',
  cardProductsSubGroupLabel:
    'Associate physical and virtual card products with the credit product.',
  cardProductsErrorMessage: 'Card product selection is required.',

  coreConstructsSectionLabel: 'Core constructs',
  creditProductTokenLabel: 'Credit product token',

  creditProductNameLabel: 'Credit product name',
  creditProductNameSubLabel: 'Enter a name for the credit product.',
  creditProductNameErrorMessage: 'Credit product name is required.',
  creditProductNameBlankErrorMessage: 'Credit product name cannot be blank.',

  creditProductDescriptionLabel: 'Credit product description',
  creditProductDescriptionSubLabel: 'Enter a description for the credit product.',
  creditProductDescription: 'creditProductDescription',

  creditProductClassificationLabel: 'Credit product classification',
  creditProductClassificationId: 'creditProductClassification',
  creditProductClassificationErrorMessage: 'Credit product classification is required',

  creditProductClassificationRadioGroup: 'classification',

  creditProductTypeLabel: 'Credit product type',
  creditProductSubTypeLabel: 'Credit product subtype',
  currencyLabel: 'Currency',

  creditLimitMinimumLabel: 'Credit limit minimum',
  creditLimitMinimumErrorMessage: 'Credit limit minimum is not over 0.00',
  creditLimitMinimumRequiredErrorMessage: 'Credit limit minimum is required.',

  creditLimitMaximumLabel: 'Credit limit maximum',
  creditLimitMaximumErrorMessage: 'Credit limit maximum is required.',

  usageLabel: 'Usage',
  billingCycleDayLabel: 'Billing cycle day',
  paymentDueDayLabel: 'Payment due day',
  feesLabel: 'Fees',
  feesSubLabel: 'Associate physical and virtual card products with the credit product.',

  interestCalculationLabel: 'Interest calculation',
  methodLabel: 'Method',
  methodAvgDailyValue: 'Average daily balance (with new transactions)',

  dayCountLabel: 'Daily periodic rate day count',
  interestApplicationLabel: 'Interest application',
  includeInInterestApplicationLabel: 'Include in interest application',

  minimumInterestName: 'minimumInterest',
  minimumInterestLabel: 'Minimum interest',
  minimumInterestTooltip:
    'When charging interest, the minimum amount charged can be no less than this defined value.',
  minimumInterestError: 'Minimum interest is required.',

  residualInterestLabel: 'Residual interest',
  residualInterestTooltip:
    'Determines whether to charge or waive interest for the billing period when the balance is paid off.',

  graceDaysApplicationLabel: 'Grace days application',
  graceDaysApplicationTooltip:
    'Determines the last day of grace period based on which interest charges are calculated.',

  creditsApplicationLabel: 'Credits application',
  creditsApplicationTooltip:
    'Reduces interest on newly revolving accounts by applying all credits on day 1 of billing cycle.',

  excludedTransactionTypesLabel: 'Excluded transaction types',
  excludedTransactionTypesTooltip:
    "Transactions excluded from current billing period's interest charge, but included in next.",

  paymentsLabel: 'Payments',
  minimumPaymentPercentageLabel: 'Minimum payment percentage',
  minimumPaymentPercentageTooltip:
    'The percentage of the statement balance that helps determine the minimum payment value.',
  minimumPaymentPercentageErrorMessage: 'Minimum payment percentage is required.',

  minimumPaymentAmountLabel: 'Minimum payment amount',
  minimumPaymentAmountTooltip: 'The fixed amount that helps determine the minimum payment value.',
  minimumPaymentAmountErrorMessage: 'Minimum payment amount is required.',

  paymentAllocationOrderLabel: 'Payment allocation order',

  noteLabel: 'Note',
  noteSubLabel:
    'This note appears in the timeline after you make a decision on the product. Fill this out before making a decision on the credit product underneath.',

  noteErrorMessage: 'Note is required.',

  rejectBtnText: 'Reject',
  submitBtnText: 'Submit',
  secondBtnText: 'Save as draft',
  cancelBtnText: 'Cancel',

  backBtnText: 'Back',
  continueBtnText: 'Continue',

  moreDropdownLabel: 'More',
  timelineLabel: 'Timeline',

  purchasesLabel: 'Purchases',
  aprLabel: 'APR',
  rewardsLabel: 'Rewards',
  tokenLabel: 'Token',
  tokenHistory: 'Full token history',
  statusLabel: 'Status',
  lastModifiedLabel: 'Last modified',
  creditLimitRangeLabel: 'Credit limit range',
  minimumPaymentAppliedTowards: 'Minimum payment applied towards',

  tokenHistoryHeader: 'Credit product token history',

  reviewUpdatesLabel: 'Review updates',
  includeInMinimumPaymentLabel: 'Include in minimum payment',
  usageConfigurationsLabel: 'Usage configurations',
  standardRewardsLabel: 'Standard rewards',

  minimumPaymentAmountName: 'minimumPaymentAmount',
  minimumPaymentPercentageName: 'minimumPaymentPercentage',
  includeInMinimumPaymentName: 'includeInMinimumPayment',
  paymentsMinPaymentCalculationIncludePastDueAmount:
    'paymentsMinPaymentCalculationIncludePastDueAmount',
  aprStandardRewardsName: 'aprStandardRewards',
  creditLimitMinimumName: 'creditLimitMinimum',
  creditLimitMaximumName: 'creditLimitMaximum',
  globalConfigurationsLabel: 'Global configurations',
};

export const PRODUCT_CLASSIFICATION_MAP = {
  CONSUMER: 'Consumer',
  SMALL_AND_MEDIUM_BUSINESS: 'Small medium business',

  CONSUMER_KEY: 'CONSUMER',
  SMALL_AND_MEDIUM_BUSINESS_KEY: 'SMALL_AND_MEDIUM_BUSINESS',
};

export const REVIEW_PRODUCT_STATES = {
  REVIEWING: 'reviewing',
  EDITING: 'editing',
};

export const REVIEW_PRODUCT_ALERTS = {
  [REVIEW_PRODUCT_STATES.REVIEWING]:
    'Note the new token once the pending changes are approved. Using the old token will return an error when onboarding card holder accounts.',
  [REVIEW_PRODUCT_STATES.EDITING]:
    'If pending updates are approved, the credit bundle will have a new token. Using the previous token will result in an error.',
};

export const WORK_QUEUE = {
  label: 'Work queue',
  subLabel: `A bundle is a collection of policies, such as credit product policy, APR policy, fee policy, reward policy, and offer policies. Use the approver dashboard to review and approve credit bundles.`,
};

export const CREDIT_PRODUCTS_PAGE = {
  Current:
    'View all credit products that are active or rejected. Active or rejected credit products cannot be archived.',
  Archive: 'To unarchive a credit product, restore as a draft.',
};

export const STEPS = {
  GLOBAL_CONFIGURATIONS_STEP: 'globalConfigurationsStep',
  USAGE_CONFIGURATIONS_STEP: 'usageConfigurationsStep',
  REVIEW_AND_SUBMIT_STEP: 'reviewAndSubmitStep',
};

export const STEPS_LABELS = {
  GLOBAL_CONFIGURATIONS: 'Global configurations',
  USAGE_CONFIGURATIONS: 'Usage configurations',
  REVIEW_AND_SUBMIT: 'Review and submit',
};

export const INCLUDE_IN_MINIMUM_PAYMENT_ARRAY = (showOptions = false) => [
  'PAST_DUE_AMOUNT',
  ...(showOptions
    ? ['OVER_LIMIT_AMOUNT', 'INTEREST_CHARGES', 'LATE_PAYMENT_FEE', 'RETURN_PAYMENT_FEE']
    : []),
];

export const INCLUDE_IN_MINIMUM_PAYMENT_ENUM = {
  PAST_DUE_AMOUNT: 'PAST_DUE_AMOUNT',
  OVER_LIMIT_AMOUNT: 'OVER_LIMIT_AMOUNT',
  INTEREST_CHARGES: 'INTEREST_CHARGES',
  LATE_PAYMENT_FEE: 'LATE_PAYMENT_FEE',
  RETURN_PAYMENT_FEE: 'RETURNED_PAYMENT_FEE',
};

export const INCLUDE_IN_MINIMUM_PAYMENT_ARRAY_OBJ = {
  PAST_DUE_AMOUNT: 'Past due amount',
  OVER_LIMIT_AMOUNT: 'Over limit amount',
  INTEREST_CHARGES: 'Interest charges',
  LATE_PAYMENT_FEE: 'Late fee',
  RETURNED_PAYMENT_FEE: 'Return payment fee',
};

export const DAY_COUNT_ARRAY = ['ACTUAL', '365', '366'];
export const INTEREST_APPLICATION_ARRAY = ['PRINCIPAL', 'INTEREST', 'FEES'];

export const RESIDUAL_INTEREST_ARRAY = ['ACCRUE_PAYMENT_DATE', 'ACCRUE_FULL_CYCLE', 'WAIVE'];

export const RESIDUAL_INTEREST_ARRAY_OBJ = {
  ACCRUE_PAYMENT_DATE: 'Accrue till payment date',
  ACCRUE_FULL_CYCLE: 'Accrue full cycle ',
  WAIVE: 'Waive',
};

// BUNDLES
export const POLICY_LIBRARY_DESCRIPTION = {
  CREATOR_VIEW:
    'The Policy library houses all existing credit policies, which are standalone components that make up a bundle, when combined. Select a policy type to view existing policies or create a new or duplicate policy.',
  APPROVER_VIEW:
    'View existing policies by clicking into any of the tiles below. You can view each policies details and make duplicates of policies you need.',
};

export const OFFER_TYPE = {
  APR: 'apr',
  REWARD: 'reward',
};

export const MINIMUM_PURCHASE_RULES_HASH = {
  [OFFER_TYPE.APR]: 'APR',
  [OFFER_TYPE.REWARD]: 'Reward',
};

export const TRIGGER_TYPES = {
  greaterThan: 'greaterThan',
  lessThan: 'lessThan',
  equalTo: 'equalTo',
};

export const SPEND_RULES_TRIGGER = {
  [TRIGGER_TYPES.greaterThan]: 'Greater than',
  [TRIGGER_TYPES.lessThan]: 'Less than',
  [TRIGGER_TYPES.equalTo]: 'Equal to',
};

export const MAX_PRODUCTS = 99;
