import {
  createdTime,
  programFullName,
  type,
  amount,
  collateral,
  currency,
  transactionToken,
  memo,
} from '../../available-columns.js';

/**
 * TODO: we may need to disable sorting for such columns as: created_time, currency_code, transaction_token
 * At the moment, the server returns an error:
 * "could not resolve property: currency_code of: org.jpos.gl.ProgramReserveTransaction"
 */
export const columns = [
  createdTime,
  programFullName,
  type,
  amount,
  collateral,
  currency,
  transactionToken,
  memo,
];
