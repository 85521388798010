import React, { useContext, useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import Papa from 'papaparse';
import { ProgramReserveContext } from '../../context/ProgramReserveContext.js';
import { withProgramReserveStore } from '../../hoc/withProgramReserveStore.js';
import { withFetchCurrencies } from '../../hoc/withFetchCurrencies.js';
import BulkEntryEdit from './components/BulkEntryEdit/BulkEntryEdit.js';
import BulkEntryView from './components/BulkEntryView/BulkEntryView.js';
import { BulkEntryContext } from './BulkEntryContext.js';
import { parseTransactions, PARSING_ERRORS } from './utils/csv-utils.js';

export function BulkEntry() {
  const { isEditMode, setBulkTransactions, resetTransactions } = useContext(ProgramReserveContext);
  const [file, setFile] = useState(null);
  const [parsingError, setParsingError] = useState(null);
  const bulkEntryContext = {
    file,
    setFile,
    parsingError,
    setParsingError,
  };

  useEffect(() => {
    if (file) {
      Papa.parse(file, {
        skipEmptyLines: true,
        error: console.error,
        complete: (payload) => {
          if (payload.errors.length) {
            console.warn('File parsing warnings', payload.errors);
          }

          try {
            const [headers, deposits, collateral] = payload.data;
            const csvEntries = parseTransactions(headers, deposits, collateral);

            setParsingError(null);
            setBulkTransactions(csvEntries);
          } catch (error) {
            const { message } = error;
            const isExpectedError = Object.values(PARSING_ERRORS).includes(message);

            resetTransactions();
            setParsingError(isExpectedError ? message : PARSING_ERRORS.GENERIC);
          }
        },
      });
    }
  }, [file, resetTransactions, setBulkTransactions]);

  return (
    <BulkEntryContext.Provider value={bulkEntryContext}>
      {isEditMode ? <BulkEntryEdit /> : <BulkEntryView />}
    </BulkEntryContext.Provider>
  );
}

export default withProgramReserveStore(withFetchCurrencies(observer(BulkEntry)));
