import React from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { ErrorBannerAlert, ParentDetail } from '@mq/voltron-parent';
import { Icon, VSpacer, ModalAlert, Tabs, Button, ActionDropdown, Text } from '@mqd/volt-base';

import BusinessInfoTab from '../business-info-tab/BusinessInfoTab';
import KYBTab from '../kyb-tab/KYBTab';
import TransactionsTab from '../transactions-tab/TransactionsTab';
import AuditLogsTab from '../audit-logs-tab/AuditLogsTab';
import TransitionsTab from '../transitions-tab/TransitionsTab';
import CardholdersTab from '../cardholders-tab/CardholdersTab';
import NotesTab from '../notes-tab/NotesTab';
import AddNoteModal from '../add-note-modal/AddNoteModal';
import ChangeStatusModal from '../change-status-modal/ChangeStatusModal';

import StyleContext from '../contexts/StyleContext';
import BusinessStore from '../stores/BusinessStore';
import { UAM_GRANULAR_PERMISSIONS } from '@mqd/mqd-constants';
import { getDateThirtyDaysBeforeDate } from '../transactions-table/utils';

import s from './BusinessDetail.module.css';

const BUSINESS_DETAILS_VIEW_AND_EDIT = {};

class BusinessDetail extends ParentDetail {
  constructor(props) {
    super(props);
    this.storeConstructor = BusinessStore;
    this.state = {
      activeTab: this.props.currentTab || 'Business Details',
      showAddNoteModal: false,
      showChangeStatusModal: false,
      showNoteSuccessfullyAddedAlert: false,
      showNoteNotAddedAlert: false,
      noteTabRefreshToken: 'initial',
      historyTabRefreshToken: 'initial',
      loadedStores: {},
    };
  }

  get activeTabs() {
    const { userStore = {} } = this.props;
    const { kyb_active_for_business } = this.store;
    const config = {
      'Business Details': true,
      'Children Users': true,
      Transactions: true,
      History: userStore.auditLogTableActive,
      Notes: true,
      Transitions: true,
      KYB: kyb_active_for_business,
    };
    return Object.keys(config).filter((key) => config[key]);
  }

  renderBreadcrumb() {
    const { buildBreadcrumb } = this.props;
    if (!(buildBreadcrumb instanceof Function)) return null;
    const { business_name_legal } = this.store;
    const currentCrumb = {
      children: business_name_legal || '· · ·',
    };
    return buildBreadcrumb(currentCrumb);
  }

  renderBusinessInfoTab() {
    const {
      userStore: { canRevealIdentification },
    } = this.props;
    const { userStore } = this.props;
    return (
      <BusinessInfoTab
        canRevealIdentification={canRevealIdentification}
        businessStore={this.store}
        userStore={userStore}
      />
    );
  }

  renderContent() {
    const { activeTab, noteTabRefreshToken, historyTabRefreshToken, loadedStores } = this.state;
    const {
      onCardholderViewClick,
      token,
      cardholdersTableLocalStorageKey,
      transactionsTableLocalStorageKey,
      historyTableLocalStorageKey,
      notesTableLocalStorageKey,
      transitionsTableLocalStorageKey,
      userStore,
      goToDigitalWalletToken,
      goToNewDispute,
      goToTransaction,
      canAccessDisputes,
      canAccessChildrenUsers,
    } = this.props;
    const { hasMarqetaEmail } = userStore || {};
    const { gqlClient, business_name_legal } = this.store;
    const defaultComponent = this.renderBusinessInfoTab();
    if (!this.activeTabs.includes(activeTab)) return defaultComponent;
    const goToDWT = (dwtToken) => {
      goToDigitalWalletToken(dwtToken, business_name_legal, token);
    };

    switch (activeTab) {
      case 'Business Details':
        return this.renderBusinessInfoTab();

      case 'KYB':
        return (
          <KYBTab
            userStore={userStore}
            businessStore={this.store}
            storeInitArgs={{
              gqlClient: gqlClient,
              cardholder_token: token,
            }}
          />
        );

      case 'Children Users':
        return (
          <CardholdersTab
            onStoreConstruction={(store) => {
              const newLoadedStores = Object.assign({}, loadedStores, {
                'Children Users': store,
              });
              this.setState({ loadedStores: newLoadedStores });
            }}
            storeInitArgs={{
              gqlClient: gqlClient,
              tableParentType: 'business',
              queryParams: {
                token: {
                  type: 'ID!',
                  val: token,
                },
              },
            }}
            userStore={userStore}
            onRowClick={onCardholderViewClick}
            localStorageKey={cardholdersTableLocalStorageKey}
            calculateTableHeight={(store) => (store.currentList.length >= store.count ? 0 : 70)}
            canAccessChildrenUsers={canAccessChildrenUsers}
          />
        );

      case 'Transactions':
        return (
          <TransactionsTab
            onStoreConstruction={(store) => {
              const newLoadedStores = Object.assign({}, loadedStores, {
                Transactions: store,
              });
              this.setState({ loadedStores: newLoadedStores });
            }}
            storeInitArgs={{
              gqlClient,
              tableParentType: 'business',
              enableNoParamQuery: false,
              queryParams: {
                business_token: {
                  type: 'ID!',
                  val: token,
                },
              },
              userStore: userStore,
              cardholderOrBusinessToken: token,
              start_date: new Date(getDateThirtyDaysBeforeDate(new Date())),
              end_date: new Date(new Date().setHours(23, 59, 59, 999)),
            }}
            goToDigitalWalletToken={goToDWT}
            goToNewDispute={goToNewDispute}
            goToTransaction={goToTransaction}
            localStorageKey={transactionsTableLocalStorageKey}
            canAccessDisputes={canAccessDisputes}
          />
        );

      case 'History':
        return (
          <AuditLogsTab
            key={historyTabRefreshToken}
            onStoreConstruction={(store) => {
              const newLoadedStores = Object.assign({}, loadedStores, {
                History: store,
              });
              this.setState({ loadedStores: newLoadedStores });
            }}
            storeInitArgs={{
              gqlClient,
              enableNoParamQuery: false,
              queryParams: {
                record_id: {
                  val: token,
                  type: 'String',
                },
              },
            }}
            excludedSearchFilters={['record_id']}
            localStorageKey={historyTableLocalStorageKey}
            calculateTableHeight={(store) => (store.currentList.length >= store.count ? 0 : 30)}
          />
        );

      case 'Notes':
        return (
          <NotesTab
            key={noteTabRefreshToken}
            hasMarqetaEmail={hasMarqetaEmail}
            onStoreConstruction={(store) => {
              const newLoadedStores = Object.assign({}, loadedStores, {
                Notes: store,
              });
              this.setState({ loadedStores: newLoadedStores });
            }}
            storeInitArgs={{
              gqlClient,
              token,
              type: 'business',
            }}
            localStorageKey={notesTableLocalStorageKey}
          />
        );

      case 'Transitions':
        return (
          <TransitionsTab
            onStoreConstruction={(store) => {
              const newLoadedStores = Object.assign({}, loadedStores, {
                Transitions: store,
              });
              this.setState({ loadedStores: newLoadedStores });
            }}
            storeInitArgs={{
              gqlClient,
              queryParams: {
                business_token: {
                  val: token,
                  type: 'ID',
                },
              },
            }}
            localStorageKey={transitionsTableLocalStorageKey}
            calculateTableHeight={(store) => (store.currentList.length >= store.pageSize ? 0 : 70)}
          />
        );

      default:
        return defaultComponent;
    }
  }

  renderAddNoteModal() {
    const { showAddNoteModal } = this.state;
    const { userStore } = this.props;
    if (!showAddNoteModal) return null;

    //TODO: Pull out userRole from userStore (current userRole is from Janus).
    //Map redseaUserRole to USER | ADMIN | BANK_USER | BANK_ADMIN | MARQETA_PD | MARQETA_ADMIN.
    //Pass in the correct created_by_user_role.
    //Temporarily, all notes will have created_by_user_role='ADMIN'.
    const { email } = userStore;
    const activeUserInfo = email
      ? {
          email,
          userRole: 'ADMIN',
        }
      : {};
    return (
      <AddNoteModal
        hideModal={() => this.setState({ showAddNoteModal: false })}
        heading={`Add Note - ${this.store.business_name_legal}`}
        store={this.store}
        activeUserInfo={activeUserInfo}
        renderSuccessAlert={() =>
          this.setState({
            showNoteSuccessfullyAddedAlert: true,
            noteTabRefreshToken: Math.random(),
            historyTabRefreshToken: Math.random(),
          })
        }
        renderFailedAlert={() => this.setState({ showNoteNotAddedAlert: true })}
      />
    );
  }

  renderNoteSuccessfullyAddedAlert() {
    const { showNoteSuccessfullyAddedAlert } = this.state;
    if (!showNoteSuccessfullyAddedAlert) return null;
    return (
      <ModalAlert
        type="success"
        title="Note successfully added"
        hideModal={() => this.setState({ showNoteSuccessfullyAddedAlert: false })}
      />
    );
  }

  renderNoteNotAddedAlert() {
    const { showNoteNotAddedAlert } = this.state;
    if (!showNoteNotAddedAlert) return null;
    return (
      <ModalAlert
        type="danger"
        title="Note could not be added. Please try again."
        hideModal={() => this.setState({ showNoteNotAddedAlert: false })}
      />
    );
  }

  renderChangeStatusModal() {
    const { showChangeStatusModal } = this.state;
    if (!showChangeStatusModal) return null;
    return (
      <ChangeStatusModal
        hideModal={() =>
          this.setState({
            showChangeStatusModal: false,
            historyTabRefreshToken: Math.random(),
          })
        }
        heading={`Change Status - ${this.store.business_name_legal}`}
        store={this.store}
      />
    );
  }

  renderErrors() {
    const { loadedStores, activeTab } = this.state;
    if (activeTab === 'Business%20Details' || activeTab === 'Business Details') {
      return <ErrorBannerAlert store={this.store} />;
    } else {
      return <ErrorBannerAlert store={loadedStores[activeTab]} />;
    }
  }

  render() {
    const { activeTab } = this.state;
    const { handleTabClick, onCardCreationClick } = this.props;
    const { token, loading, business_name_legal } = this.store;
    const { userStore: { cardCreationActive, uamGranularPermissionsActive, redseaRoles } = {} } =
      this.props;

    let hasViewAndEditPermission = true;

    if (uamGranularPermissionsActive) {
      hasViewAndEditPermission = redseaRoles.includes(BUSINESS_DETAILS_VIEW_AND_EDIT);
    }

    return (
      <div style={{ width: '100%' }} data-testid={`business-detail-loading-${loading}`}>
        {this.renderErrors()}
        <div className={s.flexAndSpaceBetween}>
          {this.renderBreadcrumb() || <span />}
          <div className={s.flex}>
            {hasViewAndEditPermission && (
              <>
                <Button
                  onClick={() => this.setState({ showChangeStatusModal: true })}
                  type="outline"
                  style={{ padding: '6px 16px' }}
                >
                  Change status
                </Button>
                <Button
                  testId="business-detail-tab-selector_action-Add Note"
                  onClick={() => this.setState({ showAddNoteModal: true })}
                  type="outline"
                  style={{ padding: '6px 14px', marginLeft: '16px' }}
                >
                  Add note
                </Button>
              </>
            )}
            {cardCreationActive && (
              <ActionDropdown
                anchor="right"
                control={
                  <Button
                    testId="businessActionButton"
                    type="outline"
                    style={{
                      padding: '6px 7px',
                      marginLeft: '16px',
                    }}
                  >
                    <Icon type="view-more-vertical-big" noHoverEffects={true} factor={3} />
                  </Button>
                }
                content={[
                  ...[
                    {
                      element: 'Manual create card',
                      onClick: () => onCardCreationClick({ token }),
                    },
                  ],
                ]}
              />
            )}
          </div>
        </div>
        {this.renderAddNoteModal()}
        {this.renderNoteSuccessfullyAddedAlert()}
        {this.renderNoteNotAddedAlert()}
        {this.renderChangeStatusModal()}
        <VSpacer factor={2} />
        <Text type="h3">{!loading && (business_name_legal || 'Unnamed Business')}</Text>
        <VSpacer factor={3} />
        <Tabs
          testId="business-detail-tab-selector"
          tabs={this.activeTabs}
          activeTab={activeTab}
          onTabChange={(activeTab) => {
            handleTabClick({ tab: activeTab });
            this.setState({ activeTab });
          }}
        />
        <VSpacer factor={2} />
        <StyleContext.Provider value={{ height: this.dynamicHeight }}>
          {this.renderContent()}
        </StyleContext.Provider>
      </div>
    );
  }
}

BusinessDetail.propTypes = {
  storeInitArgs: PropTypes.object,
  store: PropTypes.object,
  userStore: PropTypes.object,
  autoHydrate: PropTypes.bool,
  onStoreConstruction: PropTypes.func,
  buildBreadcrumb: PropTypes.array,
  onCardholderViewClick: PropTypes.func,
  currentTab: PropTypes.string,
  handleTabClick: PropTypes.func,
  token: PropTypes.string,
  cardholdersTableLocalStorageKey: PropTypes.string,
  transactionsTableLocalStorageKey: PropTypes.string,
  historyTableLocalStorageKey: PropTypes.string,
  notesTableLocalStorageKey: PropTypes.string,
  transitionsTableLocalStorageKey: PropTypes.string,
  canAccessDisputes: PropTypes.bool,
  goToNewDispute: PropTypes.func,
  goToTransaction: PropTypes.func,
  canAccessChildrenUsers: PropTypes.bool,
};

BusinessDetail.defaultProps = {
  storeInitArgs: {},
  canAccessChildrenUsers: false,
  store: null,
  userStore: {},
  autoHydrate: true,
  onStoreConstruction: null,
  buildBreadcrumb: [],
  onCardholderViewClick: () => {},
  currentTab: 'Business Details',
  handleTabClick: () => {},
  token: '',
  cardholdersTableLocalStorageKey: 'businessDetailCardholdersTableConfig',
  transactionsTableLocalStorageKey: 'businessDetailTransactionsTableConfig',
  historyTableLocalStorageKey: 'businessDetailHistoryTableConfig',
  notesTableLocalStorageKey: 'businessDetailNotesTableConfig',
  transitionsTableLocalStorageKey: 'businessDetailTransitionsTableConfig',
  canAccessDisputes: false,
  goToNewDispute: null,
  goToTransaction: () => {},
};

export default observer(BusinessDetail);
