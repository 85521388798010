// @flow
import { decorate, observable, action, reaction, runInAction } from 'mobx';
import { fragments } from '@mqd/graphql-utils';
import S3BatchObjectStore from './S3BatchObjectStore';
import { ParentTableStore } from '@mq/voltron-parent';

export default class BulkResponseTableStore extends ParentTableStore {
  constructor(args: Object = {}) {
    super(args);
    this.load(args);

    reaction(() => this.items.length, this.calcTableHeight);
  }
  // values
  items: Array<S3BatchObjectStore> = [];
  tableHeight: number = 800;
  tableContainerRef: any = null;

  // actions
  handleSearch() {
    const { hydrate } = this;
    hydrate && hydrate();
  }

  async hydrate() {
    this.loading = true;
    const result = await this.gqlQuery(
      `
        query getBFPUploadHistory {
          getBFPUploadHistory {
            data {
              ...s3BatchObjectBaseInfo
              Metadata {
                ...s3BatchMetadataBaseInfo
                response {
                  ...s3BatchMetadataResponseBaseInfo
                }
              }
            }
          }
        }

        ${fragments.s3BatchObjectBaseInfo}
        ${fragments.s3BatchMetadataBaseInfo}
        ${fragments.s3BatchMetadataResponseBaseInfo}
      `,
      undefined,
      undefined,
      undefined,
      'all'
    );

    runInAction(() => {
      const items = this.dig(result, 'data', 'getBFPUploadHistory', 'data');
      this.loadAndConstructList('items', items, S3BatchObjectStore);
      this.loading = false;
    });
  }

  calcTableHeight() {
    const baseTableHeight = this.items.length ? this.items.length * 40 + 32 : 800;
    try {
      const winHeight = window.innerHeight;
      if (this.tableContainerRef) {
        const dimensions = this.tableContainerRef.getBoundingClientRect();
        const maxHeight = Math.min(baseTableHeight, winHeight - dimensions.y - 40);
        this.tableHeight = Math.max(240, maxHeight);
      } else {
        return baseTableHeight;
      }
    } catch (err) {
      console.error(err);
    }
  }
}

decorate(BulkResponseTableStore, {
  // values
  items: observable,
  tableHeight: observable,
  tableContainerRef: observable,

  // actions
  hydrate: action.bound,
  calcTableHeight: action.bound,
});
