// @flow
import { decorate, observable, action, runInAction } from 'mobx';
import { fragments } from '@mqd/graphql-utils';
import FundingSourceStore from './../stores/FundingSourceStore';
import { ParentTableStore } from '@mq/voltron-parent';

export default class FundingSourcesTableStore extends ParentTableStore {
  constructor(args: Object = {}) {
    super(args);
    this.load(args);
  }
  // values
  items: Array<FundingSourceStore> = [];
  count: number = 50;

  // actions
  async hydrate() {
    this.loading = true;
    const result = await this.gqlQuery(
      `
        query fundingSources${this.outerQueryParams} {
          fundingSources${this.innerQueryParams} {
            data {
              ...fundingSourceBaseInfo
            }
            ${this.paginationAttributes}
          }
        }
        ${fragments.fundingSourceBaseInfo}
      `,
      this.hydrateParams
    );
    runInAction(() => {
      const fundingSources = this.extract(result, 'fundingSources');
      this.processPaginationResponse(fundingSources, FundingSourceStore);
      this.loading = false;
    });
  }
}

decorate(FundingSourcesTableStore, {
  // values
  items: observable,

  // actions
  hydrate: action.bound,

  // computed
});
