import React from 'react';
import logger from 'utils/logger';
import moment from 'moment';
import { assocPath } from 'ramda';
import { formatMoney, formatMoneyAndNegative } from '@mqd/volt-currency';
import { Text } from '@mqd/volt-base';

import CreditCapabilities from './CreditCapabilities/index.js';
import routerStore from 'stores/router/RouterStore';
import errorModalStore from './components/ErrorModal/ErrorModalStore.js';

import { Status } from './components/index.js';

import {
  PRODUCT_STATUS,
  CREDIT_TYPES,
  FEES_KEY_TO_FIELD_NAME,
  BUNDLE_STATUS,
} from './constants.js';

export const capitalizeFirstLetter = (str) => {
  if (str) {
    const newStr = str.toLowerCase();
    return newStr.charAt(0).toUpperCase() + newStr.slice(1);
  }
};

export const makeStringIntoParam = (string) => {
  // spaces to - and all lower case
  return string.replace(/\s+/g, '-').toLowerCase();
};

export const createStorageKey = (productType) => {
  if (productType) {
    return `hide${productType.split(' ').map(capitalizeFirstLetter).join('')}WelcomeCard`;
  } else {
    return 'hideWelcomeCard';
  }
};

export const formatCurrency = (number) => new Intl.NumberFormat('ja-JP').format(number);

export const getCreditLineString = (creditLine) => {
  if (!creditLine) return '----';
  const { min, max } = creditLine;
  return `${formatMoney(min, {})} - ${formatMoney(max, {})} USD`;
};

export const titleCase = (str = '', split = '_') => {
  try {
    const splitStr = str.split(split).join(' ');
    return splitStr.charAt(0).toUpperCase() + splitStr.substr(1).toLowerCase();
  } catch (e) {
    return str;
  }
};

export const formatDayOfMonth = (dayOrdinal, daysInMonth) => {
  if (dayOrdinal === 1) {
    return 'First day of month';
  } else if (dayOrdinal >= 28) {
    return 'Last day of month';
  } else {
    const dayOrdinalFormatted = moment(`01-${dayOrdinal}-2020`, 'MM-DD-YYYY').format('Do');
    return dayOrdinalFormatted + ' day of month';
  }
};

export const handleError = (refetch, error, routePath, errorHeading) => {
  logger.error(error);
  errorModalStore.showAPIErrorModal(
    () => {
      refetch().catch((error) => {
        handleError(refetch, error, routePath);
      });
    },
    () => {
      routerStore.go(routePath);
    },
    errorHeading
  );
};

export const positiveNumberValidator = {
  validator: (currentValue) => {
    if (!currentValue) {
      return false;
    }

    const pattern = /^-[0-9]\d*(\.\d+)?$/;
    return pattern.test(currentValue);
  },
  message: 'Invalid character detected. Enter a positive numeric value.',
};

export const getFeesFieldsObject = (fees) => {
  return fees.reduce((r, fee) => {
    const { type, value } = fee;
    const field = FEES_KEY_TO_FIELD_NAME[type];

    return {
      ...r,
      [field]: value,
    };
  }, {});
};

export const formatDate = (date, format = 'YYYY-MM-DD') => {
  return moment(new Date(date)).format(format);
};

const generateToastTextForCreditProduct = (entityType, status, canEditActiveCP) => {
  let type = entityType === CREDIT_TYPES.CREDIT ? CREDIT_TYPES.CREDIT : CREDIT_TYPES.ACQUISITION;

  switch (status) {
    case PRODUCT_STATUS.DRAFT: {
      if (canEditActiveCP && type === CREDIT_TYPES.CREDIT) {
        return 'Pending update sent for review';
      }
      return `${type} saved as draft`;
    }
    case PRODUCT_STATUS.SENT_FOR_REVISION: {
      return `${type} sent for revision to creator`;
    }
    case PRODUCT_STATUS.PENDING_APPROVAL: {
      if (canEditActiveCP && type === CREDIT_TYPES.CREDIT) {
        return 'Pending update sent for review';
      }
      return `${type} submitted to approver`;
    }
    case PRODUCT_STATUS.ACTIVE: {
      return `${type} approved`;
    }
    case PRODUCT_STATUS.REJECTED: {
      return `${type} rejected`;
    }
    case PRODUCT_STATUS.ARCHIVED: {
      return `${type} archived`;
    }

    default:
      throw new Error(`Provided status: ${status} does not exist`);
  }
};

const generateToastTextForBundle = (status) => {
  const typeText = 'Credit bundle';

  switch (status) {
    case BUNDLE_STATUS.DRAFT: {
      return `${typeText} saved as draft`;
    }
    case BUNDLE_STATUS.SENT_FOR_REVISION: {
      return `${typeText} sent for revision`;
    }
    case BUNDLE_STATUS.PENDING_APPROVAL: {
      return `${typeText} submitted for review`;
    }
    case BUNDLE_STATUS.APPROVED: {
      return `${typeText} approved`;
    }
    case BUNDLE_STATUS.REJECTED: {
      return `${typeText} rejected`;
    }
    case BUNDLE_STATUS.ARCHIVED: {
      return `${typeText} archived`;
    }
    case BUNDLE_STATUS.ACTIVE: {
      return `${typeText} activated`;
    }

    default:
      throw new Error(`Provided status: ${status} does not exist`);
  }
};

export const getTextByStatus = (entityType, status, canEditActiveCP) => {
  if (entityType === CREDIT_TYPES.CREDIT || entityType === CREDIT_TYPES.ACQUISITION) {
    return generateToastTextForCreditProduct(entityType, status, canEditActiveCP);
  } else {
    return generateToastTextForBundle(status);
  }
};

export const calculateTableHeight = (items = []) => {
  const rowHeight = 40;
  const headerHeight = 40;
  const noLinesLabelHeight = 360;

  const visibleTableHeight = 1000;

  if (items.length) {
    if (items.length <= 25) {
      return items.length * rowHeight + headerHeight;
    }
    return visibleTableHeight;
  } else {
    return noLinesLabelHeight;
  }
};

export const amountRenderer = ({ cellValue }) => (cellValue ? `${cellValue} USD` : '');
export const amountRendererWithBrackets = ({ cellValue }, currencyCode = 'USD') =>
  formatMoneyAndNegative(cellValue, currencyCode);

export const dateRenderer = ({ cellValue }) =>
  cellValue ? moment(cellValue).format('YYYY-MM-DD HH:mm') : '';
export const statusRendererWithDot = ({ cellValue }) => (
  <Status status={cellValue} capitalize showStatus={false} />
);
export const statusRenderer = ({ cellValue }) => <Status status={cellValue} capitalize />;

export const gotoCreditCard = (cardToken, accountToken, accounHolderName) => {
  routerStore.go('Credit Account Card', {
    breadcrumb: `Account holders>Credit Account:${accounHolderName}:${accountToken}`,
    token: cardToken,
  });
};

export const getinterestOnGraceReactivationTitle = (type) => {
  return titleCase(type) + (type === 'ACCRUE_FULL_CYCLE' ? ' (for end revolving accounts)' : '');
};

export const getUpdatesCount = (obj1, obj2) => {
  if (!obj1 || !obj2) return 0;
  const generalCardProductTokens = Array.from(
    new Set([...obj1.cardProductTokens, ...obj2.cardProductTokens])
  );

  const generalIncludeInMinimumPaymentTokens = Array.from(
    new Set([...obj1.includeInMinimumPayment, ...obj2.includeInMinimumPayment])
  );

  const resultObj = {
    name: obj2?.name !== obj1?.name,
    description: obj2?.description !== obj1?.description,
    creditLineMin: +obj2?.creditLine?.min !== +obj1?.creditLine?.min,
    creditLineMax: +obj2?.creditLine?.max !== +obj1?.creditLine?.max,
    minPaymentPercentage: +obj2?.minPaymentPercentage !== +obj1?.minPaymentPercentage,
    minPaymentFlatAmount: +obj2?.minPaymentFlatAmount !== +obj1?.minPaymentFlatAmount,
    cardProducts: generalCardProductTokens.some((cpt) => !obj2.cardProductTokens.includes(cpt)),
    includeInMinimumPayment: generalIncludeInMinimumPaymentTokens.some(
      (cpt) => !obj2.includeInMinimumPayment.includes(cpt)
    ),
    periodicFees: obj1.config.periodicFees.length !== obj2.config.periodicFees.length,
  };

  let numberOfUpdatedFields = 0;
  for (let key in resultObj) {
    if (resultObj[key]) numberOfUpdatedFields++;
  }

  if (!numberOfUpdatedFields) return '';

  const pendingUpdatesString = `${numberOfUpdatedFields} pending update${
    numberOfUpdatedFields > 1 ? 's' : ''
  }`;

  return pendingUpdatesString;
};

export const sortByDate = (array, key, sortDirection = 'DESC') => {
  if (!key || !array || !array.length) return [];
  if (sortDirection === 'ASC') {
    return array.sort((a, b) => new Date(a[key]) - new Date(b[key]));
  }
  return array.sort((a, b) => new Date(b[key]) - new Date(a[key]));
};

export const creditLimit = (value, currencyCode) =>
  `${formatMoney(value, { minimumFractionDigits: 2 })} ${currencyCode}`;

export const isProductNew = (productStatus, parentProductToken) => {
  return (
    (productStatus === PRODUCT_STATUS.PENDING_APPROVAL ||
      productStatus === PRODUCT_STATUS.SENT_FOR_REVISION) &&
    CreditCapabilities.canApproveProducts() &&
    !parentProductToken
  );
};

export const renderBundlesStatus = ({ val }) => {
  let statusString;

  switch (val) {
    case BUNDLE_STATUS.PENDING_APPROVAL: {
      if (CreditCapabilities.canApproveBundle()) {
        statusString = 'Needs your review';
      }

      if (CreditCapabilities.canCreateBundle()) {
        statusString = 'Submitted to Approver';
      }

      break;
    }

    case BUNDLE_STATUS.SENT_FOR_REVISION: {
      if (CreditCapabilities.canApproveBundle()) {
        statusString = 'Sent for revision';
      }

      if (CreditCapabilities.canCreateBundle()) {
        statusString = 'Needs your revision';
      }

      break;
    }

    default: {
      statusString = capitalizeFirstLetter(val);
    }
  }

  return <Text>{statusString}</Text>;
};

/**
 For a given object look for keypaths styled 'foo.bar.foobar', and create a mutli-dimensional object
 example:
 transform

 { 'a.b.c': true, d: false}

 to

 {
   a: {
     b: {
       c: true
     }
   },
   d: false
 }

 @param data subject
 @param value The value to be set
 @return The updated object
 */
export const expand = (subject) => {
  return Object.keys(subject)
    .map((keyPath) => ({ keyPath, keyParts: keyPath.split('.') }))
    .reduce((acc, { keyPath, keyParts }) => assocPath(keyParts, subject[keyPath], acc), {});
};

/**
 Flatten a object hierarchy into object with a single level.

 example:
 transform
 {
   a: {
     b: {
       c: true
     }
   },
   d: false
 }

 to:

 { 'a.b.c': true, d: false}

 Useful for configuring forms

 @param data subject
 @param value The value to be set
 @return The updated object
 */
export const flatten = (subject, parents = '') => {
  let output = {};

  Object.keys(subject).forEach((key) => {
    const value = subject[key];
    const compoundKey = parents.length > 0 ? parents + '.' + key : key;
    if (typeof value == 'object' && !Array.isArray(value)) {
      Object.assign(output, flatten(value, compoundKey));
    } else {
      output[compoundKey] = value;
    }
  });

  return output;
};

/**
 Return [str] if match was found or undefined if not.

 examples:
 'badc0de1-5698-4850-a931-503173f105d7' | ['badc0de1-5698-4850-a931-503173f105d7']
 'badc0de123423423-5698-4850-a931-503173f105d7' | undefined
 'cardProducts' | undefined

 @param str string
 @return matched string or undefined
 */
export const matchUUID = (str) => {
  if (!str) return;
  return str.match(/\b[0-9a-f]{8}\b-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-\b[0-9a-f]{12}\b/g);
};
