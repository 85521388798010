// @flow
import { decorate, observable, action, runInAction } from 'mobx';
import { fragments } from '@mqd/graphql-utils';
import CommandoModeTransitionStore from './../stores/CommandoModeTransitionStore';
import { ParentTableStore } from '@mq/voltron-parent';

export default class CommandoModeTransitionsTableStore extends ParentTableStore {
  constructor(args: Object = {}) {
    super(args);
    this.load(args);
  }
  // values
  items: Array<CommandoModeTransitionStore> = [];
  count: number = 20;
  activeSort: ?string = 'created_time-';

  // actions
  async hydrate() {
    this.loading = true;
    const result = await this.gqlQuery(
      `query commandoModeTransitions${this.outerQueryParams} {
          commandoModeTransitions${this.innerQueryParams} {
            data {
              ...commandoModeTransitionBaseInfo
            }
            ${this.paginationAttributes}
          }
        }
        ${fragments.commandoModeTransitionBaseInfo}
      `,
      this.hydrateParams
    );
    runInAction(() => {
      const transitions = this.extract(result, 'commandoModeTransitions');
      this.processPaginationResponse(transitions, CommandoModeTransitionStore);
      this.loading = false;
    });
  }
}

decorate(CommandoModeTransitionsTableStore, {
  // values
  items: observable,

  // actions
  hydrate: action.bound,

  // computed
});
